import React from "react";
import WhatsAppWidget from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import useWindowWidthAndHeight from "../useWindowWidthAndHeight/useWindowWidthAndHeight";

function Whatsapp() {
  // eslint-disable-next-line
  const [width, height] = useWindowWidthAndHeight();

  return (
    <div className="fixed">
      {width > 1000 ? (
        <WhatsAppWidget
          phoneNumber={process.env.REACT_APP_WHATSAPP}
          textReplyTime="Nos toma poco tiempo contestar"
          companyName="Sistemify"
          sendButton="Enviar"
          message="Hola! 👋🏼, que podemos hacer por ti?"
        />
      ) : (
        <a
          href={process.env.REACT_APP_WHATSAPP_URL}
          class="whatsapp"
        >
          {" "}
          <i className="fa fa-whatsapp whatsapp-icon"></i>
        </a>
      )}
    </div>
  );
}

export default Whatsapp;
