import React from "react";
import { Row, Col } from "reactstrap";
import Header from "./Header";
import Footer from "./Footer";

function Somos() {
  return (
    <>
    <div class="banner">
    <Header/>
        <div id="Somos" className="container" style={{ }} >
          <Row>
            <Col md={6}>
              <p
                align="center"
                className="card-text"
                style={{
                  fontSize: "4vh",
                  color: "#fff",
                  fontStyle: "bold",
                  paddingTop: "2%",
                }}
              >
                ¿Quienes Somos?
              </p>
              <p className="card-text" style={{ fontSize: "3vh", color: "#fff", paddingLeft: "5%", paddingRight: "5%", paddingTop: "3%", textAlign: "justify" }}>
              Somos un equipo de expertos dedicados a ofrecer soluciones tecnológicas que impulsan el crecimiento de tu negocio.
              <br />
              <br />
              Nos comprometemos a ofrecer un servicio excepcional, siempre enfocados en maximizar los resultados de nuestros clientes.
              <br />
              <br />
              Basamos nuestro trabajo en principios de transparencia, ética y responsabilidad, colaborando de cerca contigo para asegurar tu éxito.
              <br />
              <br />
              ¡Trabajemos juntos y lograremos resultados extraordinarios!
            </p>


            </Col>
              <Col md={6}>
              <img
                src={process.env.PUBLIC_URL + "/somos.webp"}
                className="card-img"
                alt="Somos"
                style={{ paddingTop: "10%", paddingBottom:"5%" }}
              />
            </Col>
          </Row>
      </div>
        {/* <div class="content">
            <h1>LO QUE NOS MOTIVA SON TUS RETOS</h1>
            <p>¿Estas Listo para tomar el control de tu negocio?</p>
        <div>
            <button type="button"><span></span>Demo</button>
            <button type="button"><span></span>Demo2</button>
        </div>
        </div> */}
    </div>
    {/* <Somos/> */}
    <Footer/>
    </>
  );
}

export default Somos;
