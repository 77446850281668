import React from "react";
import { Row, Col } from "reactstrap";
import { Nav } from "react-bootstrap";
import Contact from "./Contact/Contact";
import Header from "./Header";
import Footer from "./Footer";

function Clientes() {
  return (
    <>
      <Header />
      <div id="Clientes" className="container">
        <Row>
          <Col md={12}>
            <p
              align="center"
              className="card-text"
              style={{
                fontSize: "4vh",
                color: "#000",
                fontStyle: "bold",
                paddingTop: "2%",
              }}
            >
              Casos de Exito
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <p
              align="center"
              className="card-text"
              style={{
                fontSize: "4vh",
                color: "#000",
                fontStyle: "bold",
                paddingTop: "2%",
              }}
            >
            Únete a nuestra lista de clientes satisfechos y construyamos juntos el futuro de tu empresa.
            </p>
          </Col>
        </Row>
        <br />
        <br />
        <div className="clientes">
          <Row>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente6.jpeg"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente2.jpeg"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente3.jpeg"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente9.jpeg"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente26.png"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente13.png"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente7.jpeg"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente8.jpeg"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente16.png"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente17.png"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente18.png"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente19.png"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
          </Row>
          
          <br />
          <br />
          <Row>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente14.png"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente15.png"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente20.png"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
          </Row>

          <br />
          <br />
          <Row>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente12.png"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente11.jpeg"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente1.jpeg"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
          </Row>
          
          <br />
          <br />
          <Row>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente4.jpeg"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente21.png"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente22.png"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente23.png"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente24.png"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente25.png"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente5.jpeg"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={4}>
              <img
                src={process.env.PUBLIC_URL + "/Cliente27.png"}
                className="card-img"
                alt="Clientes"
              />
            </Col>
            <Col md={3}>
              <h2
                align="center"
                className="card-text"
                style={{
                  fontSize: "4vh",
                  color: "#000",
                  fontStyle: "italic",
                  fontWeight: "bold",
                }}
              >
                <br />
                <br />
                ¡Tu logo falta aquí!
                <br />
                <br />
              </h2>
            </Col>
          </Row>
        </div>
      </div>
      <br />
      <Footer/>
    </>
  );
}

export default Clientes;
