import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Home() {
  return (
    <>
      <div class="banner">
        <Header />
        <div class="content">
          <h1>Impulsa tu negocio con nuestras soluciones</h1>
          <p>Optimiza tus procesos y alcanza tus objetivos con nuestra ayuda.</p>
          <p>¿Listo para avanzar?</p>

          <div>
            <a href="/Contacto">
              <button type="button"><span></span>
                Solicita una Demo
              </button>
            </a>
            <a href="/Clientes">
              <button type="button"><span></span>
                Casos de Éxito
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="waves"  id="benefits-section">
        <h3>¿Por qué invertir en un software de optimización?</h3>
        <p>
          En un entorno empresarial competitivo y en constante cambio, la eficiencia es clave
          para mantenerse a la vanguardia. Invertir en un software de optimización es una
          decisión estratégica que puede transformar la forma en que tu empresa opera y crece.
        </p>
        <h3>Beneficios clave:</h3>
        <ul>
          <li>Mayor productividad: Automatizar procesos y optimizar flujos de trabajo permite a los equipos centrarse en tareas de mayor valor, eliminando cuellos de botella y reduciendo tiempos de espera.</li>
          <li>Reducción de costos: Un software de optimización permite identificar y corregir ineficiencias, lo que se traduce en un ahorro significativo de recursos, desde tiempo hasta materias primas.</li>
          <li>Toma de decisiones basada en datos: Estas herramientas brindan análisis en tiempo real y reportes detallados, proporcionando la información necesaria para tomar decisiones informadas y acertadas.</li>
          <li>Escalabilidad: A medida que tu negocio crece, un software de optimización se adapta a tus necesidades, garantizando que el rendimiento y la eficiencia se mantengan al ritmo de tu expansión.</li>
          <li>Mejora en la satisfacción del cliente: Al optimizar procesos y mejorar la entrega de productos o servicios, se incrementa la satisfacción de los clientes, creando lealtad y mejorando la reputación de tu empresa.</li>
        </ul>
        <p>
          Invertir en un software de optimización no solo mejora la eficiencia operativa, sino que
          impulsa el crecimiento sostenible, permite un mejor control sobre los recursos y posiciona
          a tu empresa para enfrentar los desafíos del futuro con confianza.
        </p>
      </div>

      <Footer />
    </>
  );
}

export default Home;
