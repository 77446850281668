import React, { useState } from "react";
import { Container, Button, Form, Col, Row } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";

const Footer = () => {
  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [tamano, setTamano] = useState("0");

  const handleForm = async (event) => {
    event.preventDefault();
    const jsonSend = {
      name,
      email,
      note,
      tamano,
      tel,
    };

    const POST_CONTACT = `${process.env.REACT_APP_CONTACTO}`;

    try {
      await axios.post(POST_CONTACT, jsonSend).then(() => {
        setName("");
        setEmail("");
        setTel("");
        setTamano("0");
        setNote("");
        Swal.fire({
          icon: "success",
          title: "Listo, se enviaron tus datos",
          text: "Te contactaremos en breve",
          timer: 3000,
          timerProgressBar: true,
          allowEscapeKey: true,
        }).then(() => window.location.reload());
      });
    } catch (error) {
      let message = error.datas;
      Swal.fire({
        icon: "error",
        title: "Algo salió mal",
        text: message,
        allowEscapeKey: true,
      });
    }
  };

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={12}>
            <h2>Contáctanos</h2>
            <Form className="footer-form" onSubmit={handleForm}>
              <Form.Group>
                <Form.Control
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  name="name"
                  placeholder="Tu nombre"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  onChange={(e) => setTel(e.target.value)}
                  type="text"
                  name="tel"
                  placeholder="Tu teléfono"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  onChange={(e) => setTamano(e.target.value)}
                  as="select"
                >
                  <option value="0">Tamaño de la Empresa</option>
                  <option value="1-5 Empleados">1-5 Empleados</option>
                  <option value="6-20 Empleados">6-20 Empleados</option>
                  <option value="21-50 Empleados">21-50 Empleados</option>
                  <option value="51-100 Empleados">51-100 Empleados</option>
                  <option value="101-250 Empleados">101-250 Empleados</option>
                  <option value="251-1000 Empleados">251-1000 Empleados</option>
                  <option value="+1000 Empleados">+1000 Empleados</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  name="email"
                  placeholder="Tu email"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  onChange={(e) => setNote(e.target.value)}
                  as="textarea"
                  placeholder="¿Qué solución buscas?"
                  style={{ height: "80px" }}
                  required
                />
              </Form.Group>
              <Button className="btn btn-warning" variant="info" type="submit">
                Enviar
              </Button>
            </Form>
            <div className="footer-info">
              <p>© 2024 Sistemify. Todos los derechos reservados.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
