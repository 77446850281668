import React, { useState, useEffect, useLayoutEffect, useMemo } from "react";
import Header from "./Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
  Card,
  CardHeader,
  CardText,
  CardBody,
} from "reactstrap";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5percent from "@amcharts/amcharts5/percent";

function Dashboards() {
  let anos = new Date();
  anos.setDate(anos.getDate() - 1325);
  const year = anos.getFullYear();
  const years = Array.from(new Array(5), (val, index) => index + year);

  const [info, setInfo] = useState([]);
  const [graficasColecciones, setGraficasColecciones] = useState([]);
  const [graficasLineas, setGraficasLineas] = useState([]);
  const [graficasProveedores, setGraficasProveedores] = useState([]);
  const [graficasFamilias, setGraficasFamilias] = useState([]);
  const [graficasAreas, setGraficasAreas] = useState([]);
  const [graficasAnos, setGraficasAnos] = useState([]);
  const [valorInventario, setValorInventario] = useState(0);
  const [cantidadInventario, setCantidadInventario] = useState(0);

  const [selectedAno, setSelectedAno] = useState("");
  const [colecciones, setColecciones] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [lineas, setLineas] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [selectedColeccion, setSelectedColeccion] = useState("");
  const [selectedFamilia, setSelectedFamilia] = useState("");
  const [selectedLinea, setSelectedLinea] = useState("");
  const [selectedProveedor, setSelectedProveedor] = useState("");

  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    let array = [
      {
        codigo: "INDI",
        nombre: "INDI-LR2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 47901,
        valor: 14993013,
        inventario: 47901,
        idArticulo: "62c3424127a4e10017c93084",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 27376,
        valor: 8568688,
        inventario: 27376,
        idArticulo: "62c3453d27a4e10017c9338e",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "RC",
        nombre: "RC-014",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 33490,
        valor: 8372500,
        inventario: 33490,
        idArticulo: "62b342c9ba9b160016396245",
        precio: 223,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24754,
        valor: 7748002,
        inventario: 24754,
        idArticulo: "62c3456127a4e10017c933b6",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ALDOS",
        nombre: "ALDOSMEN-LE2301C",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 25687,
        valor: 3981998.74,
        inventario: 25687,
        idArticulo: "62ffcb675e9fa60016eb8447",
        precio: 223,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 11660,
        valor: 3649580,
        inventario: 11660,
        idArticulo: "62745dfc95c45c183877d290",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 16983,
        valor: 2805591.5999999996,
        inventario: 16983,
        idArticulo: "62cee5b0fb6b4e00175aa0a5",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 16664,
        valor: 2752892.8,
        inventario: 16664,
        idArticulo: "62cee550fb6b4e00175aa098",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2210",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 13697,
        valor: 2435326.5999999996,
        inventario: 13697,
        idArticulo: "62745df895c45c183877d094",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2210",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 11293,
        valor: 2292479,
        inventario: 11293,
        idArticulo: "62745df895c45c183877d06b",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ALDOS",
        nombre: "ALDOSBOY-LE2301C",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 17284,
        valor: 2115907.2800000003,
        inventario: 17284,
        idArticulo: "62ffcbc55e9fa60016eb85ce",
        precio: 201,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LUIGI",
        nombre: "LUIGI-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 8096,
        valor: 1990401.5999999999,
        inventario: 8096,
        idArticulo: "62745e0195c45c183877d4b0",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "JCJUV",
        nombre: "JCJUV-NEGROC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 12509,
        valor: 1586266.29,
        inventario: 12509,
        idArticulo: "62c5e251883f0f0017d089e6",
        precio: 167,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 8806,
        valor: 1565706.7999999998,
        inventario: 8806,
        idArticulo: "62745df895c45c183877d08f",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ALDOS",
        nombre: "ALDOSTEEN-LE2301C",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 10971,
        valor: 1510596.99,
        inventario: 10971,
        idArticulo: "62ffcb975e9fa60016eb8475",
        precio: 211,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "TEEN",
        idFamilia: "62fc14e5f64ad5001675a4d5",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LUIGI",
        nombre: "LUIGI-BL2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5846,
        valor: 1437239.0999999999,
        inventario: 5846,
        idArticulo: "62745e0195c45c183877d4aa",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "ORTEGA",
        nombre: "ORTEGAMEN-LE2301C",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 8398,
        valor: 1319913.66,
        inventario: 8398,
        idArticulo: "62ffcdcd5e9fa60016eb872c",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JCJUV",
        nombre: "JCJUV-KAKIC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 9218,
        valor: 1168934.58,
        inventario: 9218,
        idArticulo: "62c5e20c883f0f0017d089cc",
        precio: 167,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JCJUV",
        nombre: "JCJUV-CAMUVEC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 8399,
        valor: 1110179.82,
        inventario: 8399,
        idArticulo: "62c5e150883f0f0017d08896",
        precio: 174.5,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "108-113",
        nombre: "108-113",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5998,
        valor: 1096134.5,
        inventario: 5998,
        idArticulo: "631f4fad803c1f0016a31893",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NONA",
        nombre: "NONA-MG2302",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3508,
        valor: 1085726,
        inventario: 3508,
        idArticulo: "638fc1654f9d8300162cde8c",
        precio: 420,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NONA",
        nombre: "NONA-MG2301",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3465,
        valor: 1072417.5,
        inventario: 3465,
        idArticulo: "638fc13a6236bf001662289c",
        precio: 420,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5957,
        valor: 1059154.5999999999,
        inventario: 5957,
        idArticulo: "62745df895c45c183877d092",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ALDO",
        nombre: "ALDO-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4969,
        valor: 1055912.5,
        inventario: 4969,
        idArticulo: "62745df595c45c183877cf7d",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "TANIAPLUS",
        nombre: "TANIAPLUS-XT2213",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 3930,
        valor: 1011581.9999999999,
        inventario: 3930,
        idArticulo: "631f4cc7803c1f0016a31303",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "HANS",
        nombre: "HANS-TY2202",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 4510,
        valor: 992651,
        inventario: 4510,
        idArticulo: "62745dfc95c45c183877d244",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "CCJUV",
        nombre: "CCJUV-KAKIC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 6889,
        valor: 955022.07,
        inventario: 6889,
        idArticulo: "62c5e05a883f0f0017d086ef",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JULIETA",
        nombre: "JULIETA-MG2201",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 4085,
        valor: 954868.75,
        inventario: 4085,
        idArticulo: "63092967d6368300164ca94b",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SIENAHOOD",
        nombre: "SIENAHOOD-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4381,
        valor: 904238.4,
        inventario: 4381,
        idArticulo: "62745e0895c45c183877d7c4",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "CCJUV",
        nombre: "CCJUV-MARINOC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 6440,
        valor: 892777.2,
        inventario: 6440,
        idArticulo: "62c5e085883f0f0017d086fc",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JCJUV",
        nombre: "JCJUV-MARINOC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 6742,
        valor: 854953.02,
        inventario: 6742,
        idArticulo: "62c5e22b883f0f0017d089d9",
        precio: 167,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "CINDY",
        nombre: "CINDYGIRLS-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3276,
        valor: 802620,
        inventario: 3276,
        idArticulo: "62c3493227a4e10017c93546",
        precio: 390,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JCNI",
        nombre: "JCNI-KAKIC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 6956,
        valor: 777402.56,
        inventario: 6956,
        idArticulo: "62c5def9883f0f0017d085c2",
        precio: 145,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "LOLAHOOD",
        nombre: "LOLAHOOD-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3712,
        valor: 740915.2,
        inventario: 3712,
        idArticulo: "62745e0195c45c183877d485",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4461,
        valor: 736957.2,
        inventario: 4461,
        idArticulo: "62cee3ccfb6b4e00175aa04e",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ALDOS",
        nombre: "ALDOSBABY-LE2301C",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 6532,
        valor: 735045.96,
        inventario: 6532,
        idArticulo: "62ffcbf75e9fa60016eb8604",
        precio: 172,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "BEBE",
        idFamilia: "62744a8b0b6417001680035a",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JCBB",
        nombre: "JCBB-KAKIC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 6752,
        valor: 725569.9199999999,
        inventario: 6752,
        idArticulo: "62c5d257883f0f0017d06aaa",
        precio: 137,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "BEBE",
        idFamilia: "62744a8b0b6417001680035a",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JCJUV",
        nombre: "JCJUV-OLIVOC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 5634,
        valor: 714447.54,
        inventario: 5634,
        idArticulo: "62c5e278883f0f0017d089f3",
        precio: 167,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "TABO",
        nombre: "TABO-XT2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3226,
        valor: 705848.7999999999,
        inventario: 3226,
        idArticulo: "628be7f495692838f4c58ba0",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ROBINHOOD",
        nombre: "ROBINHOOD-XT2202",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 6045,
        valor: 701522.25,
        inventario: 6045,
        idArticulo: "62745e0795c45c183877d734",
        precio: 190,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "FELIX",
        nombre: "FELIX-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2957,
        valor: 661628.75,
        inventario: 2957,
        idArticulo: "62745dfa95c45c183877d1c9",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "FELIX",
        nombre: "FELIX-LE2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2923,
        valor: 654021.25,
        inventario: 2923,
        idArticulo: "62745dfb95c45c183877d1d7",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SIENA",
        nombre: "SIENA-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3372,
        valor: 646412.4,
        inventario: 3372,
        idArticulo: "62745e0895c45c183877d7ca",
        precio: 305,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA568#405",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 2810,
        valor: 646300,
        inventario: 2810,
        idArticulo: "6308f06eb511770016a3b05a",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2194,
        valor: 638454,
        inventario: 2194,
        idArticulo: "62745dfc95c45c183877d25e",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3311,
        valor: 637367.5,
        inventario: 3311,
        idArticulo: "62cee609fb6b4e00175aa0b2",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JCTE",
        nombre: "JCTE-MARINOC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 4585,
        valor: 630712.6,
        inventario: 4585,
        idArticulo: "62c5e3da883f0f0017d08afb",
        precio: 185,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SIENAHOOD",
        nombre: "SIENAHOOD-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3025,
        valor: 624360,
        inventario: 3025,
        idArticulo: "62745e0895c45c183877d7c0",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ORTEGA",
        nombre: "ORTEGABP-LE2301C",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3585,
        valor: 620563.5,
        inventario: 3585,
        idArticulo: "62ffcd8b5e9fa60016eb86ed",
        precio: 291,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2096,
        valor: 609936,
        inventario: 2096,
        idArticulo: "62745dfc95c45c183877d266",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "JCTE",
        nombre: "JCTE-NEGROC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 4415,
        valor: 607327.4,
        inventario: 4415,
        idArticulo: "62c5e3fb883f0f0017d08b3c",
        precio: 185,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JCTE",
        nombre: "JCTE-KAKIC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 4395,
        valor: 604576.2,
        inventario: 4395,
        idArticulo: "62c5e3b3883f0f0017d08aee",
        precio: 185,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "PACO",
        nombre: "PACO-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2668,
        valor: 601900.7999999999,
        inventario: 2668,
        idArticulo: "62745e0495c45c183877d614",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JCNI",
        nombre: "JCNI-MARINOC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 5318,
        valor: 594339.68,
        inventario: 5318,
        idArticulo: "62c5df26883f0f0017d08611",
        precio: 145,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "CCJUV",
        nombre: "CCJUV-GRISC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 4251,
        valor: 589316.13,
        inventario: 4251,
        idArticulo: "62c5e114883f0f0017d0880a",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "INDI",
        nombre: "INDIGIRLS-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2603,
        valor: 583072,
        inventario: 2603,
        idArticulo: "62c348f427a4e10017c93539",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KITY",
        nombre: "KITY-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3432,
        valor: 581724,
        inventario: 3432,
        idArticulo: "62745dff95c45c183877d3ff",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JCBB",
        nombre: "JCBB-MARINOC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 5381,
        valor: 578242.26,
        inventario: 5381,
        idArticulo: "62c5d4aa883f0f0017d06f88",
        precio: 137,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "BEBE",
        idFamilia: "62744a8b0b6417001680035a",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JCJUV",
        nombre: "JCJUV-CAMUNEC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 4290,
        valor: 567052.2000000001,
        inventario: 4290,
        idArticulo: "62c5e1dc883f0f0017d089bf",
        precio: 174.5,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "LOLA",
        nombre: "LOLA-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2998,
        valor: 566921.7999999999,
        inventario: 2998,
        idArticulo: "62745e0195c45c183877d487",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "STR",
        nombre: "STR100",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2939,
        valor: 566080.79,
        inventario: 2939,
        idArticulo: "62d1163f4287a20017907507",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2301",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 3250,
        valor: 565110,
        inventario: 3250,
        idArticulo: "62c5b05b883f0f0017d030db",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOMBER",
        nombre: "BOMBERBOY-LE2201C",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4261,
        valor: 548603.75,
        inventario: 4261,
        idArticulo: "62bb7679ee07390017cd26ea",
        precio: 188,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JCJUV",
        nombre: "JCJUV-CAMUAZC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 4108,
        valor: 542995.4400000001,
        inventario: 4108,
        idArticulo: "62c5e1a5883f0f0017d08900",
        precio: 174.5,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3023,
        valor: 537489.3999999999,
        inventario: 3023,
        idArticulo: "62745df895c45c183877d089",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JCCH",
        nombre: "JCCH-KAKIC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 4492,
        valor: 530999.32,
        inventario: 4492,
        idArticulo: "62c5dc2b883f0f0017d08175",
        precio: 155,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JCCH",
        nombre: "JCCH-CAMUVEC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 4407,
        valor: 530426.52,
        inventario: 4407,
        idArticulo: "62c5d749883f0f0017d0751e",
        precio: 162.2,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JCJUV",
        nombre: "JCJUV-GRISC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 4104,
        valor: 520428.24,
        inventario: 4104,
        idArticulo: "62c5e29f883f0f0017d08a00",
        precio: 167,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2303",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2920,
        valor: 507729.6,
        inventario: 2920,
        idArticulo: "62c5b102883f0f0017d031f2",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JCNI",
        nombre: "JCNI-CAMUVEC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 4414,
        valor: 507521.72000000003,
        inventario: 4414,
        idArticulo: "62c5debf883f0f0017d08527",
        precio: 152.5,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "CCJUV",
        nombre: "CCJUV-CAMUVEC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3454,
        valor: 497410.54,
        inventario: 3454,
        idArticulo: "62c5dfd3883f0f0017d086aa",
        precio: 187.5,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2578,
        valor: 496265,
        inventario: 2578,
        idArticulo: "62cef3fdfb6b4e00175ab159",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SHAKIRA",
        nombre: "SHAKIRA-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2045,
        valor: 494583.25,
        inventario: 2045,
        idArticulo: "634c4adad74f160016d19f48",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1695,
        valor: 493245,
        inventario: 1695,
        idArticulo: "62745dfc95c45c183877d26a",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1693,
        valor: 492663,
        inventario: 1693,
        idArticulo: "62745dfc95c45c183877d268",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "JCBB",
        nombre: "JCBB-CAMUVEC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 4450,
        valor: 487764.5,
        inventario: 4450,
        idArticulo: "62c5d454883f0f0017d06ee9",
        precio: 144.5,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "BEBE",
        idFamilia: "62744a8b0b6417001680035a",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "CCJUV",
        nombre: "CCJUV-NEGROC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3500,
        valor: 485205,
        inventario: 3500,
        idArticulo: "62c5e0a8883f0f0017d0875d",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2308",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3176,
        valor: 477860.96,
        inventario: 3176,
        idArticulo: "62ed923750fb0700166b36dc",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIKI",
        nombre: "KIKI-LE2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2775,
        valor: 476606.25,
        inventario: 2775,
        idArticulo: "62745dff95c45c183877d3d2",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2302",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2714,
        valor: 471910.32,
        inventario: 2714,
        idArticulo: "62c5b0c6883f0f0017d031a7",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ALDO",
        nombre: "ALDO-BL2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2215,
        valor: 470687.5,
        inventario: 2215,
        idArticulo: "62745df595c45c183877cf81",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2310",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3094,
        valor: 468833.82,
        inventario: 3094,
        idArticulo: "62ed92ad50fb0700166b3733",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LAX",
        nombre: "LAX-XT2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2423,
        valor: 468365.89999999997,
        inventario: 2423,
        idArticulo: "62745e0095c45c183877d43c",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2205Q",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 3013,
        valor: 467617.6,
        inventario: 3013,
        idArticulo: "63166e77cbb556001628cfa3",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KILE",
        nombre: "KILE-LE2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2492,
        valor: 459400.2,
        inventario: 2492,
        idArticulo: "628be7f495692838f4c58bb0",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2742,
        valor: 456543,
        inventario: 2742,
        idArticulo: "62745e0495c45c183877d602",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2313",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3056,
        valor: 456505.27999999997,
        inventario: 3056,
        idArticulo: "62ed933450fb0700166b37c2",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2311",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2840,
        valor: 448663.19999999995,
        inventario: 2840,
        idArticulo: "62ed92d050fb0700166b3740",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OSLO",
        nombre: "OSLO-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3022,
        valor: 448071.93999999994,
        inventario: 3022,
        idArticulo: "62745e0495c45c183877d5e2",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2209",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3574,
        valor: 446750,
        inventario: 3574,
        idArticulo: "62745e0295c45c183877d513",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OSLO",
        nombre: "OSLO-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3006,
        valor: 445699.62,
        inventario: 3006,
        idArticulo: "62745e0495c45c183877d5e5",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIM",
        nombre: "KIM-LE2202Q",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 3035,
        valor: 445538.00000000006,
        inventario: 3035,
        idArticulo: "62cf3129fb6b4e00175adbf5",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2208",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2621,
        valor: 443473.19999999995,
        inventario: 2621,
        idArticulo: "628be7f295692838f4c58b34",
        precio: 250,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KENTHOOD",
        nombre: "KENTHOOD-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1811,
        valor: 442789.5,
        inventario: 1811,
        idArticulo: "62745dfe95c45c183877d37a",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "BARELA",
        nombre: "BARELA-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2044,
        valor: 440890.80000000005,
        inventario: 2044,
        idArticulo: "62745df795c45c183877d014",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOMBER",
        nombre: "BOMBERTEEN-LE2201C",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3094,
        valor: 438048.52,
        inventario: 3094,
        idArticulo: "62bb764aee07390017cd261d",
        precio: 195,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "STEFY",
        nombre: "STEFY-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3590,
        valor: 437261.99999999994,
        inventario: 3590,
        idArticulo: "62745e0995c45c183877d7f6",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TANIAGIRLS",
        nombre: "TANIAGIRLS-XT2211",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1979,
        valor: 436666.35000000003,
        inventario: 1979,
        idArticulo: "631f4d10803c1f0016a31313",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "LOLAHOOD",
        nombre: "LOLAHOOD-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2186,
        valor: 436325.6,
        inventario: 2186,
        idArticulo: "62745e0195c45c183877d483",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "DONNA",
        nombre: "DONNA-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2000,
        valor: 434400,
        inventario: 2000,
        idArticulo: "62745dfa95c45c183877d18f",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NICOLE",
        nombre: "NICOLE-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 3475,
        valor: 434375,
        inventario: 3475,
        idArticulo: "62745e0295c45c183877d552",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JCTE",
        nombre: "JCTE-CAMUVEC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3021,
        valor: 431791.53,
        inventario: 3021,
        idArticulo: "62c5e380883f0f0017d08ab6",
        precio: 192.5,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NIKA",
        nombre: "NIKA-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 4538,
        valor: 430202.39999999997,
        inventario: 4538,
        idArticulo: "62745e0395c45c183877d586",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TANIAGIRLS",
        nombre: "TANIAGIRLS-XT2213",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1949,
        valor: 430046.85000000003,
        inventario: 1949,
        idArticulo: "631f4d59803c1f0016a31377",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ALDOPLUS",
        nombre: "ALDOPLUS-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1847,
        valor: 426934.05000000005,
        inventario: 1847,
        idArticulo: "62745df695c45c183877cf8d",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "JCNI",
        nombre: "JCNI-OLIVOC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3797,
        valor: 424352.72000000003,
        inventario: 3797,
        idArticulo: "62c5df75883f0f0017d0866d",
        precio: 145,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NIKA",
        nombre: "NIKA-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 4468,
        valor: 423566.4,
        inventario: 4468,
        idArticulo: "62745e0395c45c183877d591",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "CLOEGIRL",
        nombre: "CLOEGIRL-LE2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2714,
        valor: 420805.7,
        inventario: 2714,
        idArticulo: "62745df995c45c183877d12b",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2307",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2662,
        valor: 420542.75999999995,
        inventario: 2662,
        idArticulo: "62ed920b50fb0700166b367c",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JCCH",
        nombre: "JCCH-MARINOC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3529,
        valor: 417163.08999999997,
        inventario: 3529,
        idArticulo: "62c5dc55883f0f0017d081d7",
        precio: 155,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2208",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2666,
        valor: 413763.19999999995,
        inventario: 2666,
        idArticulo: "62745dfe95c45c183877d35a",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2418,
        valor: 409609.2,
        inventario: 2418,
        idArticulo: "62745e0a95c45c183877d855",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JCNI",
        nombre: "JCNI-NEGROC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3657,
        valor: 408706.32,
        inventario: 3657,
        idArticulo: "62c5df4a883f0f0017d0861e",
        precio: 145,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2208",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3250,
        valor: 406250,
        inventario: 3250,
        idArticulo: "62745e0295c45c183877d517",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2207",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2614,
        valor: 405692.8,
        inventario: 2614,
        idArticulo: "62745dfe95c45c183877d358",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SHELLY",
        nombre: "SHELLY-LE2202A",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1741,
        valor: 404608.4,
        inventario: 1741,
        idArticulo: "62745e0895c45c183877d7b4",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEMO",
        nombre: "NEMO-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2107,
        valor: 399487.2,
        inventario: 2107,
        idArticulo: "62745e0295c45c183877d525",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KENDALL",
        nombre: "KENDALL-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 3705,
        valor: 396990.75000000006,
        inventario: 3705,
        idArticulo: "62745dfe95c45c183877d376",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2207",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3153,
        valor: 394125,
        inventario: 3153,
        idArticulo: "62745e0295c45c183877d50c",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2102",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 3501,
        valor: 393337.35,
        inventario: 3501,
        idArticulo: "62745df895c45c183877d097",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1344,
        valor: 391104,
        inventario: 1344,
        idArticulo: "62745dfc95c45c183877d270",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2345,
        valor: 390442.5,
        inventario: 2345,
        idArticulo: "62745e0495c45c183877d604",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2206",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3107,
        valor: 388375,
        inventario: 3107,
        idArticulo: "62745e0295c45c183877d509",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CCJUV",
        nombre: "CCJUV-OLIVOC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2800,
        valor: 388164,
        inventario: 2800,
        idArticulo: "62c5e0de883f0f0017d087aa",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "KIMGIRL",
        nombre: "KIMGIRL-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 3016,
        valor: 387646.48,
        inventario: 3016,
        idArticulo: "62745dff95c45c183877d3f0",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JCBB",
        nombre: "JCBB-OLIVOC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3589,
        valor: 385673.94,
        inventario: 3589,
        idArticulo: "62c5d509883f0f0017d070a8",
        precio: 137,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "BEBE",
        idFamilia: "62744a8b0b6417001680035a",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "KIKI",
        nombre: "KIKI-LE2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2240,
        valor: 384720,
        inventario: 2240,
        idArticulo: "62745dff95c45c183877d3d5",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2202",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3049,
        valor: 381125,
        inventario: 3049,
        idArticulo: "62745e0295c45c183877d501",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KENDALL",
        nombre: "KENDALL-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 3554,
        valor: 380811.10000000003,
        inventario: 3554,
        idArticulo: "62745dfe95c45c183877d374",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TURI",
        nombre: "TURI-LE2301C",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1800,
        valor: 376596,
        inventario: 1800,
        idArticulo: "62ffcd025e9fa60016eb865c",
        precio: 320,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 3369,
        valor: 372442.94999999995,
        inventario: 3369,
        idArticulo: "62745e0395c45c183877d598",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2302",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2486,
        valor: 371358.68,
        inventario: 2486,
        idArticulo: "62ed90f750fb0700166b35d0",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEGO",
        nombre: "LEGO-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 2403,
        valor: 366697.8,
        inventario: 2403,
        idArticulo: "628be7f495692838f4c58b98",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NEMO",
        nombre: "NEMO-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1921,
        valor: 364221.6,
        inventario: 1921,
        idArticulo: "62745e0295c45c183877d51e",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2301",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2301,
        valor: 363511.98,
        inventario: 2301,
        idArticulo: "62ed90b350fb0700166b351c",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2305",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2397,
        valor: 363217.41,
        inventario: 2397,
        idArticulo: "62ed91c250fb0700166b3662",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1158,
        valor: 362454,
        inventario: 1158,
        idArticulo: "62745dfc95c45c183877d290",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2206",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2332,
        valor: 361926.4,
        inventario: 2332,
        idArticulo: "62745dfe95c45c183877d356",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BRABY",
        nombre: "BRABY-LE2301C",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1809,
        valor: 359032.23,
        inventario: 1809,
        idArticulo: "62ffcc865e9fa60016eb864f",
        precio: 320,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KELLY",
        nombre: "KELLY-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1158,
        valor: 358864.19999999995,
        inventario: 1158,
        idArticulo: "62745dfe95c45c183877d36a",
        precio: 490,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "KOTY",
        nombre: "KOTY-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2112,
        valor: 357984,
        inventario: 2112,
        idArticulo: "62745dff95c45c183877d3fd",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2303,
        valor: 357425.6,
        inventario: 2303,
        idArticulo: "62745dfe95c45c183877d346",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KISS",
        nombre: "KISS-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 3060,
        valor: 356030.99999999994,
        inventario: 3060,
        idArticulo: "62745dff95c45c183877d3f6",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1217,
        valor: 354147,
        inventario: 1217,
        idArticulo: "62745dfc95c45c183877d264",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2309",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2335,
        valor: 353822.55,
        inventario: 2335,
        idArticulo: "62ed926450fb0700166b36e9",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2109,
        valor: 351148.5,
        inventario: 2109,
        idArticulo: "62745e0495c45c183877d606",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "MONIQUEHOOD",
        nombre: "MONIQUEHOOD-MG2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 2173,
        valor: 345507,
        inventario: 2173,
        idArticulo: "62745e0195c45c183877d4e6",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2312",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2303,
        valor: 344022.14,
        inventario: 2303,
        idArticulo: "62ed92eb50fb0700166b3766",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 3171,
        valor: 340565.4,
        inventario: 3171,
        idArticulo: "62745e0395c45c183877d578",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2205",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2002,
        valor: 338738.4,
        inventario: 2002,
        idArticulo: "62745e0295c45c183877d53d",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2205",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1274,
        valor: 337291.5,
        inventario: 1274,
        idArticulo: "62745df595c45c183877cf4b",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "REFLEX",
        nombre: "REFLEXMEN-LE2301C",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1673,
        valor: 336189.35,
        inventario: 1673,
        idArticulo: "630f83ec01870a0016969a74",
        precio: 270,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BIKERHOOD",
        nombre: "BIKERHOOD-MG2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1535,
        valor: 334169.5,
        inventario: 1535,
        idArticulo: "62745df795c45c183877d035",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SWIM",
        nombre: "SWIM#1",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 6083,
        valor: 331888.48000000004,
        inventario: 6083,
        idArticulo: "62ed63249416cf0016d47e88",
        precio: 85,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "SWIM",
        nombre: "SWIM#2",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 6083,
        valor: 331888.48000000004,
        inventario: 6083,
        idArticulo: "62ed67969416cf0016d4840f",
        precio: 82,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NICOLE",
        nombre: "NICOLE-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2633,
        valor: 329125,
        inventario: 2633,
        idArticulo: "62745e0295c45c183877d54d",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2304",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2016,
        valor: 318487.68,
        inventario: 2016,
        idArticulo: "62ed917050fb0700166b3624",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1008,
        valor: 315504,
        inventario: 1008,
        idArticulo: "62745dfc95c45c183877d290",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ALDOBOY",
        nombre: "ALDOBOY-BL2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1714,
        valor: 313319.19999999995,
        inventario: 1714,
        idArticulo: "62745df595c45c183877cf7f",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "LAX",
        nombre: "LAX-XT2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1619,
        valor: 312952.69999999995,
        inventario: 1619,
        idArticulo: "62745e0095c45c183877d43e",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "COSMIC",
        nombre: "COSMIC-XT2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1280,
        valor: 312320,
        inventario: 1280,
        idArticulo: "62745df995c45c183877d13d",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2207",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1924,
        valor: 309571.60000000003,
        inventario: 1924,
        idArticulo: "62745dff95c45c183877d3bc",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "TANIAPLUS",
        nombre: "TANIAPLUS-XT2211",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1200,
        valor: 308880,
        inventario: 1200,
        idArticulo: "631f4c3b803c1f0016a3124c",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1610,
        valor: 303146.89999999997,
        inventario: 1610,
        idArticulo: "62745e0395c45c183877d5d0",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "CCJUV",
        nombre: "CCJUV-CAMUNEC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2094,
        valor: 301556.94,
        inventario: 2094,
        idArticulo: "62c5e00b883f0f0017d086e2",
        precio: 187.5,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2104",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1805,
        valor: 300532.5,
        inventario: 1805,
        idArticulo: "62745e0495c45c183877d609",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "TOKYO",
        nombre: "TOKYO-LW2117",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1915,
        valor: 299793.25,
        inventario: 1915,
        idArticulo: "62745e0995c45c183877d833",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ERVIN",
        nombre: "ERVIN-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1363,
        valor: 296725.10000000003,
        inventario: 1363,
        idArticulo: "62745dfa95c45c183877d1c3",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "GOLDAHOOD",
        nombre: "GOLDAHOOD-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1521,
        valor: 296138.7,
        inventario: 1521,
        idArticulo: "62745dfb95c45c183877d21e",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KILE",
        nombre: "KILE-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1604,
        valor: 295697.39999999997,
        inventario: 1604,
        idArticulo: "62a7bd6353cecd0016f99902",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NICOLE",
        nombre: "NICOLE-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2338,
        valor: 292250,
        inventario: 2338,
        idArticulo: "62745e0295c45c183877d54f",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BARELA",
        nombre: "BARELA-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1343,
        valor: 289685.1,
        inventario: 1343,
        idArticulo: "62745df795c45c183877d019",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1799,
        valor: 289459.10000000003,
        inventario: 1799,
        idArticulo: "62745dff95c45c183877d3b0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "OTIS",
        nombre: "OTIS-MG2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1589,
        valor: 289356.89999999997,
        inventario: 1589,
        idArticulo: "62745e0495c45c183877d5f7",
        precio: 265,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 985,
        valor: 286635,
        inventario: 985,
        idArticulo: "62745dfc95c45c183877d276",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "CCNI",
        nombre: "CCNI-CAMUVEC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2286,
        valor: 284972.76,
        inventario: 2286,
        idArticulo: "62c5ddcd883f0f0017d08355",
        precio: 161.5,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1761,
        valor: 283344.9,
        inventario: 1761,
        idArticulo: "62745dff95c45c183877d3b2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "CCTE",
        nombre: "CCTE-MARINOC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1872,
        valor: 281661.12,
        inventario: 1872,
        idArticulo: "62c5e32e883f0f0017d08a27",
        precio: 198,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 897,
        valor: 280761,
        inventario: 897,
        idArticulo: "62c3453d27a4e10017c9338e",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "CCCH",
        nombre: "CCCH-KAKIC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2225,
        valor: 279749.25,
        inventario: 2225,
        idArticulo: "62c5d67c883f0f0017d072f7",
        precio: 170,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2206",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1619,
        valor: 273934.80000000005,
        inventario: 1619,
        idArticulo: "62745e0295c45c183877d53f",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "JCCH",
        nombre: "JCCH-NEGROC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2304,
        valor: 272355.83999999997,
        inventario: 2304,
        idArticulo: "62c5dc9e883f0f0017d08218",
        precio: 155,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "CCNI",
        nombre: "CCNI-KAKIC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2250,
        valor: 270810,
        inventario: 2250,
        idArticulo: "62c5de1a883f0f0017d0838f",
        precio: 154,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "CCBB",
        nombre: "CCBB-CAMUVEC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2270,
        valor: 268336.7,
        inventario: 2270,
        idArticulo: "62c5cf7a883f0f0017d062f0",
        precio: 153.5,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "BEBE",
        idFamilia: "62744a8b0b6417001680035a",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1404,
        valor: 266198.4,
        inventario: 1404,
        idArticulo: "62745dfa95c45c183877d19f",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ERVIN",
        nombre: "ERVIN-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1215,
        valor: 264505.5,
        inventario: 1215,
        idArticulo: "62745dfa95c45c183877d1bb",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2106",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1849,
        valor: 264037.19999999995,
        inventario: 1849,
        idArticulo: "62745e0595c45c183877d690",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 839,
        valor: 262607,
        inventario: 839,
        idArticulo: "62c3424127a4e10017c93084",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROBIN",
        nombre: "ROBIN-XT2202",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 2344,
        valor: 262176.39999999997,
        inventario: 2344,
        idArticulo: "62745e0795c45c183877d73a",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "CCBB",
        nombre: "CCBB-KAKIC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2251,
        valor: 261251.06,
        inventario: 2251,
        idArticulo: "62c5d0be883f0f0017d063b8",
        precio: 146,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "BEBE",
        idFamilia: "62744a8b0b6417001680035a",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "GOLDA",
        nombre: "GOLDA-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1333,
        valor: 259535.1,
        inventario: 1333,
        idArticulo: "62745dfb95c45c183877d21c",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SWIM",
        nombre: "SWIM#3",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 4683,
        valor: 255504.48,
        inventario: 4683,
        idArticulo: "62ed67b59416cf0016d48470",
        precio: 82,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "DNZM",
        nombre: "DNZM-LE2308C",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 2101,
        valor: 252855.34999999998,
        inventario: 2101,
        idArticulo: "62ed76639416cf0016d49857",
        precio: 154,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DNZM",
        nombre: "DNZM-LE2307C",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 2098,
        valor: 252494.3,
        inventario: 2098,
        idArticulo: "62ed76409416cf0016d497c1",
        precio: 154,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KENDALL",
        nombre: "KENDALL-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2352,
        valor: 252016.80000000002,
        inventario: 2352,
        idArticulo: "62745dfe95c45c183877d36c",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DNZM",
        nombre: "DNZM-LE2305C",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 2094,
        valor: 252012.9,
        inventario: 2094,
        idArticulo: "62ed75c39416cf0016d49603",
        precio: 154,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2208",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 951,
        valor: 251777.25,
        inventario: 951,
        idArticulo: "62745df595c45c183877cf51",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2108",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1760,
        valor: 251327.99999999997,
        inventario: 1760,
        idArticulo: "62745e0595c45c183877d694",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DNZM",
        nombre: "DNZM-LE2304C",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 2088,
        valor: 251290.8,
        inventario: 2088,
        idArticulo: "62ed759e9416cf0016d495be",
        precio: 154,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TOKYO",
        nombre: "TOKYO-LW2114",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1600,
        valor: 250480.00000000003,
        inventario: 1600,
        idArticulo: "62745e0995c45c183877d82d",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SIENAHOOD",
        nombre: "SIENAHOOD-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1213,
        valor: 250363.2,
        inventario: 1213,
        idArticulo: "62745e0895c45c183877d7be",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KILA",
        nombre: "KILA-HN2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 1395,
        valor: 249844.5,
        inventario: 1395,
        idArticulo: "62745dff95c45c183877d3de",
        precio: 235,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "FELIX",
        nombre: "FELIX-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1095,
        valor: 245006.25,
        inventario: 1095,
        idArticulo: "62745dfb95c45c183877d1d5",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1266,
        valor: 243705,
        inventario: 1266,
        idArticulo: "62745e0295c45c183877d561",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KATY",
        nombre: "KATY-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 807,
        valor: 239921.1,
        inventario: 807,
        idArticulo: "62745dfe95c45c183877d368",
        precio: 490,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "PEACHHOOD",
        nombre: "PEACHHOOD-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 1255,
        valor: 236015.3,
        inventario: 1255,
        idArticulo: "62745e0495c45c183877d640",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA568#401",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1022,
        valor: 235060,
        inventario: 1022,
        idArticulo: "6308f072b511770016a3b078",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2206",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1458,
        valor: 234592.2,
        inventario: 1458,
        idArticulo: "62745dff95c45c183877d398",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2206",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 870,
        valor: 230332.5,
        inventario: 870,
        idArticulo: "62745df595c45c183877cf47",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ALDOBOY",
        nombre: "ALDOBOY-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1256,
        valor: 229596.8,
        inventario: 1256,
        idArticulo: "62745df695c45c183877cf86",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "OSLO",
        nombre: "OSLO-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1546,
        valor: 229225.41999999998,
        inventario: 1546,
        idArticulo: "62745e0495c45c183877d5eb",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1213,
        valor: 228395.77,
        inventario: 1213,
        idArticulo: "62745e0395c45c183877d5c7",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "FELIX",
        nombre: "FELIX-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1010,
        valor: 225987.5,
        inventario: 1010,
        idArticulo: "62745dfb95c45c183877d1cd",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2208",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1403,
        valor: 225742.7,
        inventario: 1403,
        idArticulo: "62745dff95c45c183877d3c0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "JCCH",
        nombre: "JCCH-OLIVOC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1899,
        valor: 224480.78999999998,
        inventario: 1899,
        idArticulo: "62c5dcc8883f0f0017d082b8",
        precio: 155,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "BARELAHOOD",
        nombre: "BARELAHOOD-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1040,
        valor: 224328,
        inventario: 1040,
        idArticulo: "62745df795c45c183877d016",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2301",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 1772,
        valor: 221943,
        inventario: 1772,
        idArticulo: "62ed72839416cf0016d48ec5",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEMO",
        nombre: "NEMO-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1165,
        valor: 220884,
        inventario: 1165,
        idArticulo: "62745e0295c45c183877d51a",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2211",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1081,
        valor: 219443,
        inventario: 1081,
        idArticulo: "62745df895c45c183877d073",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "REFLEX",
        nombre: "REFLEXTEEN-LE2301C",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1197,
        valor: 217375.19999999998,
        inventario: 1197,
        idArticulo: "630f842301870a0016969ae2",
        precio: 256,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "TEEN",
        idFamilia: "62fc14e5f64ad5001675a4d5",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOMBER",
        nombre: "BOMBERBABY-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1785,
        valor: 216966.75,
        inventario: 1785,
        idArticulo: "62bb76d2ee07390017cd2705",
        precio: 1,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "BEBE",
        idFamilia: "62744a8b0b6417001680035a",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "CCNI",
        nombre: "CCNI-MARINOC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1800,
        valor: 216648,
        inventario: 1800,
        idArticulo: "62c5de40883f0f0017d08409",
        precio: 154,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "CCNI",
        nombre: "CCNI-NEGROC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1800,
        valor: 216648,
        inventario: 1800,
        idArticulo: "62c5de64883f0f0017d0842a",
        precio: 154,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "CCNI",
        nombre: "CCNI-OLIVOC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1800,
        valor: 216648,
        inventario: 1800,
        idArticulo: "62c5de88883f0f0017d084b0",
        precio: 154,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2204",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1332,
        valor: 214318.80000000002,
        inventario: 1332,
        idArticulo: "62745dff95c45c183877d3b4",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 683,
        valor: 213779,
        inventario: 683,
        idArticulo: "62c3456127a4e10017c933b6",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JCBB",
        nombre: "JCBB-NEGROC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1966,
        valor: 211266.36,
        inventario: 1966,
        idArticulo: "62c5d4d4883f0f0017d07037",
        precio: 137,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "BEBE",
        idFamilia: "62744a8b0b6417001680035a",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2201",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1238,
        valor: 209469.60000000003,
        inventario: 1238,
        idArticulo: "62745e0295c45c183877d530",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "CCBB",
        nombre: "CCBB-MARINOC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1800,
        valor: 208908,
        inventario: 1800,
        idArticulo: "62c5d190883f0f0017d067d1",
        precio: 146,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "BEBE",
        idFamilia: "62744a8b0b6417001680035a",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "CCBB",
        nombre: "CCBB-OLIVOC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1800,
        valor: 208908,
        inventario: 1800,
        idArticulo: "62c5d1f6883f0f0017d069b0",
        precio: 146,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "BEBE",
        idFamilia: "62744a8b0b6417001680035a",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "REFLEX",
        nombre: "REFLEXBOY-LE2301C",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1222,
        valor: 206151.4,
        inventario: 1222,
        idArticulo: "630f845701870a0016969c06",
        precio: 243,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2304",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1165,
        valor: 202570.19999999998,
        inventario: 1165,
        idArticulo: "62c5b130883f0f0017d03200",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2207",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1240,
        valor: 199516,
        inventario: 1240,
        idArticulo: "62745dff95c45c183877d39c",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "INDI",
        nombre: "INDIGIRLS-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 863,
        valor: 193312,
        inventario: 863,
        idArticulo: "62c3483b27a4e10017c93505",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PETER",
        nombre: "PETER-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 844,
        valor: 192854,
        inventario: 844,
        idArticulo: "62745e0595c45c183877d66e",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "SIENAHOOD",
        nombre: "SIENAHOOD-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 931,
        valor: 192158.40000000002,
        inventario: 931,
        idArticulo: "62745e0895c45c183877d7ba",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "22#",
        nombre: "22#0979",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1320,
        valor: 190146.00000000003,
        inventario: 1320,
        idArticulo: "634c575ed74f160016d1a0ce",
        precio: 265,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CCCH",
        nombre: "CCCH-MARINOC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1512,
        valor: 190103.76,
        inventario: 1512,
        idArticulo: "62c5d6b4883f0f0017d073bc",
        precio: 170,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZCRG-LR2201",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 1435,
        valor: 189420,
        inventario: 1435,
        idArticulo: "62ed74319416cf0016d492e8",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 958,
        valor: 181636.8,
        inventario: 958,
        idArticulo: "62745dfa95c45c183877d197",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2210",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 619,
        valor: 180129,
        inventario: 619,
        idArticulo: "62745dfc95c45c183877d27f",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "SWIM",
        nombre: "SWIM#6",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 3283,
        valor: 179120.48,
        inventario: 3283,
        idArticulo: "639368696a7e3d0016418a4a",
        precio: 82,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KIKI",
        nombre: "KIKI-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1035,
        valor: 177761.25,
        inventario: 1035,
        idArticulo: "62745dff95c45c183877d3d9",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "INDI",
        nombre: "INDIGIRLS-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 780,
        valor: 174720,
        inventario: 780,
        idArticulo: "62c3483b27a4e10017c93505",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2203",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1030,
        valor: 174276.00000000003,
        inventario: 1030,
        idArticulo: "62745e0295c45c183877d53b",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "CCTE",
        nombre: "CCTE-CAMUVEC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1092,
        valor: 170166.36000000002,
        inventario: 1092,
        idArticulo: "62c5e2e2883f0f0017d08a0d",
        precio: 205.5,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ROX",
        nombre: "ROX-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1001,
        valor: 169669.5,
        inventario: 1001,
        idArticulo: "628be7f495692838f4c58bb6",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 829,
        valor: 168287,
        inventario: 829,
        idArticulo: "62745df795c45c183877d05d",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SWIM",
        nombre: "SWIM#1PLUS",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 2969,
        valor: 165195.16,
        inventario: 2969,
        idArticulo: "62ed637d9416cf0016d47ec6",
        precio: 90,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "SWIM",
        nombre: "SWIM#2PLUS",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 2963,
        valor: 164861.32,
        inventario: 2963,
        idArticulo: "62ed63a19416cf0016d47ed3",
        precio: 90,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "CCTE",
        nombre: "CCTE-KAKIC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1080,
        valor: 162496.80000000002,
        inventario: 1080,
        idArticulo: "62c5e30c883f0f0017d08a1a",
        precio: 198,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "CCTE",
        nombre: "CCTE-NEGROC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1080,
        valor: 162496.80000000002,
        inventario: 1080,
        idArticulo: "62c5e352883f0f0017d08a34",
        precio: 198,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NEO",
        nombre: "NEO-LE2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 984,
        valor: 155816.4,
        inventario: 984,
        idArticulo: "62745e0295c45c183877d52e",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2105",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 926,
        valor: 154179,
        inventario: 926,
        idArticulo: "62745e0495c45c183877d60f",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 492,
        valor: 153996,
        inventario: 492,
        idArticulo: "62c3453d27a4e10017c9338e",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "INDI",
        nombre: "INDIGIRLS-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 685,
        valor: 153440,
        inventario: 685,
        idArticulo: "62c348f427a4e10017c93539",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ANITAHOOD",
        nombre: "ANITAHOOD-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 794,
        valor: 151257,
        inventario: 794,
        idArticulo: "62745df695c45c183877cfea",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA568#404",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 653,
        valor: 150190,
        inventario: 653,
        idArticulo: "6308f04ab511770016a3b02c",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VIENA",
        nombre: "VIENA-HN2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 583,
        valor: 150064.19999999998,
        inventario: 583,
        idArticulo: "62745e0b95c45c183877d8d3",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2202",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 883,
        valor: 149403.6,
        inventario: 883,
        idArticulo: "62745e0295c45c183877d537",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KENT",
        nombre: "KENT-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 632,
        valor: 149215.19999999998,
        inventario: 632,
        idArticulo: "62745dfe95c45c183877d380",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "PARIS",
        nombre: "PARIS-HN2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 839,
        valor: 148083.5,
        inventario: 839,
        idArticulo: "62745e0495c45c183877d623",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "BOMBER",
        nombre: "BOMBERMEN-LE2201C",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 962,
        valor: 146589.56,
        inventario: 962,
        idArticulo: "62bb75f3ee07390017cd25e8",
        precio: 202,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KENDALL",
        nombre: "KENDALL-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1360,
        valor: 145724,
        inventario: 1360,
        idArticulo: "62745dfe95c45c183877d36e",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "HELLO",
        nombre: "HELLO-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1062,
        valor: 143635.5,
        inventario: 1062,
        idArticulo: "62745dfc95c45c183877d256",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 745,
        valor: 141252,
        inventario: 745,
        idArticulo: "62745dfa95c45c183877d195",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 442,
        valor: 138346,
        inventario: 442,
        idArticulo: "62c3424127a4e10017c93084",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2306",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 909,
        valor: 137531.7,
        inventario: 909,
        idArticulo: "63503cb66819510016f65316",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ERVIN",
        nombre: "ERVIN-MG2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 629,
        valor: 136933.3,
        inventario: 629,
        idArticulo: "62745dfa95c45c183877d1c5",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 707,
        valor: 134047.19999999998,
        inventario: 707,
        idArticulo: "62745dfa95c45c183877d193",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OLIVIA",
        nombre: "OLIVIA-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 732,
        valor: 133736.4,
        inventario: 732,
        idArticulo: "62745e0395c45c183877d5ba",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SWIM",
        nombre: "SWIM#7PLUS",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 2396,
        valor: 133313.44,
        inventario: 2396,
        idArticulo: "62ed63cb9416cf0016d47ee0",
        precio: 90,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2303",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 1048,
        valor: 131262,
        inventario: 1048,
        idArticulo: "62ed73979416cf0016d49147",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2302",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 815,
        valor: 130318.5,
        inventario: 815,
        idArticulo: "63503bed6819510016f65231",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROX",
        nombre: "ROX-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 756,
        valor: 128142,
        inventario: 756,
        idArticulo: "62745e0795c45c183877d77a",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 408,
        valor: 127704,
        inventario: 408,
        idArticulo: "62c3453d27a4e10017c9338e",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 384,
        valor: 127680,
        inventario: 384,
        idArticulo: "62745e0795c45c183877d74d",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "TANIAPLUS",
        nombre: "TANIAPLUS-XT2212",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 495,
        valor: 127412.99999999999,
        inventario: 495,
        idArticulo: "631f4ca5803c1f0016a3129c",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 711,
        valor: 126415.79999999999,
        inventario: 711,
        idArticulo: "62745df895c45c183877d07e",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "WARIO",
        nombre: "WARIO-XT2302",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 2178,
        valor: 125866.62,
        inventario: 2178,
        idArticulo: "62ed65ac9416cf0016d4800c",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2303",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 830,
        valor: 125579.00000000001,
        inventario: 830,
        idArticulo: "63503c1fd03b310016e5f9e9",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2301",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 720,
        valor: 125193.59999999999,
        inventario: 720,
        idArticulo: "62c5b05b883f0f0017d030db",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "INDI",
        nombre: "INDIGIRLS-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 557,
        valor: 124768,
        inventario: 557,
        idArticulo: "62c348f427a4e10017c93539",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2204",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 774,
        valor: 124536.6,
        inventario: 774,
        idArticulo: "62745dfe95c45c183877d390",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "BOMBER",
        nombre: "BOMBERBP-LE2201C",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 745,
        valor: 122403.50000000001,
        inventario: 745,
        idArticulo: "62bb6958ee07390017cd1263",
        precio: 224,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "WARIO",
        nombre: "WARIO-XT2303",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 2107,
        valor: 121763.53,
        inventario: 2107,
        idArticulo: "62ed65cc9416cf0016d4804e",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1089,
        valor: 120388.95,
        inventario: 1089,
        idArticulo: "62745e0395c45c183877d596",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2303",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 672,
        valor: 116847.36,
        inventario: 672,
        idArticulo: "62c5b102883f0f0017d031f2",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "CCBB",
        nombre: "CCBB-NEGROC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1000,
        valor: 116060,
        inventario: 1000,
        idArticulo: "62c5d1c4883f0f0017d068cd",
        precio: 146,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "BEBE",
        idFamilia: "62744a8b0b6417001680035a",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "QUEENS",
        nombre: "QUEENS-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 519,
        valor: 114076.20000000001,
        inventario: 519,
        idArticulo: "62745e0695c45c183877d6fa",
        precio: 345,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DONNA",
        nombre: "DONNA-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 522,
        valor: 113378.4,
        inventario: 522,
        idArticulo: "62745dfa95c45c183877d18d",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SILVIA",
        nombre: "SILVIA-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 631,
        valor: 113359.15,
        inventario: 631,
        idArticulo: "62745e0895c45c183877d7cc",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2206",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 650,
        valor: 109980.00000000001,
        inventario: 650,
        idArticulo: "62745e0295c45c183877d53f",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "SURF",
        nombre: "SURFPRINT#1",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1897,
        valor: 109627.62999999999,
        inventario: 1897,
        idArticulo: "62ed60119416cf0016d47b51",
        precio: 85,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "STEFY",
        nombre: "STEFY-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 900,
        valor: 109620,
        inventario: 900,
        idArticulo: "62745e0995c45c183877d7fa",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CINDY",
        nombre: "CINDYGIRLS-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 437,
        valor: 107065,
        inventario: 437,
        idArticulo: "62c3493227a4e10017c93546",
        precio: 390,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 336,
        valor: 105168,
        inventario: 336,
        idArticulo: "62c3424127a4e10017c93084",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 334,
        valor: 104542,
        inventario: 334,
        idArticulo: "62745dfc95c45c183877d290",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2304",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 600,
        valor: 104328,
        inventario: 600,
        idArticulo: "62c5b130883f0f0017d03200",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2302",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 799,
        valor: 100074.75,
        inventario: 799,
        idArticulo: "62ed736a9416cf0016d490dd",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2104",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 695,
        valor: 99245.99999999999,
        inventario: 695,
        idArticulo: "62745e0595c45c183877d687",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SURF",
        nombre: "SURFPRINT#7",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1707,
        valor: 98647.53,
        inventario: 1707,
        idArticulo: "62ed61299416cf0016d47d15",
        precio: 85,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 554,
        valor: 98501.2,
        inventario: 554,
        idArticulo: "62745df895c45c183877d07e",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2201",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 572,
        valor: 96782.40000000001,
        inventario: 572,
        idArticulo: "62745e0295c45c183877d530",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "INDI",
        nombre: "INDIGIRLS-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 432,
        valor: 96768,
        inventario: 432,
        idArticulo: "62c348f427a4e10017c93539",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SURF",
        nombre: "SURFPRINT#13",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1651,
        valor: 95411.29,
        inventario: 1651,
        idArticulo: "62ed62229416cf0016d47e26",
        precio: 85,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2305",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 619,
        valor: 94985.54999999999,
        inventario: 619,
        idArticulo: "63503c8dd03b310016e5fa3e",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SURF",
        nombre: "SURFPRINT#8",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1641,
        valor: 94833.39,
        inventario: 1641,
        idArticulo: "62ed61579416cf0016d47d9b",
        precio: 85,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2203",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 358,
        valor: 94780.5,
        inventario: 358,
        idArticulo: "62745df595c45c183877cf3d",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 485,
        valor: 93362.5,
        inventario: 485,
        idArticulo: "62745e0395c45c183877d566",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SURF",
        nombre: "SURFPRINT#5",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1600,
        valor: 92463.99999999999,
        inventario: 1600,
        idArticulo: "62ed60d39416cf0016d47cf1",
        precio: 85,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "SURF",
        nombre: "SURFPRINT#12",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1598,
        valor: 92348.42,
        inventario: 1598,
        idArticulo: "62ed61ff9416cf0016d47e19",
        precio: 85,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "FILIP",
        nombre: "FILIP-KV2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 455,
        valor: 89271.00000000001,
        inventario: 455,
        idArticulo: "62745dfb95c45c183877d1e7",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "SURF",
        nombre: "SURFPRINT#11",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1541,
        valor: 89054.39,
        inventario: 1541,
        idArticulo: "62ed61d99416cf0016d47e0c",
        precio: 85,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "SHAKIRA",
        nombre: "SHAKIRA-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 363,
        valor: 87791.55,
        inventario: 363,
        idArticulo: "62745e0895c45c183877d7ae",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 450,
        valor: 86625,
        inventario: 450,
        idArticulo: "62745e0395c45c183877d566",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SURF",
        nombre: "SURFPRINT#2",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1497,
        valor: 86511.62999999999,
        inventario: 1497,
        idArticulo: "62ed60539416cf0016d47bbf",
        precio: 85,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 477,
        valor: 84810.59999999999,
        inventario: 477,
        idArticulo: "62745df895c45c183877d087",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JFK",
        nombre: "JFK-XT2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 402,
        valor: 83616,
        inventario: 402,
        idArticulo: "62745dfd95c45c183877d2d0",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "RIKO",
        nombre: "RIKO-HN2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 521,
        valor: 83281.85,
        inventario: 521,
        idArticulo: "62745e0795c45c183877d71d",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "OREGON",
        nombre: "OREGON-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 564,
        valor: 83268.95999999999,
        inventario: 564,
        idArticulo: "62745e0495c45c183877d5db",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "QUEENS",
        nombre: "QUEENS-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 372,
        valor: 81765.6,
        inventario: 372,
        idArticulo: "62745e0695c45c183877d6fa",
        precio: 345,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2304",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 634,
        valor: 79408.5,
        inventario: 634,
        idArticulo: "62ed73c39416cf0016d491c8",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 478,
        valor: 78965.59999999999,
        inventario: 478,
        idArticulo: "62cee3ccfb6b4e00175aa04e",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 450,
        valor: 78525,
        inventario: 450,
        idArticulo: "62745e0695c45c183877d6d8",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SURF",
        nombre: "SURFPRINT#4",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1335,
        valor: 77149.65,
        inventario: 1335,
        idArticulo: "62ed60a99416cf0016d47c86",
        precio: 85,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "REX",
        nombre: "REX-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 449,
        valor: 77048.4,
        inventario: 449,
        idArticulo: "62745e0695c45c183877d710",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VIENA",
        nombre: "VIENA-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 299,
        valor: 76962.59999999999,
        inventario: 299,
        idArticulo: "62745e0b95c45c183877d8cf",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "SURF",
        nombre: "SURFPRINT#3",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1329,
        valor: 76802.90999999999,
        inventario: 1329,
        idArticulo: "62ed607d9416cf0016d47c02",
        precio: 85,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "INDI",
        nombre: "INDIGIRLS-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 336,
        valor: 75264,
        inventario: 336,
        idArticulo: "62c3483b27a4e10017c93505",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KISS",
        nombre: "KISS-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 646,
        valor: 75162.09999999999,
        inventario: 646,
        idArticulo: "629e9092f4d9d700160d9785",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2302",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 432,
        valor: 75116.16,
        inventario: 432,
        idArticulo: "62c5b0c6883f0f0017d031a7",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JULIETA",
        nombre: "JULIETA-MG2201",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 320,
        valor: 74800,
        inventario: 320,
        idArticulo: "63092967d6368300164ca94b",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CINDY",
        nombre: "CINDYGIRLS-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 305,
        valor: 74725,
        inventario: 305,
        idArticulo: "62c3493227a4e10017c93546",
        precio: 390,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 450,
        valor: 74340.00000000001,
        inventario: 450,
        idArticulo: "62745e0395c45c183877d574",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIK",
        nombre: "NIK-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 719,
        valor: 73445.85,
        inventario: 719,
        idArticulo: "62745e0395c45c183877d5a6",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "IMP",
        nombre: "IMP-968",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 204,
        valor: 73440,
        inventario: 204,
        idArticulo: "636e7b077696f000167f8112",
        precio: 390,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "CINDY",
        nombre: "CINDYGIRLS-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 295,
        valor: 72275,
        inventario: 295,
        idArticulo: "62c3485e27a4e10017c93512",
        precio: 390,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2303",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 576,
        valor: 72144,
        inventario: 576,
        idArticulo: "62ed73979416cf0016d49147",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SURF",
        nombre: "SURFPRINT#13PLUS",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1200,
        valor: 71928,
        inventario: 1200,
        idArticulo: "62ed6da29416cf0016d48a76",
        precio: 94,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "SURF",
        nombre: "SURFPRINT#1PLUS",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1194,
        valor: 71568.36,
        inventario: 1194,
        idArticulo: "62ed62709416cf0016d47e54",
        precio: 94,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "SURF",
        nombre: "SURFPRINT#12PLUS",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1191,
        valor: 71388.54,
        inventario: 1191,
        idArticulo: "62ed62c19416cf0016d47e6e",
        precio: 94,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "SURF",
        nombre: "SURFPRINT#7PLUS",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1181,
        valor: 70789.14,
        inventario: 1181,
        idArticulo: "639364fc6b407500165512aa",
        precio: 94,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "CINDY",
        nombre: "CINDYGIRLS-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 288,
        valor: 70560,
        inventario: 288,
        idArticulo: "62c3493227a4e10017c93546",
        precio: 390,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2205",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 438,
        valor: 70474.2,
        inventario: 438,
        idArticulo: "62745dff95c45c183877d3c2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA568#405",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 304,
        valor: 69920,
        inventario: 304,
        idArticulo: "6308f06eb511770016a3b05a",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZCRG-LR2202",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 529,
        valor: 69828,
        inventario: 529,
        idArticulo: "62ed745f9416cf0016d4935b",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2305",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 837,
        valor: 69705.36,
        inventario: 837,
        idArticulo: "6311296c8c32950016f84bc4",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2306",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 791,
        valor: 69275.78,
        inventario: 791,
        idArticulo: "631129368c32950016f84b5d",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "22#",
        nombre: "22#0975",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 480,
        valor: 69144,
        inventario: 480,
        idArticulo: "634c57648a10be00166f7894",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2302",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 552,
        valor: 69138,
        inventario: 552,
        idArticulo: "62ed736a9416cf0016d490dd",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2301",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 552,
        valor: 69138,
        inventario: 552,
        idArticulo: "62ed72839416cf0016d48ec5",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BARELA",
        nombre: "BARELA-MG2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 319,
        valor: 68808.3,
        inventario: 319,
        idArticulo: "62745df795c45c183877d01b",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2203",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 254,
        valor: 67246.5,
        inventario: 254,
        idArticulo: "62745df595c45c183877cf3d",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SWIM",
        nombre: "SWIM#9PLUS",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1208,
        valor: 67213.12,
        inventario: 1208,
        idArticulo: "62ed64529416cf0016d47f31",
        precio: 90,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "SILVIA",
        nombre: "SILVIA-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 374,
        valor: 67189.1,
        inventario: 374,
        idArticulo: "62745e0895c45c183877d7d0",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZCRG-LR2202",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 504,
        valor: 66528,
        inventario: 504,
        idArticulo: "62ed745f9416cf0016d4935b",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2208",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 391,
        valor: 66157.2,
        inventario: 391,
        idArticulo: "628be7f295692838f4c58b34",
        precio: 250,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2304",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 528,
        valor: 66132,
        inventario: 528,
        idArticulo: "62ed73c39416cf0016d491c8",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SWIM",
        nombre: "SWIM#8PLUS",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1183,
        valor: 65822.12,
        inventario: 1183,
        idArticulo: "62ed64269416cf0016d47efa",
        precio: 90,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2108",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 405,
        valor: 65488.50000000001,
        inventario: 405,
        idArticulo: "62745dfc95c45c183877d2b4",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2204",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 386,
        valor: 65311.20000000001,
        inventario: 386,
        idArticulo: "62745e0295c45c183877d539",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 204,
        valor: 63852,
        inventario: 204,
        idArticulo: "62c3456127a4e10017c933b6",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2306",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 417,
        valor: 62291.46,
        inventario: 417,
        idArticulo: "62ed91e550fb0700166b366f",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "CARRERA",
        nombre: "CARRERA-KV2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 209,
        valor: 62073,
        inventario: 209,
        idArticulo: "62745df995c45c183877d0f5",
        precio: 465,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "MARIO",
        nombre: "MARIO-XT2301",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1133,
        valor: 61816.48,
        inventario: 1133,
        idArticulo: "62ed64ee9416cf0016d47f94",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 319,
        valor: 61407.5,
        inventario: 319,
        idArticulo: "62cef3fdfb6b4e00175ab159",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOMBER",
        nombre: "BOMBERMONTH-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 521,
        valor: 61217.5,
        inventario: 521,
        idArticulo: "62bb76f0ee07390017cd2712",
        precio: 1,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "BEBE",
        idFamilia: "62744a8b0b6417001680035a",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2211",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 300,
        valor: 60900,
        inventario: 300,
        idArticulo: "62745df895c45c183877d073",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MARIO",
        nombre: "MARIO-XT2302",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1092,
        valor: 59579.52,
        inventario: 1092,
        idArticulo: "62ed65119416cf0016d47fa1",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "CCCH",
        nombre: "CCCH-CAMUVEC",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 452,
        valor: 59261.72000000001,
        inventario: 452,
        idArticulo: "62c5d637883f0f0017d072da",
        precio: 177.5,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "CINDY",
        nombre: "CINDYGIRLS-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 240,
        valor: 58800,
        inventario: 240,
        idArticulo: "62c3485e27a4e10017c93512",
        precio: 390,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SURF",
        nombre: "SURFPRINT#6",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 1007,
        valor: 58194.53,
        inventario: 1007,
        idArticulo: "62ed61059416cf0016d47d08",
        precio: 85,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "WARIO",
        nombre: "WARIO-XT2301",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 997,
        valor: 57616.63,
        inventario: 997,
        idArticulo: "62ed65849416cf0016d47fcf",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 300,
        valor: 56880,
        inventario: 300,
        idArticulo: "62745dfa95c45c183877d195",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ANITA",
        nombre: "ANITA-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 298,
        valor: 56769,
        inventario: 298,
        idArticulo: "62745df695c45c183877cff0",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA568#405",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 238,
        valor: 54740,
        inventario: 238,
        idArticulo: "6308f06eb511770016a3b05a",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2303",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 360,
        valor: 54550.8,
        inventario: 360,
        idArticulo: "62ed913550fb0700166b35f4",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2303",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 360,
        valor: 54550.8,
        inventario: 360,
        idArticulo: "62ed913550fb0700166b35f4",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2206",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 338,
        valor: 54384.200000000004,
        inventario: 338,
        idArticulo: "62745dff95c45c183877d3be",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2302",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 312,
        valor: 54250.56,
        inventario: 312,
        idArticulo: "62c5b0c6883f0f0017d031a7",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KISS",
        nombre: "KISS-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 465,
        valor: 54102.75,
        inventario: 465,
        idArticulo: "629e90dbf4d9d700160d97a0",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZCRG-LR2201",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 408,
        valor: 53856,
        inventario: 408,
        idArticulo: "62ed74319416cf0016d492e8",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 347,
        valor: 53854.399999999994,
        inventario: 347,
        idArticulo: "62745dfe95c45c183877d34a",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2304",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 335,
        valor: 53566.5,
        inventario: 335,
        idArticulo: "63503c4ad03b310016e5fa37",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MARIO",
        nombre: "MARIO-XT2303",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 976,
        valor: 53250.560000000005,
        inventario: 976,
        idArticulo: "62ed65369416cf0016d47fb8",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "DL2",
        nombre: "DL26FWMCOT001",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 193,
        valor: 53210.1,
        inventario: 193,
        idArticulo: "62745dfa95c45c183877d185",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 491,
        valor: 52733.4,
        inventario: 491,
        idArticulo: "62745e0395c45c183877d583",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 476,
        valor: 52621.8,
        inventario: 476,
        idArticulo: "62745e0395c45c183877d594",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MOSCU",
        nombre: "MOSCU-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 316,
        valor: 52424.4,
        inventario: 316,
        idArticulo: "62745e0295c45c183877d4f4",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SHAKIRA",
        nombre: "SHAKIRA-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 216,
        valor: 52239.6,
        inventario: 216,
        idArticulo: "62745e0895c45c183877d7ae",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MOSCU",
        nombre: "MOSCU-LW2104",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 311,
        valor: 51594.9,
        inventario: 311,
        idArticulo: "62745e0295c45c183877d4f6",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 269,
        valor: 51002.4,
        inventario: 269,
        idArticulo: "62745dfa95c45c183877d197",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PEACH",
        nombre: "PEACH-MG2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 283,
        valor: 50037.23,
        inventario: 283,
        idArticulo: "62745e0495c45c183877d638",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VIENA",
        nombre: "VIENA-HN2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 194,
        valor: 49935.6,
        inventario: 194,
        idArticulo: "62745e0b95c45c183877d8d1",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "PEACH",
        nombre: "PEACH-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 279,
        valor: 49329.99,
        inventario: 279,
        idArticulo: "62745e0495c45c183877d63a",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KATY",
        nombre: "KATY-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 165,
        valor: 49054.5,
        inventario: 165,
        idArticulo: "62745dfe95c45c183877d368",
        precio: 490,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "DIXIE",
        nombre: "DIXIE-MG2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 286,
        valor: 48948.9,
        inventario: 286,
        idArticulo: "62745dfa95c45c183877d16f",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 254,
        valor: 48895,
        inventario: 254,
        idArticulo: "62745e0295c45c183877d561",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2203",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 286,
        valor: 48391.2,
        inventario: 286,
        idArticulo: "62745e0295c45c183877d53b",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2207",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 180,
        valor: 47655,
        inventario: 180,
        idArticulo: "62745df595c45c183877cf49",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BARELAHOOD",
        nombre: "BARELAHOOD-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 220,
        valor: 47454,
        inventario: 220,
        idArticulo: "62745df795c45c183877d016",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2206",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 179,
        valor: 47390.25,
        inventario: 179,
        idArticulo: "62745df595c45c183877cf47",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2301",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 312,
        valor: 47205.600000000006,
        inventario: 312,
        idArticulo: "63503bb86819510016f651fa",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 285,
        valor: 47082.00000000001,
        inventario: 285,
        idArticulo: "62745e0395c45c183877d574",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 285,
        valor: 47082,
        inventario: 285,
        idArticulo: "62cee3ccfb6b4e00175aa04e",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2301",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 311,
        valor: 47054.3,
        inventario: 311,
        idArticulo: "63503bb86819510016f651fa",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MOSCU",
        nombre: "MOSCU-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 283,
        valor: 46949.700000000004,
        inventario: 283,
        idArticulo: "62745e0295c45c183877d4f1",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2307",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 452,
        valor: 46872.4,
        inventario: 452,
        idArticulo: "631128d68c32950016f84a81",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2212",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 268,
        valor: 46766,
        inventario: 268,
        idArticulo: "62745e0695c45c183877d6ea",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KELLY",
        nombre: "KELLY-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 150,
        valor: 46485,
        inventario: 150,
        idArticulo: "62745dfe95c45c183877d36a",
        precio: 490,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2108",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 286,
        valor: 46246.200000000004,
        inventario: 286,
        idArticulo: "62745dfc95c45c183877d2b4",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2302",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 288,
        valor: 46051.2,
        inventario: 288,
        idArticulo: "63503bed6819510016f65231",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "INDI",
        nombre: "INDIGIRLS-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 203,
        valor: 45472,
        inventario: 203,
        idArticulo: "62c3483b27a4e10017c93505",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 239,
        valor: 45314.4,
        inventario: 239,
        idArticulo: "62745dfa95c45c183877d197",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "QUEENS",
        nombre: "QUEENS-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 204,
        valor: 44839.2,
        inventario: 204,
        idArticulo: "62745e0695c45c183877d6fa",
        precio: 345,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 251,
        valor: 44627.799999999996,
        inventario: 251,
        idArticulo: "62745df895c45c183877d092",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2102",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 450,
        valor: 44415,
        inventario: 450,
        idArticulo: "62745dfd95c45c183877d2d4",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2106",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 442,
        valor: 43625.4,
        inventario: 442,
        idArticulo: "62745dfd95c45c183877d2e0",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 131,
        valor: 43557.5,
        inventario: 131,
        idArticulo: "62745e0795c45c183877d758",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2208",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 164,
        valor: 43419,
        inventario: 164,
        idArticulo: "62745df595c45c183877cf51",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SIENA",
        nombre: "SIENA-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 226,
        valor: 43324.200000000004,
        inventario: 226,
        idArticulo: "62745e0895c45c183877d7c6",
        precio: 305,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "IMP",
        nombre: "IMP-968",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 43200,
        inventario: 120,
        idArticulo: "636e7b077696f000167f8112",
        precio: 390,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 225,
        valor: 42660,
        inventario: 225,
        idArticulo: "62745dfa95c45c183877d195",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 210,
        valor: 42630,
        inventario: 210,
        idArticulo: "62745df795c45c183877d05d",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2204",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 161,
        valor: 42624.75,
        inventario: 161,
        idArticulo: "62745df595c45c183877cf4d",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KENDALL",
        nombre: "KENDALL-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 396,
        valor: 42431.4,
        inventario: 396,
        idArticulo: "62745dfe95c45c183877d36c",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2210",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 209,
        valor: 42427,
        inventario: 209,
        idArticulo: "62745df895c45c183877d06b",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KELLY",
        nombre: "KELLY-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 135,
        valor: 41836.5,
        inventario: 135,
        idArticulo: "62745dfe95c45c183877d36a",
        precio: 490,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2301",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 240,
        valor: 41731.2,
        inventario: 240,
        idArticulo: "62c5b05b883f0f0017d030db",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2303",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 240,
        valor: 41731.2,
        inventario: 240,
        idArticulo: "62c5b102883f0f0017d031f2",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2304",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 240,
        valor: 41731.2,
        inventario: 240,
        idArticulo: "62c5b130883f0f0017d03200",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2125B",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 240,
        valor: 41160,
        inventario: 240,
        idArticulo: "62745e0595c45c183877d6a6",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 141,
        valor: 41031,
        inventario: 141,
        idArticulo: "62745dfc95c45c183877d278",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2305",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 270,
        valor: 40913.1,
        inventario: 270,
        idArticulo: "62ed91c250fb0700166b3662",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2309",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 270,
        valor: 40913.1,
        inventario: 270,
        idArticulo: "62ed926450fb0700166b36e9",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROX",
        nombre: "ROX-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 240,
        valor: 40680,
        inventario: 240,
        idArticulo: "62745e0795c45c183877d77a",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 210,
        valor: 40425,
        inventario: 210,
        idArticulo: "62cee609fb6b4e00175aa0b2",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 210,
        valor: 40425,
        inventario: 210,
        idArticulo: "62cee609fb6b4e00175aa0b2",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2101",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 359,
        valor: 40333.649999999994,
        inventario: 359,
        idArticulo: "628be7f495692838f4c58b9e",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2312",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 270,
        valor: 40332.6,
        inventario: 270,
        idArticulo: "62ed92eb50fb0700166b3766",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "HANS",
        nombre: "HANS-TY2201",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 183,
        valor: 40278.299999999996,
        inventario: 183,
        idArticulo: "62745dfc95c45c183877d242",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SIENA",
        nombre: "SIENA-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 210,
        valor: 40257,
        inventario: 210,
        idArticulo: "62745e0895c45c183877d7ca",
        precio: 305,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2206",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 151,
        valor: 39977.25,
        inventario: 151,
        idArticulo: "62745df595c45c183877cf47",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "108-113",
        nombre: "108-113",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 218,
        valor: 39839.5,
        inventario: 218,
        idArticulo: "631f4fad803c1f0016a31893",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2203",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 150,
        valor: 39712.5,
        inventario: 150,
        idArticulo: "62745df595c45c183877cf3d",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NEMO",
        nombre: "NEMO-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 209,
        valor: 39626.4,
        inventario: 209,
        idArticulo: "62745e0295c45c183877d51a",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2205",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 234,
        valor: 39592.8,
        inventario: 234,
        idArticulo: "62745e0295c45c183877d53d",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2203",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 149,
        valor: 39447.75,
        inventario: 149,
        idArticulo: "62745df595c45c183877cf3d",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2211",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 193,
        valor: 39179,
        inventario: 193,
        idArticulo: "62745df895c45c183877d073",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N013",
        nombre: "6349",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 210,
        valor: 39146.100000000006,
        inventario: 210,
        idArticulo: "62745df395c45c183877ce57",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N116",
        nombre: "CH75005(20A)",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 221,
        valor: 39117,
        inventario: 221,
        idArticulo: "628be7f195692838f4c58ac4",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2020",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ELZAPLUS",
        nombre: "ELZAPLUS-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 356,
        valor: 38875.200000000004,
        inventario: 356,
        idArticulo: "62745dfa95c45c183877d1b1",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KATY",
        nombre: "KATY-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 130,
        valor: 38649,
        inventario: 130,
        idArticulo: "62745dfe95c45c183877d368",
        precio: 490,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "VIENA",
        nombre: "VIENA-HN2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 150,
        valor: 38610,
        inventario: 150,
        idArticulo: "62745e0b95c45c183877d8d1",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "TANIAPLUS",
        nombre: "TANIAPLUS-XT2212",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 150,
        valor: 38610,
        inventario: 150,
        idArticulo: "631f4ca5803c1f0016a3129c",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KENDALL",
        nombre: "KENDALL-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 359,
        valor: 38466.85,
        inventario: 359,
        idArticulo: "62745dfe95c45c183877d376",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2304",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 240,
        valor: 38376,
        inventario: 240,
        idArticulo: "63503c4ad03b310016e5fa37",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2304",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 240,
        valor: 38376,
        inventario: 240,
        idArticulo: "63503c4ad03b310016e5fa37",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2302",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 239,
        valor: 38216.1,
        inventario: 239,
        idArticulo: "63503bed6819510016f65231",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "STEFY",
        nombre: "STEFY-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 313,
        valor: 38123.4,
        inventario: 313,
        idArticulo: "62745e0995c45c183877d7fd",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PACOPLUS",
        nombre: "PACOPLUS-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 154,
        valor: 37976.4,
        inventario: 154,
        idArticulo: "62745e0495c45c183877d616",
        precio: 365,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROX",
        nombre: "ROX-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 224,
        valor: 37968,
        inventario: 224,
        idArticulo: "628be7f495692838f4c58bb6",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PETER",
        nombre: "PETER-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 165,
        valor: 37702.5,
        inventario: 165,
        idArticulo: "62745e0595c45c183877d66e",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 113,
        valor: 37572.5,
        inventario: 113,
        idArticulo: "62745e0795c45c183877d758",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 37560,
        inventario: 120,
        idArticulo: "62c3424127a4e10017c93084",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JULIETA",
        nombre: "JULIETA-MG2201",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 160,
        valor: 37400,
        inventario: 160,
        idArticulo: "63092967d6368300164ca94b",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SILVIA",
        nombre: "SILVIA-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 207,
        valor: 37187.55,
        inventario: 207,
        idArticulo: "62745e0895c45c183877d7d0",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIETA",
        nombre: "JULIETA-MG2201",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 159,
        valor: 37166.25,
        inventario: 159,
        idArticulo: "63092967d6368300164ca94b",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CLOE",
        nombre: "CLOE-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 220,
        valor: 36916.00000000001,
        inventario: 220,
        idArticulo: "62745df995c45c183877d127",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2305",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 240,
        valor: 36828,
        inventario: 240,
        idArticulo: "63503c8dd03b310016e5fa3e",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "VERSALLESHOOD",
        nombre: "VERSALLESHOOD-LW2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 239,
        valor: 36806,
        inventario: 239,
        idArticulo: "62745e0a95c45c183877d8a9",
        precio: 135,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA568#404",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 160,
        valor: 36800,
        inventario: 160,
        idArticulo: "6308f04ab511770016a3b02c",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2210",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 292,
        valor: 36500,
        inventario: 292,
        idArticulo: "62745e0295c45c183877d515",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 205,
        valor: 36449,
        inventario: 205,
        idArticulo: "62745df895c45c183877d087",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TANIA",
        nombre: "TANIA-TY2204",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 156,
        valor: 36426,
        inventario: 156,
        idArticulo: "62745e0995c45c183877d80f",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2309",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 240,
        valor: 36367.2,
        inventario: 240,
        idArticulo: "62ed926450fb0700166b36e9",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2303",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 240,
        valor: 36312,
        inventario: 240,
        idArticulo: "63503c1fd03b310016e5f9e9",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2303",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 240,
        valor: 36312,
        inventario: 240,
        idArticulo: "63503c1fd03b310016e5f9e9",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2306",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 240,
        valor: 36312,
        inventario: 240,
        idArticulo: "63503cb66819510016f65316",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 116,
        valor: 36308,
        inventario: 116,
        idArticulo: "62745dfc95c45c183877d290",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "WARIO",
        nombre: "WARIO-XT2301",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 624,
        valor: 36060.96,
        inventario: 624,
        idArticulo: "62ed65849416cf0016d47fcf",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2304",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 287,
        valor: 35946.75,
        inventario: 287,
        idArticulo: "62ed73c39416cf0016d491c8",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 108,
        valor: 35910,
        inventario: 108,
        idArticulo: "62745e0795c45c183877d74d",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2312",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 240,
        valor: 35851.2,
        inventario: 240,
        idArticulo: "62ed92eb50fb0700166b3766",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OLIVIA",
        nombre: "OLIVIA-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 195,
        valor: 35626.5,
        inventario: 195,
        idArticulo: "62745e0395c45c183877d5ba",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2202",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 210,
        valor: 35532,
        inventario: 210,
        idArticulo: "62745e0295c45c183877d537",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "CINDY",
        nombre: "CINDYGIRLS-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 144,
        valor: 35280,
        inventario: 144,
        idArticulo: "62c3493227a4e10017c93546",
        precio: 390,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 34920,
        inventario: 120,
        idArticulo: "62745dfc95c45c183877d25e",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 34920,
        inventario: 120,
        idArticulo: "62745dfc95c45c183877d278",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "SHELLY",
        nombre: "SHELLY-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 150,
        valor: 34860,
        inventario: 150,
        idArticulo: "62745e0895c45c183877d7b2",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SHAKIRA",
        nombre: "SHAKIRA-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 144,
        valor: 34826.4,
        inventario: 144,
        idArticulo: "634c4adad74f160016d19f48",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROX",
        nombre: "ROX-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 205,
        valor: 34747.5,
        inventario: 205,
        idArticulo: "628be7f495692838f4c58bb6",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SHAKIRA",
        nombre: "SHAKIRA-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 143,
        valor: 34584.549999999996,
        inventario: 143,
        idArticulo: "634c4adad74f160016d19f48",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PACOPLUS",
        nombre: "PACOPLUS-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 140,
        valor: 34524,
        inventario: 140,
        idArticulo: "62745e0495c45c183877d616",
        precio: 365,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "VIENA",
        nombre: "VIENA-HN2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 134,
        valor: 34491.6,
        inventario: 134,
        idArticulo: "62745e0b95c45c183877d8d1",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "ELZAPLUS",
        nombre: "ELZAPLUS-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 315,
        valor: 34398,
        inventario: 315,
        idArticulo: "62745dfa95c45c183877d1a5",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PACO",
        nombre: "PACO-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 152,
        valor: 34291.2,
        inventario: 152,
        idArticulo: "62745e0495c45c183877d611",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2303",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 226,
        valor: 34245.78000000003,
        inventario: 226,
        idArticulo: "62ed913550fb0700166b35f4",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SIENAHOOD",
        nombre: "SIENAHOOD-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 165,
        valor: 34056,
        inventario: 165,
        idArticulo: "62745e0895c45c183877d7ba",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "0024",
        nombre: "0024-2-2",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 190,
        valor: 33905.5,
        inventario: 190,
        idArticulo: "634c556a8a10be00166f7855",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 306,
        valor: 33828.299999999996,
        inventario: 306,
        idArticulo: "62745e0395c45c183877d598",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-HN2211",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 199,
        valor: 33710.6,
        inventario: 199,
        idArticulo: "63226d88283a510016948a83",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 312,
        valor: 33508.8,
        inventario: 312,
        idArticulo: "62745e0395c45c183877d57f",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2211",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 164,
        valor: 33292,
        inventario: 164,
        idArticulo: "62745df895c45c183877d073",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "WARIO",
        nombre: "WARIO-XT2303",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 576,
        valor: 33287.04,
        inventario: 576,
        idArticulo: "62ed65cc9416cf0016d4804e",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "108-113",
        nombre: "108-113",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 182,
        valor: 33260.5,
        inventario: 182,
        idArticulo: "631f4fad803c1f0016a31893",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KILE",
        nombre: "KILE-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 180,
        valor: 33183,
        inventario: 180,
        idArticulo: "62a7bd6353cecd0016f99902",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2301",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 263,
        valor: 32940.75,
        inventario: 263,
        idArticulo: "62ed72839416cf0016d48ec5",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ALDOBOY",
        nombre: "ALDOBOY-BL2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 180,
        valor: 32904,
        inventario: 180,
        idArticulo: "62745df595c45c183877cf7f",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2204",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 124,
        valor: 32829,
        inventario: 124,
        idArticulo: "62745df595c45c183877cf4d",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "LOLAHOOD",
        nombre: "LOLAHOOD-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 164,
        valor: 32734.4,
        inventario: 164,
        idArticulo: "62745e0195c45c183877d485",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2306",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 216,
        valor: 32680.800000000003,
        inventario: 216,
        idArticulo: "63503cb66819510016f65316",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MONACO",
        nombre: "MONACO-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 255,
        valor: 32665.5,
        inventario: 255,
        idArticulo: "62745e0195c45c183877d4e2",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2106",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 289,
        valor: 32469.149999999998,
        inventario: 289,
        idArticulo: "62745df895c45c183877d0a1",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "CATAR",
        nombre: "CATAR-LW2104",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 255,
        valor: 32397.75,
        inventario: 255,
        idArticulo: "62745df995c45c183877d117",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SILVIA",
        nombre: "SILVIA-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 180,
        valor: 32337,
        inventario: 180,
        idArticulo: "62745e0895c45c183877d7d0",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MARIO",
        nombre: "MARIO-XT2303",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 592,
        valor: 32299.52,
        inventario: 592,
        idArticulo: "62ed65369416cf0016d47fb8",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KILA",
        nombre: "KILA-HN2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 180,
        valor: 32238,
        inventario: 180,
        idArticulo: "62745dff95c45c183877d3de",
        precio: 235,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "VERSALLES",
        nombre: "VERSALLES-LW2203",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 240,
        valor: 32207.999999999996,
        inventario: 240,
        idArticulo: "62745e0b95c45c183877d8af",
        precio: 125,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2210",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 180,
        valor: 32003.999999999996,
        inventario: 180,
        idArticulo: "62745df895c45c183877d094",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PARKER",
        nombre: "PARKER-XT2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 190,
        valor: 31920,
        inventario: 190,
        idArticulo: "62745e0495c45c183877d626",
        precio: 375,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZCRG-LR2201",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 240,
        valor: 31680,
        inventario: 240,
        idArticulo: "62ed74319416cf0016d492e8",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 95,
        valor: 31587.5,
        inventario: 95,
        idArticulo: "62745e0795c45c183877d75a",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "PARIS",
        nombre: "PARIS-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 178,
        valor: 31417,
        inventario: 178,
        idArticulo: "62745e0495c45c183877d61b",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2306",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 210,
        valor: 31369.8,
        inventario: 210,
        idArticulo: "62ed91e550fb0700166b366f",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 165,
        valor: 31284,
        inventario: 165,
        idArticulo: "62745dfa95c45c183877d19f",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PEACH",
        nombre: "PEACH-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 176,
        valor: 31118.56,
        inventario: 176,
        idArticulo: "62745e0495c45c183877d63a",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 165,
        valor: 31067.85,
        inventario: 165,
        idArticulo: "62745e0395c45c183877d5c7",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "SIENAHOOD",
        nombre: "SIENAHOOD-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 150,
        valor: 30960,
        inventario: 150,
        idArticulo: "62745e0895c45c183877d7c0",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LIAM",
        nombre: "LIAM-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 143,
        valor: 30873.7,
        inventario: 143,
        idArticulo: "62745e0095c45c183877d472",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "VERSALLESPLUS",
        nombre: "VERSALLESPLUS-LW2205",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 206,
        valor: 30858.800000000003,
        inventario: 206,
        idArticulo: "62745e0b95c45c183877d8cb",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 160,
        valor: 30800,
        inventario: 160,
        idArticulo: "62cef3fdfb6b4e00175ab159",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2208",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 182,
        valor: 30794.4,
        inventario: 182,
        idArticulo: "628be7f295692838f4c58b34",
        precio: 250,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2207",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 198,
        valor: 30729.6,
        inventario: 198,
        idArticulo: "62745dfe95c45c183877d358",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2205",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 181,
        valor: 30625.200000000004,
        inventario: 181,
        idArticulo: "62745e0295c45c183877d53d",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 159,
        valor: 30607.5,
        inventario: 159,
        idArticulo: "62745e0295c45c183877d55f",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 30555,
        inventario: 105,
        idArticulo: "62745dfc95c45c183877d266",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 30555,
        inventario: 105,
        idArticulo: "62745dfc95c45c183877d278",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 30555,
        inventario: 105,
        idArticulo: "62745dfc95c45c183877d278",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "NIKA",
        nombre: "NIKA-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 322,
        valor: 30525.6,
        inventario: 322,
        idArticulo: "62745e0395c45c183877d588",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 104,
        valor: 30264,
        inventario: 104,
        idArticulo: "62745dfc95c45c183877d25e",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 104,
        valor: 30264,
        inventario: 104,
        idArticulo: "62745dfc95c45c183877d268",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "CLOE",
        nombre: "CLOE-LE2204",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 180,
        valor: 30204.000000000004,
        inventario: 180,
        idArticulo: "62745df995c45c183877d129",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JJ20",
        nombre: "JJ20228",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 96,
        valor: 30048,
        inventario: 96,
        idArticulo: "6296628a1eac77001641dd93",
        precio: 480,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LIAM",
        nombre: "LIAM-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 139,
        valor: 30010.100000000002,
        inventario: 139,
        idArticulo: "62745e0095c45c183877d470",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2303",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 239,
        valor: 29934.75,
        inventario: 239,
        idArticulo: "62ed73979416cf0016d49147",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SILVIA",
        nombre: "SILVIA-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 165,
        valor: 29642.25,
        inventario: 165,
        idArticulo: "62745e0895c45c183877d7d0",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "LEE",
        nombre: "LEE-LE2103",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 279,
        valor: 29587.95,
        inventario: 279,
        idArticulo: "62745e0095c45c183877d44c",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2105",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 247,
        valor: 29565.9,
        inventario: 247,
        idArticulo: "62745dfd95c45c183877d30a",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 165,
        valor: 29336.999999999996,
        inventario: 165,
        idArticulo: "62745df895c45c183877d087",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 165,
        valor: 29336.999999999996,
        inventario: 165,
        idArticulo: "62745df895c45c183877d087",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2305",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 191,
        valor: 29308.949999999997,
        inventario: 191,
        idArticulo: "63503c8dd03b310016e5fa3e",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 88,
        valor: 29260,
        inventario: 88,
        idArticulo: "62745e0795c45c183877d75a",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2210",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 164,
        valor: 29159.199999999997,
        inventario: 164,
        idArticulo: "62745df895c45c183877d094",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OTIS",
        nombre: "OTIS-MG2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 160,
        valor: 29136,
        inventario: 160,
        idArticulo: "62745e0495c45c183877d5f2",
        precio: 265,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERSALLESPLUS",
        nombre: "VERSALLESPLUS-LW2204",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 194,
        valor: 29061.2,
        inventario: 194,
        idArticulo: "62745e0b95c45c183877d8c3",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CRCM",
        nombre: "CRCM-103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 192,
        valor: 28800,
        inventario: 192,
        idArticulo: "62745df995c45c183877d14b",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MONIQUE",
        nombre: "MONIQUE-MG2002",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 191,
        valor: 28764.6,
        inventario: 191,
        idArticulo: "62745e0295c45c183877d4ef",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SIENA",
        nombre: "SIENA-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 150,
        valor: 28755.000000000004,
        inventario: 150,
        idArticulo: "62745e0895c45c183877d7c6",
        precio: 305,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2304",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 180,
        valor: 28436.4,
        inventario: 180,
        idArticulo: "62ed917050fb0700166b3624",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2304",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 180,
        valor: 28436.4,
        inventario: 180,
        idArticulo: "62ed917050fb0700166b3624",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 150,
        valor: 28243.5,
        inventario: 150,
        idArticulo: "62745e0395c45c183877d5cc",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 150,
        valor: 28243.5,
        inventario: 150,
        idArticulo: "62745e0395c45c183877d5d0",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BRADYBOY",
        nombre: "BRADYBOY-XT2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 215,
        valor: 28218.75,
        inventario: 215,
        idArticulo: "62745df895c45c183877d0b3",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 139,
        valor: 28217,
        inventario: 139,
        idArticulo: "62745df795c45c183877d061",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 146,
        valor: 28105,
        inventario: 146,
        idArticulo: "62cef3fdfb6b4e00175ab159",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 180,
        valor: 27935.999999999996,
        inventario: 180,
        idArticulo: "62745dfe95c45c183877d346",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 84,
        valor: 27930,
        inventario: 84,
        idArticulo: "62745e0795c45c183877d758",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "N125",
        nombre: "SW-V3",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 186,
        valor: 27900,
        inventario: 186,
        idArticulo: "628be7f295692838f4c58ae2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SHELLY",
        nombre: "SHELLY-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 27888,
        inventario: 120,
        idArticulo: "62745e0895c45c183877d7b2",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BIKERHOOD",
        nombre: "BIKERHOOD-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 128,
        valor: 27865.6,
        inventario: 128,
        idArticulo: "62745df795c45c183877d02d",
        precio: 335,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ERVIN",
        nombre: "ERVIN-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 128,
        valor: 27865.6,
        inventario: 128,
        idArticulo: "62745dfa95c45c183877d1c3",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2206",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 105,
        valor: 27798.75,
        inventario: 105,
        idArticulo: "62745df595c45c183877cf47",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2207",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 105,
        valor: 27798.75,
        inventario: 105,
        idArticulo: "62745df595c45c183877cf49",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KITY",
        nombre: "KITY-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 164,
        valor: 27798,
        inventario: 164,
        idArticulo: "62745dff95c45c183877d3ff",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ERVIN",
        nombre: "ERVIN-MG2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 127,
        valor: 27647.899999999998,
        inventario: 127,
        idArticulo: "62745dfa95c45c183877d1c5",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 83,
        valor: 27597.5,
        inventario: 83,
        idArticulo: "62745e0795c45c183877d758",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "PEACH",
        nombre: "PEACH-MG2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 156,
        valor: 27582.36,
        inventario: 156,
        idArticulo: "62745e0495c45c183877d638",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PEACH",
        nombre: "PEACH-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 156,
        valor: 27582.36,
        inventario: 156,
        idArticulo: "62745e0495c45c183877d63a",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 135,
        valor: 27405,
        inventario: 135,
        idArticulo: "62745df795c45c183877d05d",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2303",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 180,
        valor: 27275.4,
        inventario: 180,
        idArticulo: "62ed913550fb0700166b35f4",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 165,
        valor: 27258.000000000004,
        inventario: 165,
        idArticulo: "62745e0395c45c183877d570",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DIXIE",
        nombre: "DIXIE-MG2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 159,
        valor: 27212.85,
        inventario: 159,
        idArticulo: "62745dfa95c45c183877d171",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "FIJI",
        nombre: "FIJI-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 190,
        valor: 27131.999999999996,
        inventario: 190,
        idArticulo: "62745dfb95c45c183877d1df",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2304",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 216,
        valor: 27054,
        inventario: 216,
        idArticulo: "62ed73c39416cf0016d491c8",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2302",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 215,
        valor: 26928.75,
        inventario: 215,
        idArticulo: "62ed736a9416cf0016d490dd",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2302",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 256,
        valor: 26547.2,
        inventario: 256,
        idArticulo: "631128698c32950016f8498d",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KILE",
        nombre: "KILE-LE2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 144,
        valor: 26546.4,
        inventario: 144,
        idArticulo: "628be7f495692838f4c58bb2",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KILE",
        nombre: "KILE-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 144,
        valor: 26546.4,
        inventario: 144,
        idArticulo: "62a7bd6353cecd0016f99902",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2211",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 212,
        valor: 26500,
        inventario: 212,
        idArticulo: "62745e0295c45c183877d51c",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2208",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 156,
        valor: 26395.199999999997,
        inventario: 156,
        idArticulo: "628be7f295692838f4c58b34",
        precio: 250,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 84,
        valor: 26292,
        inventario: 84,
        idArticulo: "62c3453d27a4e10017c9338e",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2102",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 184,
        valor: 26275.199999999997,
        inventario: 184,
        idArticulo: "62745e0595c45c183877d683",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2206",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 155,
        valor: 26226.000000000004,
        inventario: 155,
        idArticulo: "62745e0295c45c183877d53f",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2205",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 155,
        valor: 26226.000000000004,
        inventario: 155,
        idArticulo: "62745e0295c45c183877d53d",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ELZAPLUS",
        nombre: "ELZAPLUS-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 240,
        valor: 26208,
        inventario: 240,
        idArticulo: "62745dfa95c45c183877d1b1",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 26190,
        inventario: 90,
        idArticulo: "62745dfc95c45c183877d25e",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "REX",
        nombre: "REX-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 152,
        valor: 26083.199999999997,
        inventario: 152,
        idArticulo: "62745e0695c45c183877d712",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2304",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 165,
        valor: 26066.699999999997,
        inventario: 165,
        idArticulo: "62ed917050fb0700166b3624",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2206",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 154,
        valor: 26056.800000000003,
        inventario: 154,
        idArticulo: "62745e0295c45c183877d53f",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BARELA",
        nombre: "BARELA-MG2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 25884,
        inventario: 120,
        idArticulo: "62745df795c45c183877d01b",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BARELAHOOD",
        nombre: "BARELAHOOD-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 25884,
        inventario: 120,
        idArticulo: "62745df795c45c183877d016",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "REX",
        nombre: "REX-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 150,
        valor: 25740,
        inventario: 150,
        idArticulo: "62745e0695c45c183877d710",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 88,
        valor: 25608,
        inventario: 88,
        idArticulo: "62745dfc95c45c183877d26c",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "LOLA",
        nombre: "LOLA-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 135,
        valor: 25528.5,
        inventario: 135,
        idArticulo: "62745e0195c45c183877d489",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "BARELA",
        nombre: "BARELA-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 118,
        valor: 25452.6,
        inventario: 118,
        idArticulo: "62745df795c45c183877d019",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROX",
        nombre: "ROX-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 150,
        valor: 25425,
        inventario: 150,
        idArticulo: "628be7f495692838f4c58bb6",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2301",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 168,
        valor: 25418.4,
        inventario: 168,
        idArticulo: "63503bb86819510016f651fa",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ZGL",
        nombre: "ZGL-LE2101",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 247,
        valor: 25416.300000000003,
        inventario: 247,
        idArticulo: "62745e0b95c45c183877d8e3",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 134,
        valor: 25230.86,
        inventario: 134,
        idArticulo: "62745e0395c45c183877d5cc",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2202",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 95,
        valor: 25151.25,
        inventario: 95,
        idArticulo: "62745df595c45c183877cf3f",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2207",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 162,
        valor: 25142.4,
        inventario: 162,
        idArticulo: "62745dfe95c45c183877d358",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2203",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 201,
        valor: 25125,
        inventario: 201,
        idArticulo: "62745e0295c45c183877d505",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA568#404",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 109,
        valor: 25070,
        inventario: 109,
        idArticulo: "6308f04ab511770016a3b02c",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2305",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 165,
        valor: 25002.45,
        inventario: 165,
        idArticulo: "62ed91c250fb0700166b3662",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KILA",
        nombre: "KILA-HN2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 139,
        valor: 24894.899999999998,
        inventario: 139,
        idArticulo: "62745dff95c45c183877d3de",
        precio: 235,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2210",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 140,
        valor: 24891.999999999996,
        inventario: 140,
        idArticulo: "62745df895c45c183877d094",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2111",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 174,
        valor: 24847.199999999997,
        inventario: 174,
        idArticulo: "62745e0595c45c183877d69f",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "CINDY",
        nombre: "CINDY-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 72,
        valor: 24840,
        inventario: 72,
        idArticulo: "62c3429a27a4e10017c9310d",
        precio: 535,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "CLOE",
        nombre: "CLOE-LE2204",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 148,
        valor: 24834.4,
        inventario: 148,
        idArticulo: "62745df995c45c183877d129",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 150,
        valor: 24780.000000000004,
        inventario: 150,
        idArticulo: "62745e0395c45c183877d574",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 150,
        valor: 24780,
        inventario: 150,
        idArticulo: "62cee5b0fb6b4e00175aa0a5",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIKI",
        nombre: "KIKI-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 144,
        valor: 24732,
        inventario: 144,
        idArticulo: "62745dff95c45c183877d3d9",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ALDOBOY",
        nombre: "ALDOBOY-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 135,
        valor: 24677.999999999996,
        inventario: 135,
        idArticulo: "62745df695c45c183877cf86",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "OLIVIA",
        nombre: "OLIVIA-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 135,
        valor: 24664.5,
        inventario: 135,
        idArticulo: "62745e0395c45c183877d5ba",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2302",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 165,
        valor: 24647.7,
        inventario: 165,
        idArticulo: "62ed90f750fb0700166b35d0",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "099V",
        nombre: "099V#502",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3854,
        valor: 24588.52,
        inventario: 3854,
        idArticulo: "6340630fd4fdc60016dec915",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ELZAPLUS",
        nombre: "ELZAPLUS-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 225,
        valor: 24570,
        inventario: 225,
        idArticulo: "62745dfa95c45c183877d1a5",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERSALLESPLUS",
        nombre: "VERSALLESPLUS-LW2205",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 164,
        valor: 24567.2,
        inventario: 164,
        idArticulo: "62745e0b95c45c183877d8cb",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIKI",
        nombre: "KIKI-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 143,
        valor: 24560.25,
        inventario: 143,
        idArticulo: "62745dff95c45c183877d3d9",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OSLO",
        nombre: "OSLO-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 165,
        valor: 24464.549999999996,
        inventario: 165,
        idArticulo: "62745e0495c45c183877d5eb",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ERVIN",
        nombre: "ERVIN-MG2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 111,
        valor: 24164.699999999997,
        inventario: 111,
        idArticulo: "62745dfa95c45c183877d1c5",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 146,
        valor: 24119.2,
        inventario: 146,
        idArticulo: "62745e0395c45c183877d574",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "099V",
        nombre: "099V#501",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3774,
        valor: 24078.12,
        inventario: 3774,
        idArticulo: "634062ecd4fdc60016dec8ec",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "HELLO",
        nombre: "HELLO-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 178,
        valor: 24074.5,
        inventario: 178,
        idArticulo: "62745dfc95c45c183877d25a",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2205",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 142,
        valor: 24026.4,
        inventario: 142,
        idArticulo: "62745e0295c45c183877d53d",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "VERSALLES",
        nombre: "VERSALLES-LW2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 179,
        valor: 24021.8,
        inventario: 179,
        idArticulo: "62745e0a95c45c183877d8ab",
        precio: 125,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 135,
        valor: 24002.999999999996,
        inventario: 135,
        idArticulo: "62745df895c45c183877d092",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LOLAHOOD",
        nombre: "LOLAHOOD-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 23952,
        inventario: 120,
        idArticulo: "62745e0195c45c183877d483",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 136,
        valor: 23732,
        inventario: 136,
        idArticulo: "62745e0695c45c183877d6ca",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MARIO",
        nombre: "MARIO-XT2302",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 434,
        valor: 23679.04,
        inventario: 434,
        idArticulo: "62ed65119416cf0016d47fa1",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "DONNA",
        nombre: "DONNA-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 109,
        valor: 23674.8,
        inventario: 109,
        idArticulo: "62745dfa95c45c183877d18d",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 133,
        valor: 23647.399999999998,
        inventario: 133,
        idArticulo: "62745df895c45c183877d08f",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TABO",
        nombre: "TABO-XT2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 108,
        valor: 23630.4,
        inventario: 108,
        idArticulo: "628be7f495692838f4c58ba0",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2205",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 89,
        valor: 23562.75,
        inventario: 89,
        idArticulo: "62745df595c45c183877cf4b",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RS0",
        nombre: "RS-004",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 72,
        valor: 23535.36,
        inventario: 72,
        idArticulo: "62c32ee527a4e10017c911b0",
        precio: 450,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TANIA",
        nombre: "TANIA-TY2202",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 100,
        valor: 23350,
        inventario: 100,
        idArticulo: "62745e0995c45c183877d80d",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2205",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 88,
        valor: 23298,
        inventario: 88,
        idArticulo: "62745df595c45c183877cf4b",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "FELIX",
        nombre: "FELIX-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 104,
        valor: 23270,
        inventario: 104,
        idArticulo: "62745dfb95c45c183877d1d5",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KELLY",
        nombre: "KELLY-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 75,
        valor: 23242.5,
        inventario: 75,
        idArticulo: "62745dfe95c45c183877d36a",
        precio: 490,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2204",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 144,
        valor: 23169.6,
        inventario: 144,
        idArticulo: "62745dfe95c45c183877d390",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "VIENA",
        nombre: "VIENA-HN2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 23165.999999999996,
        inventario: 90,
        idArticulo: "62745e0b95c45c183877d8d3",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "VIENA",
        nombre: "VIENA-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 23165.999999999996,
        inventario: 90,
        idArticulo: "62745e0b95c45c183877d8cf",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 122,
        valor: 23131.2,
        inventario: 122,
        idArticulo: "62745dfa95c45c183877d197",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OTIS",
        nombre: "OTIS-MG2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 127,
        valor: 23126.7,
        inventario: 127,
        idArticulo: "62745e0495c45c183877d5f2",
        precio: 265,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SIENA",
        nombre: "SIENA-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 23004.000000000004,
        inventario: 120,
        idArticulo: "62745e0895c45c183877d7ca",
        precio: 305,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "REX",
        nombre: "REX-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 134,
        valor: 22994.4,
        inventario: 134,
        idArticulo: "62745e0695c45c183877d710",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ELZAPLUS",
        nombre: "ELZAPLUS-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 210,
        valor: 22932,
        inventario: 210,
        idArticulo: "62745dfa95c45c183877d1a5",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 119,
        valor: 22907.5,
        inventario: 119,
        idArticulo: "62745e0295c45c183877d563",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEMO",
        nombre: "NEMO-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 22752,
        inventario: 120,
        idArticulo: "62745e0295c45c183877d51e",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N043",
        nombre: "19005",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 69,
        valor: 22743.09,
        inventario: 69,
        idArticulo: "62745df495c45c183877cec3",
        precio: 380,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2310",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 150,
        valor: 22729.5,
        inventario: 150,
        idArticulo: "62ed92ad50fb0700166b3733",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2310",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 150,
        valor: 22729.5,
        inventario: 150,
        idArticulo: "62ed92ad50fb0700166b3733",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2308",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 150,
        valor: 22569,
        inventario: 150,
        idArticulo: "62ed923750fb0700166b36dc",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2104",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 228,
        valor: 22503.6,
        inventario: 228,
        idArticulo: "62745dfd95c45c183877d2d6",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2313",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 150,
        valor: 22407,
        inventario: 150,
        idArticulo: "62ed933450fb0700166b37c2",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KOTY",
        nombre: "KOTY-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 132,
        valor: 22374,
        inventario: 132,
        idArticulo: "62745dff95c45c183877d3fd",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2208",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 144,
        valor: 22348.8,
        inventario: 144,
        idArticulo: "62745dfe95c45c183877d35a",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NONA",
        nombre: "NONA-MG2301",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 72,
        valor: 22284,
        inventario: 72,
        idArticulo: "638fc13a6236bf001662289c",
        precio: 420,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NONA",
        nombre: "NONA-MG2302",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 72,
        valor: 22284,
        inventario: 72,
        idArticulo: "638fc1654f9d8300162cde8c",
        precio: 420,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NONA",
        nombre: "NONA-MG2301",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 72,
        valor: 22284,
        inventario: 72,
        idArticulo: "638fc13a6236bf001662289c",
        precio: 420,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "WARIO",
        nombre: "WARIO-XT2301",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 385,
        valor: 22249.15,
        inventario: 385,
        idArticulo: "62ed65849416cf0016d47fcf",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "WARIO",
        nombre: "WARIO-XT2302",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 384,
        valor: 22191.36,
        inventario: 384,
        idArticulo: "62ed65ac9416cf0016d4800c",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "TANIA",
        nombre: "TANIA-TY2203",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 95,
        valor: 22182.5,
        inventario: 95,
        idArticulo: "62745e0995c45c183877d815",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 134,
        valor: 22136.8,
        inventario: 134,
        idArticulo: "62cee5b0fb6b4e00175aa0a5",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "WARIO",
        nombre: "WARIO-XT2302",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 383,
        valor: 22133.57,
        inventario: 383,
        idArticulo: "62ed65ac9416cf0016d4800c",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "PEACH",
        nombre: "PEACH-MG2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 125,
        valor: 22101.25,
        inventario: 125,
        idArticulo: "62745e0495c45c183877d638",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA568#401",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 96,
        valor: 22080,
        inventario: 96,
        idArticulo: "6308f072b511770016a3b078",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA568#401",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 96,
        valor: 22080,
        inventario: 96,
        idArticulo: "6308f072b511770016a3b078",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2206",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 130,
        valor: 21996.000000000004,
        inventario: 130,
        idArticulo: "62745e0295c45c183877d53f",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 132,
        valor: 21978,
        inventario: 132,
        idArticulo: "62745e0495c45c183877d604",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "HELLO",
        nombre: "HELLO-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 162,
        valor: 21910.5,
        inventario: 162,
        idArticulo: "62745dfc95c45c183877d25a",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 129,
        valor: 21852.600000000002,
        inventario: 129,
        idArticulo: "62745e0a95c45c183877d861",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 75,
        valor: 21825,
        inventario: 75,
        idArticulo: "62745dfc95c45c183877d26c",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 75,
        valor: 21825,
        inventario: 75,
        idArticulo: "62745dfc95c45c183877d266",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2212",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 125,
        valor: 21812.5,
        inventario: 125,
        idArticulo: "62745e0695c45c183877d6ea",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2303",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 210,
        valor: 21777,
        inventario: 210,
        idArticulo: "631128a68c32950016f84a4a",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SIENAHOOD",
        nombre: "SIENAHOOD-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 21672,
        inventario: 105,
        idArticulo: "62745e0895c45c183877d7c0",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BARELA",
        nombre: "BARELA-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 99,
        valor: 21354.3,
        inventario: 99,
        idArticulo: "62745df795c45c183877d019",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2304",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 135,
        valor: 21327.3,
        inventario: 135,
        idArticulo: "62ed917050fb0700166b3624",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2311",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 135,
        valor: 21327.3,
        inventario: 135,
        idArticulo: "62ed92d050fb0700166b3740",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2210",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 21315,
        inventario: 105,
        idArticulo: "62745df895c45c183877d06b",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "CLOE",
        nombre: "CLOE-LE2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 126,
        valor: 21142.800000000003,
        inventario: 126,
        idArticulo: "62745df995c45c183877d125",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "QUEENS",
        nombre: "QUEENS-LR2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 96,
        valor: 21100.800000000003,
        inventario: 96,
        idArticulo: "62745e0695c45c183877d6fc",
        precio: 345,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BRENDA",
        nombre: "BRENDA-ET2101",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 118,
        valor: 21063,
        inventario: 118,
        idArticulo: "62745df895c45c183877d0bf",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "PEACHHOOD",
        nombre: "PEACHHOOD-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 112,
        valor: 21062.72,
        inventario: 112,
        idArticulo: "62745e0495c45c183877d640",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2301",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 168,
        valor: 21042,
        inventario: 168,
        idArticulo: "62ed72839416cf0016d48ec5",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "RENO",
        nombre: "RENO-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 21015.6,
        inventario: 120,
        idArticulo: "62745e0695c45c183877d702",
        precio: 255,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BIKERHOOD",
        nombre: "BIKERHOOD-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 96,
        valor: 20899.199999999997,
        inventario: 96,
        idArticulo: "62745df795c45c183877d02d",
        precio: 335,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KILE",
        nombre: "KILE-LE2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 113,
        valor: 20831.55,
        inventario: 113,
        idArticulo: "628be7f495692838f4c58bb2",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "VERSALLES",
        nombre: "VERSALLES-LW2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 155,
        valor: 20801,
        inventario: 155,
        idArticulo: "62745e0a95c45c183877d8ab",
        precio: 125,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2205",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 192,
        valor: 20620.800000000003,
        inventario: 192,
        idArticulo: "62745e0395c45c183877d581",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N09",
        nombre: "6220",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 106,
        valor: 20587.32,
        inventario: 106,
        idArticulo: "62745df395c45c183877ce85",
        precio: 245,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "PARKER",
        nombre: "PARKER-XT2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 122,
        valor: 20496,
        inventario: 122,
        idArticulo: "62745e0495c45c183877d62e",
        precio: 375,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ALDO",
        nombre: "ALDO-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 96,
        valor: 20400,
        inventario: 96,
        idArticulo: "62745df595c45c183877cf7d",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "OTIS",
        nombre: "OTIS-MG2205",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 112,
        valor: 20395.2,
        inventario: 112,
        idArticulo: "62745e0495c45c183877d5ff",
        precio: 265,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2206",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 126,
        valor: 20273.4,
        inventario: 126,
        idArticulo: "62745dff95c45c183877d398",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2205",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 126,
        valor: 20273.4,
        inventario: 126,
        idArticulo: "62745dff95c45c183877d3c2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KITY",
        nombre: "KITY-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 119,
        valor: 20170.5,
        inventario: 119,
        idArticulo: "62745dff95c45c183877d3ff",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "22#",
        nombre: "22#0975",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 140,
        valor: 20167,
        inventario: 140,
        idArticulo: "634c57648a10be00166f7894",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2302",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 135,
        valor: 20166.3,
        inventario: 135,
        idArticulo: "62ed90f750fb0700166b35d0",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2207",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 125,
        valor: 20112.5,
        inventario: 125,
        idArticulo: "62745dff95c45c183877d39c",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 125,
        valor: 20112.5,
        inventario: 125,
        idArticulo: "62745dff95c45c183877d3b2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "108-113",
        nombre: "108-113",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 110,
        valor: 20102.5,
        inventario: 110,
        idArticulo: "631f4fad803c1f0016a31893",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PARKER",
        nombre: "PARKER-XT2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 119,
        valor: 19992,
        inventario: 119,
        idArticulo: "62745e0495c45c183877d62a",
        precio: 375,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 19950,
        inventario: 60,
        idArticulo: "62745e0795c45c183877d758",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 19950,
        inventario: 60,
        idArticulo: "62745e0795c45c183877d74d",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "KILE",
        nombre: "KILE-LE2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 108,
        valor: 19909.8,
        inventario: 108,
        idArticulo: "628be7f495692838f4c58bb0",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEMO",
        nombre: "NEMO-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 19908,
        inventario: 105,
        idArticulo: "62745e0295c45c183877d51a",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 180,
        valor: 19899,
        inventario: 180,
        idArticulo: "62745e0395c45c183877d594",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2203",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 75,
        valor: 19856.25,
        inventario: 75,
        idArticulo: "62745df595c45c183877cf3d",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CRPU",
        nombre: "CRPU-025",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 116,
        valor: 19853.4,
        inventario: 116,
        idArticulo: "62745df995c45c183877d14d",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TANIA",
        nombre: "TANIA-TY2201",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 85,
        valor: 19847.5,
        inventario: 85,
        idArticulo: "62745e0995c45c183877d808",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ALISONPLUS",
        nombre: "ALISONPLUS-HN2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 81,
        valor: 19845,
        inventario: 81,
        idArticulo: "62745df695c45c183877cfac",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 19824,
        inventario: 120,
        idArticulo: "62cee3ccfb6b4e00175aa04e",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 119,
        valor: 19813.5,
        inventario: 119,
        idArticulo: "62745e0495c45c183877d606",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 19770.45,
        inventario: 105,
        idArticulo: "62745e0395c45c183877d5c7",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NEMO",
        nombre: "NEMO-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 104,
        valor: 19718.399999999998,
        inventario: 104,
        idArticulo: "62745e0295c45c183877d525",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2110",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 144,
        valor: 19656,
        inventario: 144,
        idArticulo: "62745e0a95c45c183877d8a0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2306",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 224,
        valor: 19617.92,
        inventario: 224,
        idArticulo: "631129368c32950016f84b5d",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 59,
        valor: 19617.5,
        inventario: 59,
        idArticulo: "62745e0795c45c183877d75a",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "ATS",
        nombre: "ATS-156",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 192,
        valor: 19584,
        inventario: 192,
        idArticulo: "64134f1abd849b0014e4d6c9",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-145",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 192,
        valor: 19584,
        inventario: 192,
        idArticulo: "64134c73bd849b0014e4d5f7",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-146",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 192,
        valor: 19584,
        inventario: 192,
        idArticulo: "64134d7737f14e001485c661",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-149",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 192,
        valor: 19584,
        inventario: 192,
        idArticulo: "64134df737f14e001485c696",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-150",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 192,
        valor: 19584,
        inventario: 192,
        idArticulo: "64134e224043fc001409b70e",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-152",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 192,
        valor: 19584,
        inventario: 192,
        idArticulo: "64134ea74043fc001409b736",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-158",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 192,
        valor: 19584,
        inventario: 192,
        idArticulo: "64134f4a4043fc001409b785",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2206",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 126,
        valor: 19555.199999999997,
        inventario: 126,
        idArticulo: "62745dfe95c45c183877d356",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROBERTHOOD",
        nombre: "ROBERTHOOD-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 96,
        valor: 19488,
        inventario: 96,
        idArticulo: "62745e0795c45c183877d726",
        precio: 335,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBERTHOOD",
        nombre: "ROBERTHOOD-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 96,
        valor: 19488,
        inventario: 96,
        idArticulo: "62745e0795c45c183877d724",
        precio: 335,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2128",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 119,
        valor: 19452.93,
        inventario: 119,
        idArticulo: "62745e0595c45c183877d6b1",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "WARIO",
        nombre: "WARIO-XT2302",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 336,
        valor: 19417.44,
        inventario: 336,
        idArticulo: "62ed65ac9416cf0016d4800c",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ALDOPLUS",
        nombre: "ALDOPLUS-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 84,
        valor: 19416.600000000002,
        inventario: 84,
        idArticulo: "62745df695c45c183877cf8d",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2101",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 142,
        valor: 19383,
        inventario: 142,
        idArticulo: "62745e0a95c45c183877d887",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "VIENA",
        nombre: "VIENA-HN2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 75,
        valor: 19305,
        inventario: 75,
        idArticulo: "62745e0b95c45c183877d8d3",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2205",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 154,
        valor: 19250,
        inventario: 154,
        idArticulo: "62745e0295c45c183877d507",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OLIVIA",
        nombre: "OLIVIA-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 19183.5,
        inventario: 105,
        idArticulo: "62745e0395c45c183877d5ba",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZCRG-LR2202",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 144,
        valor: 19008,
        inventario: 144,
        idArticulo: "62ed745f9416cf0016d4935b",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2301",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 18957.6,
        inventario: 120,
        idArticulo: "62ed90b350fb0700166b351c",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2104",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 192,
        valor: 18950.4,
        inventario: 192,
        idArticulo: "62745dfd95c45c183877d2d6",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2106",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 192,
        valor: 18950.4,
        inventario: 192,
        idArticulo: "62745dfd95c45c183877d2e0",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KISS",
        nombre: "KISS-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 162,
        valor: 18848.7,
        inventario: 162,
        idArticulo: "629e9092f4d9d700160d9785",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KISS",
        nombre: "KISS-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 162,
        valor: 18848.7,
        inventario: 162,
        idArticulo: "629e90dbf4d9d700160d97a0",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TOKYO",
        nombre: "TOKYO-LW2116",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 18786,
        inventario: 120,
        idArticulo: "62745e0995c45c183877d831",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 18780,
        inventario: 60,
        idArticulo: "62c3456127a4e10017c933b6",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JFK",
        nombre: "JFK-XT2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 18720,
        inventario: 90,
        idArticulo: "62745dfd95c45c183877d2d0",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "SILVIA",
        nombre: "SILVIA-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 104,
        valor: 18683.600000000002,
        inventario: 104,
        idArticulo: "62745e0895c45c183877d7cc",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2102",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 189,
        valor: 18654.3,
        inventario: 189,
        idArticulo: "62745dfd95c45c183877d2d4",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SHELLY",
        nombre: "SHELLY-LE2202A",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 80,
        valor: 18592,
        inventario: 80,
        idArticulo: "62745e0895c45c183877d7b4",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SIENAHOOD",
        nombre: "SIENAHOOD-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 18576,
        inventario: 90,
        idArticulo: "62745e0895c45c183877d7be",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIKI",
        nombre: "KIKI-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 108,
        valor: 18549,
        inventario: 108,
        idArticulo: "62745dff95c45c183877d3d9",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JFK",
        nombre: "JFK-XT2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 89,
        valor: 18512,
        inventario: 89,
        idArticulo: "62745dfd95c45c183877d2c6",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "VERSALLESHOOD",
        nombre: "VERSALLESHOOD-LW2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 120,
        valor: 18480,
        inventario: 120,
        idArticulo: "62745e0a95c45c183877d8a9",
        precio: 135,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2101",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 187,
        valor: 18456.9,
        inventario: 187,
        idArticulo: "62745dfd95c45c183877d2d2",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SIENAHOOD",
        nombre: "SIENAHOOD-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 89,
        valor: 18369.600000000002,
        inventario: 89,
        idArticulo: "62745e0895c45c183877d7c0",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 18322.5,
        inventario: 105,
        idArticulo: "62745e0695c45c183877d6d8",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 118,
        valor: 18313.6,
        inventario: 118,
        idArticulo: "62745dfe95c45c183877d346",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 108,
        valor: 18295.2,
        inventario: 108,
        idArticulo: "62745e0a95c45c183877d84b",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 18270,
        inventario: 90,
        idArticulo: "62745df795c45c183877d061",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2302",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 176,
        valor: 18251.2,
        inventario: 176,
        idArticulo: "631128698c32950016f8498d",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "RENO",
        nombre: "RENO-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 104,
        valor: 18213.52,
        inventario: 104,
        idArticulo: "62745e0695c45c183877d70b",
        precio: 255,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2306",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 18156,
        inventario: 120,
        idArticulo: "63503cb66819510016f65316",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JAPAN",
        nombre: "JAPAN-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 116,
        valor: 18148.2,
        inventario: 116,
        idArticulo: "62745dfd95c45c183877d2c2",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBINHOOD",
        nombre: "ROBINHOOD-XT2203",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 156,
        valor: 18103.8,
        inventario: 156,
        idArticulo: "62745e0795c45c183877d736",
        precio: 190,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2302",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 144,
        valor: 18036,
        inventario: 144,
        idArticulo: "62ed736a9416cf0016d490dd",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2301",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 144,
        valor: 18036,
        inventario: 144,
        idArticulo: "62ed72839416cf0016d48ec5",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2304",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 144,
        valor: 18036,
        inventario: 144,
        idArticulo: "62ed73c39416cf0016d491c8",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "WARIO",
        nombre: "WARIO-XT2303",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 312,
        valor: 18030.48,
        inventario: 312,
        idArticulo: "62ed65cc9416cf0016d4804e",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 109,
        valor: 18006.8,
        inventario: 109,
        idArticulo: "62cee3ccfb6b4e00175aa04e",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2202",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 144,
        valor: 18000,
        inventario: 144,
        idArticulo: "62745e0295c45c183877d501",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2208",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 144,
        valor: 18000,
        inventario: 144,
        idArticulo: "62745e0295c45c183877d517",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2207",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 144,
        valor: 18000,
        inventario: 144,
        idArticulo: "62745e0295c45c183877d50c",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIETA",
        nombre: "JULIETA-MG2201",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 77,
        valor: 17998.75,
        inventario: 77,
        idArticulo: "63092967d6368300164ca94b",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 54,
        valor: 17955,
        inventario: 54,
        idArticulo: "62745e0795c45c183877d750",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2306",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 17925.6,
        inventario: 120,
        idArticulo: "62ed91e550fb0700166b366f",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2302",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 17925.6,
        inventario: 120,
        idArticulo: "62ed90f750fb0700166b35d0",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2312",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 17925.6,
        inventario: 120,
        idArticulo: "62ed92eb50fb0700166b3766",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PEACHHOOD",
        nombre: "PEACHHOOD-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 95,
        valor: 17865.7,
        inventario: 95,
        idArticulo: "62745e0495c45c183877d640",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ALDO",
        nombre: "ALDO-BL2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 84,
        valor: 17850,
        inventario: 84,
        idArticulo: "62745df595c45c183877cf81",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "ROX",
        nombre: "ROX-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 17797.5,
        inventario: 105,
        idArticulo: "628be7f495692838f4c58bb6",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KOTY",
        nombre: "KOTY-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 17797.5,
        inventario: 105,
        idArticulo: "62745dff95c45c183877d3fd",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OSLO",
        nombre: "OSLO-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 17792.399999999998,
        inventario: 120,
        idArticulo: "62745e0495c45c183877d5eb",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MARIO",
        nombre: "MARIO-XT2302",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 324,
        valor: 17677.440000000002,
        inventario: 324,
        idArticulo: "62ed65119416cf0016d47fa1",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "MARIO",
        nombre: "MARIO-XT2303",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 324,
        valor: 17677.440000000002,
        inventario: 324,
        idArticulo: "62ed65369416cf0016d47fb8",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "MARIO",
        nombre: "MARIO-XT2301",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 324,
        valor: 17677.440000000002,
        inventario: 324,
        idArticulo: "62ed64ee9416cf0016d47f94",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "MC",
        nombre: "MC-0414",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 80,
        valor: 17671.199999999997,
        inventario: 80,
        idArticulo: "63226c2e283a510016948896",
        precio: 283,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "CINDY",
        nombre: "CINDYGIRLS-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 72,
        valor: 17640,
        inventario: 72,
        idArticulo: "62c3485e27a4e10017c93512",
        precio: 390,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2208",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 104,
        valor: 17596.8,
        inventario: 104,
        idArticulo: "628be7f295692838f4c58b34",
        precio: 250,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 17460,
        inventario: 60,
        idArticulo: "62745dfc95c45c183877d264",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 17460,
        inventario: 60,
        idArticulo: "62745dfc95c45c183877d266",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "ROBIN",
        nombre: "ROBIN-XT2203",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 156,
        valor: 17448.6,
        inventario: 156,
        idArticulo: "62745e0795c45c183877d740",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "LAX",
        nombre: "LAX-XT2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 90,
        valor: 17397,
        inventario: 90,
        idArticulo: "62745e0095c45c183877d43a",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 108,
        valor: 17377.2,
        inventario: 108,
        idArticulo: "62745dfe95c45c183877d38e",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2204",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 108,
        valor: 17377.2,
        inventario: 108,
        idArticulo: "62745dff95c45c183877d3b4",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2208",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 108,
        valor: 17377.2,
        inventario: 108,
        idArticulo: "62745dff95c45c183877d3c0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2207",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 108,
        valor: 17377.2,
        inventario: 108,
        idArticulo: "62745dff95c45c183877d3bc",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2302",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 167,
        valor: 17317.9,
        inventario: 167,
        idArticulo: "631128698c32950016f8498d",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "IMP",
        nombre: "IMP-968",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 17280,
        inventario: 48,
        idArticulo: "636e7b077696f000167f8112",
        precio: 390,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "BARELA",
        nombre: "BARELA-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 80,
        valor: 17256,
        inventario: 80,
        idArticulo: "62745df795c45c183877d019",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2208",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 107,
        valor: 17216.3,
        inventario: 107,
        idArticulo: "62745dff95c45c183877d3a0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2206",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 107,
        valor: 17216.3,
        inventario: 107,
        idArticulo: "62745dff95c45c183877d3be",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 104,
        valor: 17180.8,
        inventario: 104,
        idArticulo: "62cee5b0fb6b4e00175aa0a5",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "22#",
        nombre: "22#0979",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 119,
        valor: 17141.95,
        inventario: 119,
        idArticulo: "634c575ed74f160016d1a0ce",
        precio: 265,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ATS",
        nombre: "ATS-144",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 168,
        valor: 17136,
        inventario: 168,
        idArticulo: "64134bcdbd849b0014e4d5ef",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-148",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 168,
        valor: 17136,
        inventario: 168,
        idArticulo: "64134dc9bd849b0014e4d63b",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-147",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 168,
        valor: 17136,
        inventario: 168,
        idArticulo: "64134db037f14e001485c681",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-151",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 168,
        valor: 17136,
        inventario: 168,
        idArticulo: "64134e484043fc001409b713",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-155",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 168,
        valor: 17136,
        inventario: 168,
        idArticulo: "64134effbd849b0014e4d6bd",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-153",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 168,
        valor: 17136,
        inventario: 168,
        idArticulo: "64134ec2bd849b0014e4d687",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-154",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 168,
        valor: 17136,
        inventario: 168,
        idArticulo: "64134ee737f14e001485c6fb",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-157",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 168,
        valor: 17136,
        inventario: 168,
        idArticulo: "64134f3137f14e001485c70a",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-156",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 168,
        valor: 17136,
        inventario: 168,
        idArticulo: "64134f1abd849b0014e4d6c9",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-159",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 168,
        valor: 17136,
        inventario: 168,
        idArticulo: "64134f634043fc001409b78a",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NIKA",
        nombre: "NIKA-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 180,
        valor: 17064,
        inventario: 180,
        idArticulo: "62745e0395c45c183877d586",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 17064,
        inventario: 90,
        idArticulo: "62745dfa95c45c183877d195",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEMO",
        nombre: "NEMO-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 17064,
        inventario: 90,
        idArticulo: "62745e0295c45c183877d527",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEMO",
        nombre: "NEMO-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 17064,
        inventario: 90,
        idArticulo: "62745e0295c45c183877d51e",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 106,
        valor: 17055.4,
        inventario: 106,
        idArticulo: "62745dff95c45c183877d3b0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2116",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 17035.2,
        inventario: 105,
        idArticulo: "62745e0995c45c183877d846",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MONIQUE",
        nombre: "MONIQUE-MG2002",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 113,
        valor: 17017.8,
        inventario: 113,
        idArticulo: "62745e0295c45c183877d4ef",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "USA",
        nombre: "USA-LR2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 108,
        valor: 17010,
        inventario: 108,
        idArticulo: "62745e0a95c45c183877d87c",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "22#",
        nombre: "22#7975",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 118,
        valor: 16997.9,
        inventario: 118,
        idArticulo: "634c5761d74f160016d1a0d3",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N118",
        nombre: "CH95005(20A)",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 96,
        valor: 16992,
        inventario: 96,
        idArticulo: "628be7f195692838f4c58ad0",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2020",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "PEACH",
        nombre: "PEACH-MG2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 96,
        valor: 16973.760000000002,
        inventario: 96,
        idArticulo: "62745e0495c45c183877d638",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "0024",
        nombre: "0024-2-2",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 95,
        valor: 16952.75,
        inventario: 95,
        idArticulo: "634c556a8a10be00166f7855",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 16946.1,
        inventario: 90,
        idArticulo: "62745e0395c45c183877d5ce",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 16946.1,
        inventario: 90,
        idArticulo: "62745e0395c45c183877d5d0",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "HELLO",
        nombre: "HELLO-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 125,
        valor: 16906.25,
        inventario: 125,
        idArticulo: "62745dfc95c45c183877d256",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "FELIX",
        nombre: "FELIX-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 75,
        valor: 16781.25,
        inventario: 75,
        idArticulo: "62745dfb95c45c183877d1cd",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2206",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 108,
        valor: 16761.6,
        inventario: 108,
        idArticulo: "62745dfe95c45c183877d356",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2208",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 108,
        valor: 16761.6,
        inventario: 108,
        idArticulo: "62745dfe95c45c183877d35a",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "FIJI",
        nombre: "FIJI-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 117,
        valor: 16707.6,
        inventario: 117,
        idArticulo: "62745dfb95c45c183877d1dd",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2302",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 96,
        valor: 16692.48,
        inventario: 96,
        idArticulo: "62c5b0c6883f0f0017d031a7",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2303",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 96,
        valor: 16692.48,
        inventario: 96,
        idArticulo: "62c5b102883f0f0017d031f2",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2301",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 96,
        valor: 16692.48,
        inventario: 96,
        idArticulo: "62c5b05b883f0f0017d030db",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2302",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 96,
        valor: 16692.48,
        inventario: 96,
        idArticulo: "62c5b0c6883f0f0017d031a7",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2301",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 96,
        valor: 16692.48,
        inventario: 96,
        idArticulo: "62c5b05b883f0f0017d030db",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "WARIO",
        nombre: "WARIO-XT2303",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 288,
        valor: 16643.52,
        inventario: 288,
        idArticulo: "62ed65cc9416cf0016d4804e",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2307",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 160,
        valor: 16592,
        inventario: 160,
        idArticulo: "631128d68c32950016f84a81",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2307",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 16587.899999999998,
        inventario: 105,
        idArticulo: "62ed920b50fb0700166b367c",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2107",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 168,
        valor: 16581.600000000002,
        inventario: 168,
        idArticulo: "62745dfd95c45c183877d2e4",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2107",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 168,
        valor: 16581.600000000002,
        inventario: 168,
        idArticulo: "62745dfd95c45c183877d2e4",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBIN",
        nombre: "ROBIN-XT2207",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 148,
        valor: 16553.8,
        inventario: 148,
        idArticulo: "62745e0795c45c183877d74a",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 93,
        valor: 16535.399999999998,
        inventario: 93,
        idArticulo: "62745df895c45c183877d092",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2303",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 95,
        valor: 16518.6,
        inventario: 95,
        idArticulo: "62c5b102883f0f0017d031f2",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2105",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 147,
        valor: 16515.45,
        inventario: 147,
        idArticulo: "62745df895c45c183877d0a3",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 87,
        valor: 16495.2,
        inventario: 87,
        idArticulo: "62745dfa95c45c183877d193",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2106",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 167,
        valor: 16482.9,
        inventario: 167,
        idArticulo: "62745dfd95c45c183877d2e0",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ALDOBOY",
        nombre: "ALDOBOY-BL2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 16452,
        inventario: 90,
        idArticulo: "62745df595c45c183877cf7f",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "JAPAN",
        nombre: "JAPAN-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 16427.25,
        inventario: 105,
        idArticulo: "62745dfd95c45c183877d2ba",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DL27",
        nombre: "DL27FWMCOT017",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 115,
        valor: 16422,
        inventario: 115,
        idArticulo: "628be7f295692838f4c58b0a",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "LEE",
        nombre: "LEE-LE2101",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 154,
        valor: 16331.699999999999,
        inventario: 154,
        idArticulo: "62745e0095c45c183877d444",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MANHATAN",
        nombre: "MANHATAN-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 53,
        valor: 16324,
        inventario: 53,
        idArticulo: "62745e0195c45c183877d4ac",
        precio: 450,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2105",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 98,
        valor: 16317,
        inventario: 98,
        idArticulo: "62745e0495c45c183877d60f",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "KILA",
        nombre: "KILA-HN2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 90,
        valor: 16119,
        inventario: 90,
        idArticulo: "62745dff95c45c183877d3de",
        precio: 235,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 85,
        valor: 16004.65,
        inventario: 85,
        idArticulo: "62745e0395c45c183877d5ce",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 16001.999999999998,
        inventario: 90,
        idArticulo: "62745df895c45c183877d087",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JAPAN",
        nombre: "JAPAN-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 102,
        valor: 15957.900000000001,
        inventario: 102,
        idArticulo: "62745dfd95c45c183877d2b8",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2205",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 60,
        valor: 15885,
        inventario: 60,
        idArticulo: "62745df595c45c183877cf4b",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 96,
        valor: 15859.2,
        inventario: 96,
        idArticulo: "62745e0395c45c183877d574",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIM",
        nombre: "KIM-LE2204",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 108,
        valor: 15854.4,
        inventario: 108,
        idArticulo: "62745dff95c45c183877d3e6",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZCRG-LR2202",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 120,
        valor: 15840,
        inventario: 120,
        idArticulo: "62ed745f9416cf0016d4935b",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZCRG-LR2201",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 120,
        valor: 15840,
        inventario: 120,
        idArticulo: "62ed74319416cf0016d492e8",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BRADYBOY",
        nombre: "BRADYBOY-XT2105",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 15750,
        inventario: 120,
        idArticulo: "62745df895c45c183877d0b6",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "VERSALLESPLUS",
        nombre: "VERSALLESPLUS-LW2205",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 105,
        valor: 15729.000000000002,
        inventario: 105,
        idArticulo: "62745e0b95c45c183877d8cb",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERSALLESPLUS",
        nombre: "VERSALLESPLUS-LW2205",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 105,
        valor: 15729.000000000002,
        inventario: 105,
        idArticulo: "62745e0b95c45c183877d8cb",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MARIO",
        nombre: "MARIO-XT2303",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 288,
        valor: 15713.28,
        inventario: 288,
        idArticulo: "62ed65369416cf0016d47fb8",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "FILIP",
        nombre: "FILIP-KV2205",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 80,
        valor: 15696.000000000002,
        inventario: 80,
        idArticulo: "62745dfb95c45c183877d1ef",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2306",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 15684.9,
        inventario: 105,
        idArticulo: "62ed91e550fb0700166b366f",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2313",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 15684.9,
        inventario: 105,
        idArticulo: "62ed933450fb0700166b37c2",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BIKERHOOD",
        nombre: "BIKERHOOD-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 72,
        valor: 15674.4,
        inventario: 72,
        idArticulo: "62745df795c45c183877d02f",
        precio: 335,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2202",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 156,
        valor: 15584.399999999998,
        inventario: 156,
        idArticulo: "62745dfd95c45c183877d2e8",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OSLO",
        nombre: "OSLO-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 15568.349999999999,
        inventario: 105,
        idArticulo: "62745e0495c45c183877d5e5",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PARIS",
        nombre: "PARIS-HN2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 88,
        valor: 15532,
        inventario: 88,
        idArticulo: "62745e0495c45c183877d61e",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "VIENA",
        nombre: "VIENA-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 15443.999999999998,
        inventario: 60,
        idArticulo: "62745e0b95c45c183877d8cf",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2304",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 96,
        valor: 15350.4,
        inventario: 96,
        idArticulo: "63503c4ad03b310016e5fa37",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROMANKIDS",
        nombre: "ROMANKIDS-HN2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 96,
        valor: 15321.6,
        inventario: 96,
        idArticulo: "62745e0795c45c183877d76a",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "WARIO",
        nombre: "WARIO-XT2301",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 264,
        valor: 15256.56,
        inventario: 264,
        idArticulo: "62ed65849416cf0016d47fcf",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KITY",
        nombre: "KITY-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 15255,
        inventario: 90,
        idArticulo: "62745dff95c45c183877d3ff",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 15246,
        inventario: 90,
        idArticulo: "62745e0a95c45c183877d84b",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "QUEENS",
        nombre: "QUEENS-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 69,
        valor: 15166.2,
        inventario: 69,
        idArticulo: "62745e0695c45c183877d6f2",
        precio: 345,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OLIVIAPLUS",
        nombre: "OLIVIAPLUS-HN2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 72,
        valor: 15120,
        inventario: 72,
        idArticulo: "62745e0395c45c183877d5c1",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 89,
        valor: 15076.6,
        inventario: 89,
        idArticulo: "62745e0a95c45c183877d85d",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PEACHHOOD",
        nombre: "PEACHHOOD-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 80,
        valor: 15044.8,
        inventario: 80,
        idArticulo: "62745e0495c45c183877d640",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2303",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 120,
        valor: 15030,
        inventario: 120,
        idArticulo: "62ed73979416cf0016d49147",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2302",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 120,
        valor: 15030,
        inventario: 120,
        idArticulo: "62ed736a9416cf0016d490dd",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 86,
        valor: 15007,
        inventario: 86,
        idArticulo: "62745e0695c45c183877d6d8",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2206",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 120,
        valor: 15000,
        inventario: 120,
        idArticulo: "62745e0295c45c183877d509",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "0024",
        nombre: "0024-2-2",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 84,
        valor: 14989.8,
        inventario: 84,
        idArticulo: "634c556a8a10be00166f7855",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROX",
        nombre: "ROX-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 88,
        valor: 14916,
        inventario: 88,
        idArticulo: "62745e0795c45c183877d77a",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2304",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 150,
        valor: 14910,
        inventario: 150,
        idArticulo: "631129128c32950016f84b07",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 73,
        valor: 14819,
        inventario: 73,
        idArticulo: "62745df795c45c183877d05b",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LOLAHOOD",
        nombre: "LOLAHOOD-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 74,
        valor: 14770.4,
        inventario: 74,
        idArticulo: "62745e0195c45c183877d483",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "MARIO",
        nombre: "MARIO-XT2301",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 270,
        valor: 14731.2,
        inventario: 270,
        idArticulo: "62ed64ee9416cf0016d47f94",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2305",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 96,
        valor: 14731.2,
        inventario: 96,
        idArticulo: "63503c8dd03b310016e5fa3e",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "QUEENS",
        nombre: "QUEENS-LR2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 67,
        valor: 14726.6,
        inventario: 67,
        idArticulo: "62745e0695c45c183877d6fc",
        precio: 345,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA568#404",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 64,
        valor: 14720,
        inventario: 64,
        idArticulo: "6308f04ab511770016a3b02c",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYO",
        nombre: "TOKYO-LW2116",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 94,
        valor: 14715.7,
        inventario: 94,
        idArticulo: "62745e0995c45c183877d831",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 44,
        valor: 14630,
        inventario: 44,
        idArticulo: "62745e0795c45c183877d750",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "BRADYBOY",
        nombre: "BRADYBOY-XT2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 111,
        valor: 14568.75,
        inventario: 111,
        idArticulo: "62745df895c45c183877d0b3",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "MJ",
        nombre: "MJ-01",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 101,
        valor: 14564.2,
        inventario: 101,
        idArticulo: "62745e0195c45c183877d4d0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2303",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 96,
        valor: 14524.8,
        inventario: 96,
        idArticulo: "63503c1fd03b310016e5f9e9",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2301",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 96,
        valor: 14524.8,
        inventario: 96,
        idArticulo: "63503bb86819510016f651fa",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "FILIP",
        nombre: "FILIP-KV2203",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 74,
        valor: 14518.8,
        inventario: 74,
        idArticulo: "62745dfb95c45c183877d1ed",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2208",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 90,
        valor: 14481,
        inventario: 90,
        idArticulo: "62745dff95c45c183877d3a0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 90,
        valor: 14481,
        inventario: 90,
        idArticulo: "62745dfe95c45c183877d388",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2207",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 90,
        valor: 14481,
        inventario: 90,
        idArticulo: "62745dff95c45c183877d3bc",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "ROSY",
        nombre: "ROSY-TY2201",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 52,
        valor: 14450.8,
        inventario: 52,
        idArticulo: "62745e0795c45c183877d771",
        precio: 480,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2201",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 144,
        valor: 14385.6,
        inventario: 144,
        idArticulo: "62745dfd95c45c183877d2e6",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2203",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 144,
        valor: 14385.6,
        inventario: 144,
        idArticulo: "62745dfd95c45c183877d2f0",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SIENA",
        nombre: "SIENA-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 75,
        valor: 14377.500000000002,
        inventario: 75,
        idArticulo: "62745e0895c45c183877d7c6",
        precio: 305,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SIENA",
        nombre: "SIENA-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 75,
        valor: 14377.500000000002,
        inventario: 75,
        idArticulo: "62745e0895c45c183877d7ca",
        precio: 305,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2103",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 100,
        valor: 14279.999999999998,
        inventario: 100,
        idArticulo: "62745e0595c45c183877d685",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2101",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 119,
        valor: 14244.300000000001,
        inventario: 119,
        idArticulo: "62745dfd95c45c183877d2fc",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KITY",
        nombre: "KITY-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 84,
        valor: 14238,
        inventario: 84,
        idArticulo: "62745dff95c45c183877d3f8",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROX",
        nombre: "ROX-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 84,
        valor: 14238,
        inventario: 84,
        idArticulo: "62745e0795c45c183877d77a",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NEMO",
        nombre: "NEMO-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 75,
        valor: 14220,
        inventario: 75,
        idArticulo: "62745e0295c45c183877d51a",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2102",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 144,
        valor: 14212.8,
        inventario: 144,
        idArticulo: "62745dfd95c45c183877d2d4",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OTIS",
        nombre: "OTIS-MG2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 78,
        valor: 14203.8,
        inventario: 78,
        idArticulo: "62745e0495c45c183877d5f7",
        precio: 265,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MANHATAN",
        nombre: "MANHATAN-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 46,
        valor: 14168,
        inventario: 46,
        idArticulo: "62745e0195c45c183877d4b2",
        precio: 450,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "FILIP",
        nombre: "FILIP-KV2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 72,
        valor: 14126.4,
        inventario: 72,
        idArticulo: "62745dfb95c45c183877d1e7",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2105",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 118,
        valor: 14124.6,
        inventario: 118,
        idArticulo: "62745dfd95c45c183877d30a",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 75,
        valor: 14121.75,
        inventario: 75,
        idArticulo: "62745e0395c45c183877d5d0",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2301",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 139,
        valor: 14115.449999999999,
        inventario: 139,
        idArticulo: "6311281c8c32950016f848dd",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2105",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 143,
        valor: 14114.1,
        inventario: 143,
        idArticulo: "62745dfd95c45c183877d2e2",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYO",
        nombre: "TOKYO-LW2118",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 14089.500000000002,
        inventario: 90,
        idArticulo: "62745e0995c45c183877d83a",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYO",
        nombre: "TOKYO-LW2117",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 14089.500000000002,
        inventario: 90,
        idArticulo: "62745e0995c45c183877d833",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2306",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 160,
        valor: 14012.8,
        inventario: 160,
        idArticulo: "631129368c32950016f84b5d",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2104",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 117,
        valor: 14004.9,
        inventario: 117,
        idArticulo: "62745dfd95c45c183877d302",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2205",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 87,
        valor: 13998.300000000001,
        inventario: 87,
        idArticulo: "62745dfe95c45c183877d392",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "GIRLZ",
        nombre: "GIRLZ-LE2104",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 180,
        valor: 13986,
        inventario: 180,
        idArticulo: "62745dfb95c45c183877d211",
        precio: 165,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 84,
        valor: 13986,
        inventario: 84,
        idArticulo: "62745e0495c45c183877d606",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "LOLAHOOD",
        nombre: "LOLAHOOD-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 70,
        valor: 13972,
        inventario: 70,
        idArticulo: "62745e0195c45c183877d485",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "KISS",
        nombre: "KISS-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 120,
        valor: 13962,
        inventario: 120,
        idArticulo: "62745dff95c45c183877d3f6",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LIAMPLUS",
        nombre: "LIAMPLUS-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 13962,
        inventario: 60,
        idArticulo: "62745e0095c45c183877d478",
        precio: 365,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "SHELLY",
        nombre: "SHELLY-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 13944,
        inventario: 60,
        idArticulo: "62745e0895c45c183877d7b2",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "FILIP",
        nombre: "FILIP-KV2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 71,
        valor: 13930.2,
        inventario: 71,
        idArticulo: "62745dfb95c45c183877d1e7",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "WARIO",
        nombre: "WARIO-XT2302",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 240,
        valor: 13869.6,
        inventario: 240,
        idArticulo: "62ed65ac9416cf0016d4800c",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "N037",
        nombre: "7301",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 83,
        valor: 13819.5,
        inventario: 83,
        idArticulo: "62745df495c45c183877ceb1",
        precio: 210,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "VERSALLESHOOD",
        nombre: "VERSALLESHOOD-LW2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 89,
        valor: 13706,
        inventario: 89,
        idArticulo: "62745e0a95c45c183877d8a6",
        precio: 135,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KENTHOOD",
        nombre: "KENTHOOD-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 56,
        valor: 13692,
        inventario: 56,
        idArticulo: "62745dfe95c45c183877d37c",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "SINGAPUR",
        nombre: "SINGAPUR-LW2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 72,
        valor: 13615.199999999997,
        inventario: 72,
        idArticulo: "62745e0895c45c183877d7e1",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MONIQUEHOOD",
        nombre: "MONIQUEHOOD-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 85,
        valor: 13515,
        inventario: 85,
        idArticulo: "62745e0195c45c183877d4e4",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SILVIA",
        nombre: "SILVIA-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 75,
        valor: 13473.75,
        inventario: 75,
        idArticulo: "62745e0895c45c183877d7cc",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "FELIX",
        nombre: "FELIX-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 13425,
        inventario: 60,
        idArticulo: "62745dfa95c45c183877d1c9",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "USA",
        nombre: "USA-LR2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 85,
        valor: 13387.5,
        inventario: 85,
        idArticulo: "62745e0a95c45c183877d87c",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BARELA",
        nombre: "BARELA-MG2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 62,
        valor: 13373.4,
        inventario: 62,
        idArticulo: "62745df795c45c183877d01b",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OSLO",
        nombre: "OSLO-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 90,
        valor: 13344.3,
        inventario: 90,
        idArticulo: "62745e0495c45c183877d5eb",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PACO",
        nombre: "PACO-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 59,
        valor: 13310.4,
        inventario: 59,
        idArticulo: "62745e0495c45c183877d611",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2302",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 128,
        valor: 13273.6,
        inventario: 128,
        idArticulo: "631128698c32950016f8498d",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2303",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 128,
        valor: 13273.6,
        inventario: 128,
        idArticulo: "631128a68c32950016f84a4a",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2307",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 128,
        valor: 13273.6,
        inventario: 128,
        idArticulo: "631128d68c32950016f84a81",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2302",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 128,
        valor: 13273.6,
        inventario: 128,
        idArticulo: "631128698c32950016f8498d",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2303",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 128,
        valor: 13273.6,
        inventario: 128,
        idArticulo: "631128a68c32950016f84a4a",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2307",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 128,
        valor: 13273.6,
        inventario: 128,
        idArticulo: "631128d68c32950016f84a81",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2307",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 128,
        valor: 13273.6,
        inventario: 128,
        idArticulo: "631128d68c32950016f84a81",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OLIVIAKIDS",
        nombre: "OLIVIAKIDS-HN2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 80,
        valor: 13272,
        inventario: 80,
        idArticulo: "62745e0395c45c183877d5bc",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "PARIS",
        nombre: "PARIS-HN2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 75,
        valor: 13237.5,
        inventario: 75,
        idArticulo: "62745e0495c45c183877d61e",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "GABO",
        nombre: "GABO-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 59,
        valor: 13233.7,
        inventario: 59,
        idArticulo: "62745dfb95c45c183877d1fb",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "KIM",
        nombre: "KIM-LE2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 90,
        valor: 13212.000000000002,
        inventario: 90,
        idArticulo: "62745dff95c45c183877d3e4",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2203",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 78,
        valor: 13197.600000000002,
        inventario: 78,
        idArticulo: "62745e0295c45c183877d53b",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 82,
        valor: 13193.8,
        inventario: 82,
        idArticulo: "62745dfe95c45c183877d386",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "ELZAPLUS",
        nombre: "ELZAPLUS-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 120,
        valor: 13104,
        inventario: 120,
        idArticulo: "62745dfa95c45c183877d1a9",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 13095,
        inventario: 45,
        idArticulo: "62745dfc95c45c183877d278",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 13095,
        inventario: 45,
        idArticulo: "62745dfc95c45c183877d270",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 13095,
        inventario: 45,
        idArticulo: "62745dfc95c45c183877d276",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 13095,
        inventario: 45,
        idArticulo: "62745dfc95c45c183877d26a",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "ROBERTHOOD",
        nombre: "ROBERTHOOD-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 64,
        valor: 12992,
        inventario: 64,
        idArticulo: "62745e0795c45c183877d726",
        precio: 335,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N074",
        nombre: "7297CH",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 90,
        valor: 12969,
        inventario: 90,
        idArticulo: "62745df595c45c183877cf21",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2108",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 95,
        valor: 12967.5,
        inventario: 95,
        idArticulo: "62745e0a95c45c183877d89b",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "BARELAHOOD",
        nombre: "BARELAHOOD-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 12942,
        inventario: 60,
        idArticulo: "62745df795c45c183877d016",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TURIN",
        nombre: "TURIN-DG2104",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 108,
        valor: 12927.6,
        inventario: 108,
        idArticulo: "62745e0a95c45c183877d875",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2110",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 90,
        valor: 12851.999999999998,
        inventario: 90,
        idArticulo: "62745e0595c45c183877d699",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2109",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 90,
        valor: 12851.999999999998,
        inventario: 90,
        idArticulo: "62745e0595c45c183877d696",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MARIO",
        nombre: "MARIO-XT2301",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 234,
        valor: 12767.04,
        inventario: 234,
        idArticulo: "62ed64ee9416cf0016d47f94",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BARELA",
        nombre: "BARELA-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 59,
        valor: 12726.3,
        inventario: 59,
        idArticulo: "62745df795c45c183877d019",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2304",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 128,
        valor: 12723.2,
        inventario: 128,
        idArticulo: "631129128c32950016f84b07",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2304",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 128,
        valor: 12723.2,
        inventario: 128,
        idArticulo: "631129128c32950016f84b07",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 75,
        valor: 12705,
        inventario: 75,
        idArticulo: "62745e0a95c45c183877d855",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZCRG-LR2201",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 96,
        valor: 12672,
        inventario: 96,
        idArticulo: "62ed74319416cf0016d492e8",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZCRG-LR2202",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 96,
        valor: 12672,
        inventario: 96,
        idArticulo: "62ed745f9416cf0016d4935b",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "CLOE",
        nombre: "CLOE-LE2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 75,
        valor: 12585,
        inventario: 75,
        idArticulo: "62745df995c45c183877d125",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 76,
        valor: 12555.2,
        inventario: 76,
        idArticulo: "62745e0395c45c183877d56d",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PERUPLUS",
        nombre: "PERUPLUS-LW2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 81,
        valor: 12542.04,
        inventario: 81,
        idArticulo: "62745e0595c45c183877d65a",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2201",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 74,
        valor: 12520.8,
        inventario: 74,
        idArticulo: "62745e0295c45c183877d530",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2304",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 72,
        valor: 12519.36,
        inventario: 72,
        idArticulo: "62c5b130883f0f0017d03200",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 65,
        valor: 12512.5,
        inventario: 65,
        idArticulo: "62745e0295c45c183877d55b",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DHL",
        nombre: "DHL-LE2101",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 125,
        valor: 12468.75,
        inventario: 125,
        idArticulo: "62745dfa95c45c183877d169",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SIENAHOOD",
        nombre: "SIENAHOOD-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 12384,
        inventario: 60,
        idArticulo: "62745e0895c45c183877d7be",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NYRSEV",
        nombre: "NYRSEV-2204",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 156,
        valor: 12353.64,
        inventario: 156,
        idArticulo: "628be7f395692838f4c58b42",
        precio: 8.8,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-148",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 120,
        valor: 12240,
        inventario: 120,
        idArticulo: "64134dc9bd849b0014e4d63b",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-158",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 120,
        valor: 12240,
        inventario: 120,
        idArticulo: "64134f4a4043fc001409b785",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 65,
        valor: 12238.85,
        inventario: 65,
        idArticulo: "62745e0395c45c183877d5cc",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 76,
        valor: 12228.4,
        inventario: 76,
        idArticulo: "62745dfe95c45c183877d386",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIKI",
        nombre: "KIKI-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 71,
        valor: 12194.25,
        inventario: 71,
        idArticulo: "62745dff95c45c183877d3d9",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LUCA",
        nombre: "LUCA-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 62,
        valor: 12114.8,
        inventario: 62,
        idArticulo: "62745e0195c45c183877d49d",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2206",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 75,
        valor: 12067.5,
        inventario: 75,
        idArticulo: "62745dff95c45c183877d398",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "PEACHHOOD",
        nombre: "PEACHHOOD-MG2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 64,
        valor: 12035.84,
        inventario: 64,
        idArticulo: "62745e0495c45c183877d63c",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DENIMZ",
        nombre: "DENIMZ-LR2303",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 96,
        valor: 12024,
        inventario: 96,
        idArticulo: "62ed73979416cf0016d49147",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2207",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 71,
        valor: 12013.2,
        inventario: 71,
        idArticulo: "62745e0295c45c183877d544",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "TEO",
        nombre: "TEO-LE2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 72,
        valor: 12006,
        inventario: 72,
        idArticulo: "62745e0995c45c183877d81d",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2209",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 96,
        valor: 12000,
        inventario: 96,
        idArticulo: "62745e0295c45c183877d513",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N036",
        nombre: "7300",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 72,
        valor: 11988,
        inventario: 72,
        idArticulo: "62745df495c45c183877cea9",
        precio: 210,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 59,
        valor: 11977,
        inventario: 59,
        idArticulo: "62745df895c45c183877d06f",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LOLAHOOD",
        nombre: "LOLAHOOD-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 11976,
        inventario: 60,
        idArticulo: "62745e0195c45c183877d483",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "LOLAHOOD",
        nombre: "LOLAHOOD-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 11976,
        inventario: 60,
        idArticulo: "62745e0195c45c183877d485",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "KYOTORIB",
        nombre: "KYOTORIB-LW2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 64,
        valor: 11974.4,
        inventario: 64,
        idArticulo: "62745e0095c45c183877d42a",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MONIQUE",
        nombre: "MONIQUE-MG2002",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 79,
        valor: 11897.4,
        inventario: 79,
        idArticulo: "62745e0295c45c183877d4ef",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2104",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 120,
        valor: 11844,
        inventario: 120,
        idArticulo: "62745dfd95c45c183877d2d6",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2105",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 120,
        valor: 11844,
        inventario: 120,
        idArticulo: "62745dfd95c45c183877d2e2",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PACOPLUS",
        nombre: "PACOPLUS-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 11836.8,
        inventario: 48,
        idArticulo: "62745e0495c45c183877d616",
        precio: 365,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OTIS",
        nombre: "OTIS-MG2205",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 65,
        valor: 11836.5,
        inventario: 65,
        idArticulo: "62745e0495c45c183877d5ff",
        precio: 265,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2104",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 71,
        valor: 11821.5,
        inventario: 71,
        idArticulo: "62745e0495c45c183877d609",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "LUIGI",
        nombre: "LUIGI-BL2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 11800.8,
        inventario: 48,
        idArticulo: "62745e0195c45c183877d4aa",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "LUIGI",
        nombre: "LUIGI-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 11800.8,
        inventario: 48,
        idArticulo: "62745e0195c45c183877d4b0",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "CINDY",
        nombre: "CINDY-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 34,
        valor: 11730,
        inventario: 34,
        idArticulo: "62745df995c45c183877d121",
        precio: 535,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2202",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 44,
        valor: 11649,
        inventario: 44,
        idArticulo: "62745df595c45c183877cf3f",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBIN",
        nombre: "ROBIN-XT2206",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 104,
        valor: 11632.4,
        inventario: 104,
        idArticulo: "62745e0795c45c183877d746",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2108",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 85,
        valor: 11602.5,
        inventario: 85,
        idArticulo: "62745e0a95c45c183877d89b",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 108,
        valor: 11599.2,
        inventario: 108,
        idArticulo: "62745e0395c45c183877d57f",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2205",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 108,
        valor: 11599.2,
        inventario: 108,
        idArticulo: "62745e0395c45c183877d581",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2208",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 72,
        valor: 11584.8,
        inventario: 72,
        idArticulo: "62745dff95c45c183877d3c0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 72,
        valor: 11584.8,
        inventario: 72,
        idArticulo: "62745dff95c45c183877d3b2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 11550,
        inventario: 60,
        idArticulo: "62cee609fb6b4e00175aa0b2",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "VERSALLES",
        nombre: "VERSALLES-LW2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 86,
        valor: 11541.2,
        inventario: 86,
        idArticulo: "62745e0a95c45c183877d8ad",
        precio: 125,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 66,
        valor: 11517,
        inventario: 66,
        idArticulo: "62745e0695c45c183877d6ce",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2304",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 72,
        valor: 11512.8,
        inventario: 72,
        idArticulo: "63503c4ad03b310016e5fa37",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 104,
        valor: 11497.2,
        inventario: 104,
        idArticulo: "62745e0395c45c183877d594",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 107,
        valor: 11491.8,
        inventario: 107,
        idArticulo: "62745e0395c45c183877d583",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2105",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 96,
        valor: 11491.2,
        inventario: 96,
        idArticulo: "62745dfd95c45c183877d30a",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2116",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 96,
        valor: 11491.2,
        inventario: 96,
        idArticulo: "62745dfd95c45c183877d31c",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2115",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 96,
        valor: 11491.2,
        inventario: 96,
        idArticulo: "62745dfd95c45c183877d320",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIKA",
        nombre: "NIKA-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 121,
        valor: 11470.8,
        inventario: 121,
        idArticulo: "62745e0395c45c183877d591",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2205",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 71,
        valor: 11423.9,
        inventario: 71,
        idArticulo: "62745dff95c45c183877d3c2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "FILIP",
        nombre: "FILIP-KV2204",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 58,
        valor: 11379.6,
        inventario: 58,
        idArticulo: "62745dfb95c45c183877d1eb",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 11376,
        inventario: 60,
        idArticulo: "62745dfa95c45c183877d197",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2103",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 95,
        valor: 11371.5,
        inventario: 95,
        idArticulo: "62745dfd95c45c183877d300",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2102",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 95,
        valor: 11371.5,
        inventario: 95,
        idArticulo: "62745dfd95c45c183877d2fa",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2104",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 95,
        valor: 11371.5,
        inventario: 95,
        idArticulo: "62745dfd95c45c183877d302",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2309",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 75,
        valor: 11364.75,
        inventario: 75,
        idArticulo: "62ed926450fb0700166b36e9",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N067",
        nombre: "7268B",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 70,
        valor: 11338.600000000002,
        inventario: 70,
        idArticulo: "62745df495c45c183877cf09",
        precio: 210,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "KENT",
        nombre: "KENT-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 11332.8,
        inventario: 48,
        idArticulo: "62745dfe95c45c183877d37e",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "PEACH",
        nombre: "PEACH-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 64,
        valor: 11315.84,
        inventario: 64,
        idArticulo: "62745e0495c45c183877d63a",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PEACH",
        nombre: "PEACH-MG2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 64,
        valor: 11315.84,
        inventario: 64,
        idArticulo: "62745e0495c45c183877d638",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 11297.4,
        inventario: 60,
        idArticulo: "62745e0395c45c183877d5d0",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 11297.4,
        inventario: 60,
        idArticulo: "62745e0395c45c183877d5ce",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2308",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 75,
        valor: 11284.5,
        inventario: 75,
        idArticulo: "62ed923750fb0700166b36dc",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2313",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 75,
        valor: 11203.5,
        inventario: 75,
        idArticulo: "62ed933450fb0700166b37c2",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 72,
        valor: 11174.4,
        inventario: 72,
        idArticulo: "62745dfe95c45c183877d346",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2103",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 113,
        valor: 11153.1,
        inventario: 113,
        idArticulo: "62745dfd95c45c183877d2d8",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2104",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 93,
        valor: 11132.1,
        inventario: 93,
        idArticulo: "62745dfd95c45c183877d302",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2104",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 99,
        valor: 11122.65,
        inventario: 99,
        idArticulo: "62745df895c45c183877d09b",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "WARIO",
        nombre: "WARIO-XT2301",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 192,
        valor: 11095.68,
        inventario: 192,
        idArticulo: "62ed65849416cf0016d47fcf",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ALDOPLUS",
        nombre: "ALDOPLUS-BL2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 11095.2,
        inventario: 48,
        idArticulo: "62745df695c45c183877cf88",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "ALDOPLUS",
        nombre: "ALDOPLUS-BL2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 11095.2,
        inventario: 48,
        idArticulo: "62745df695c45c183877cf88",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2305",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 72,
        valor: 11048.4,
        inventario: 72,
        idArticulo: "63503c8dd03b310016e5fa3e",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA568#405",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 48,
        valor: 11040,
        inventario: 48,
        idArticulo: "6308f06eb511770016a3b05a",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PIAMONTE",
        nombre: "PIAMONTE-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 72,
        valor: 11037.6,
        inventario: 72,
        idArticulo: "62745e0595c45c183877d671",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2107",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 92,
        valor: 11012.4,
        inventario: 92,
        idArticulo: "62745dfd95c45c183877d30c",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ZGL",
        nombre: "ZGL-LE2101",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 107,
        valor: 11010.300000000001,
        inventario: 107,
        idArticulo: "62745e0b95c45c183877d8e3",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2107",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 68,
        valor: 10995.6,
        inventario: 68,
        idArticulo: "62745dfc95c45c183877d2b2",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 63,
        valor: 10993.5,
        inventario: 63,
        idArticulo: "62745e0695c45c183877d6cc",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 57,
        valor: 10972.5,
        inventario: 57,
        idArticulo: "62745e0295c45c183877d561",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEO",
        nombre: "LEO-LE2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 69,
        valor: 10926.15,
        inventario: 69,
        idArticulo: "62745e0095c45c183877d460",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2301",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 72,
        valor: 10893.6,
        inventario: 72,
        idArticulo: "63503bb86819510016f651fa",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKOHOOD",
        nombre: "NIKOHOOD-MG2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 67,
        valor: 10835.24,
        inventario: 67,
        idArticulo: "62745e0395c45c183877d5ac",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SILVIA",
        nombre: "SILVIA-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 10779,
        inventario: 60,
        idArticulo: "62745e0895c45c183877d7d0",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "INDI",
        nombre: "INDIGIRLS-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 10752,
        inventario: 48,
        idArticulo: "62c348f427a4e10017c93539",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "D19",
        nombre: "D1914(21B)",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 149,
        valor: 10728,
        inventario: 149,
        idArticulo: "62745dfa95c45c183877d157",
        precio: 85,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 96,
        valor: 10612.8,
        inventario: 96,
        idArticulo: "62745e0395c45c183877d596",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2112",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 74,
        valor: 10567.2,
        inventario: 74,
        idArticulo: "62745e0595c45c183877d6a2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2213",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 10470,
        inventario: 60,
        idArticulo: "62745e0695c45c183877d6ec",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 10470,
        inventario: 60,
        idArticulo: "62745e0695c45c183877d6da",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2212",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 10470,
        inventario: 60,
        idArticulo: "62745e0695c45c183877d6ea",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N091",
        nombre: "6320",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 60,
        valor: 10428.6,
        inventario: 60,
        idArticulo: "628be7f095692838f4c58a7c",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "FIJI",
        nombre: "FIJI-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 73,
        valor: 10424.4,
        inventario: 73,
        idArticulo: "62745dfb95c45c183877d1d9",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "LAX",
        nombre: "LAX-XT2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 53,
        valor: 10244.9,
        inventario: 53,
        idArticulo: "62745e0095c45c183877d43a",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "LAX",
        nombre: "LAX-XT2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 53,
        valor: 10244.9,
        inventario: 53,
        idArticulo: "62745e0095c45c183877d438",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NIKA",
        nombre: "NIKA-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 108,
        valor: 10238.4,
        inventario: 108,
        idArticulo: "62745e0395c45c183877d586",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ALDOBOY",
        nombre: "ALDOBOY-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 56,
        valor: 10236.8,
        inventario: 56,
        idArticulo: "62745df695c45c183877cf86",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "ALDO",
        nombre: "ALDO-BL2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 10200,
        inventario: 48,
        idArticulo: "62745df595c45c183877cf81",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "ROX",
        nombre: "ROX-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 10170,
        inventario: 60,
        idArticulo: "62745e0795c45c183877d77c",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 10164,
        inventario: 60,
        idArticulo: "62745e0a95c45c183877d855",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RIKO",
        nombre: "RIKO-HN2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 63,
        valor: 10070.55,
        inventario: 63,
        idArticulo: "62745e0695c45c183877d717",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "FELIX",
        nombre: "FELIX-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 10068.75,
        inventario: 45,
        idArticulo: "62745dfa95c45c183877d1cb",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N023",
        nombre: "6388",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 60,
        valor: 10050.599999999999,
        inventario: 60,
        idArticulo: "62745df395c45c183877ce6d",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 59,
        valor: 9994.6,
        inventario: 59,
        idArticulo: "62745e0a95c45c183877d850",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 9975,
        inventario: 30,
        idArticulo: "62745e0795c45c183877d750",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 90,
        valor: 9949.5,
        inventario: 90,
        idArticulo: "62745e0395c45c183877d598",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 90,
        valor: 9949.5,
        inventario: 90,
        idArticulo: "62745e0395c45c183877d594",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 57,
        valor: 9946.5,
        inventario: 57,
        idArticulo: "62745e0695c45c183877d6dc",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MARIO",
        nombre: "MARIO-XT2301",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 182,
        valor: 9929.92,
        inventario: 182,
        idArticulo: "62ed64ee9416cf0016d47f94",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 9912,
        inventario: 60,
        idArticulo: "62cee550fb6b4e00175aa098",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 89,
        valor: 9838.949999999999,
        inventario: 89,
        idArticulo: "62745e0395c45c183877d596",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MJ",
        nombre: "MJ-02",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 68,
        valor: 9805.599999999999,
        inventario: 68,
        idArticulo: "62745e0195c45c183877d4d2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "2921",
        nombre: "2921-2-4R",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 37,
        valor: 9792.42,
        inventario: 37,
        idArticulo: "632cb9e8519b4700162c22d8",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-144",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134bcdbd849b0014e4d5ef",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-145",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134c73bd849b0014e4d5f7",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-146",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134d7737f14e001485c661",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-147",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134db037f14e001485c681",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-149",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134df737f14e001485c696",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-151",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134e484043fc001409b713",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-152",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134ea74043fc001409b736",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-153",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134ec2bd849b0014e4d687",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-154",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134ee737f14e001485c6fb",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-155",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134effbd849b0014e4d6bd",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-157",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134f3137f14e001485c70a",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-149",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134df737f14e001485c696",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-154",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134ee737f14e001485c6fb",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-151",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134e484043fc001409b713",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-152",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134ea74043fc001409b736",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-156",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134f1abd849b0014e4d6c9",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-159",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134f634043fc001409b78a",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-144",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134bcdbd849b0014e4d5ef",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-145",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134c73bd849b0014e4d5f7",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-146",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134d7737f14e001485c661",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-148",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134dc9bd849b0014e4d63b",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-152",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134ea74043fc001409b736",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-154",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134ee737f14e001485c6fb",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-156",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134f1abd849b0014e4d6c9",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-159",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 96,
        valor: 9792,
        inventario: 96,
        idArticulo: "64134f634043fc001409b78a",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "DONNA",
        nombre: "DONNA-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 9774,
        inventario: 45,
        idArticulo: "62745dfa95c45c183877d18d",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA-MG2203",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 43,
        valor: 9767.45,
        inventario: 43,
        idArticulo: "62745dfe95c45c183877d336",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2301",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 96,
        valor: 9748.8,
        inventario: 96,
        idArticulo: "6311281c8c32950016f848dd",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2301",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 96,
        valor: 9748.8,
        inventario: 96,
        idArticulo: "6311281c8c32950016f848dd",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2301",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 96,
        valor: 9748.8,
        inventario: 96,
        idArticulo: "6311281c8c32950016f848dd",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROBERT",
        nombre: "ROBERT-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 9744,
        inventario: 48,
        idArticulo: "62745e0795c45c183877d728",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "HELLO",
        nombre: "HELLO-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 72,
        valor: 9738,
        inventario: 72,
        idArticulo: "62745dfc95c45c183877d256",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIK",
        nombre: "NIK-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 95,
        valor: 9704.25,
        inventario: 95,
        idArticulo: "62745e0395c45c183877d5a3",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SIENAHOOD",
        nombre: "SIENAHOOD-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 47,
        valor: 9700.800000000001,
        inventario: 47,
        idArticulo: "62745e0895c45c183877d7ba",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "0024",
        nombre: "0024-5-1",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 9700.8,
        inventario: 48,
        idArticulo: "634c55678a10be00166f7852",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 90,
        valor: 9666,
        inventario: 90,
        idArticulo: "62745e0395c45c183877d576",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEO",
        nombre: "LEO-LE2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 61,
        valor: 9659.35,
        inventario: 61,
        idArticulo: "62745e0095c45c183877d45e",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N076",
        nombre: "7301B",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 62,
        valor: 9585.199999999999,
        inventario: 62,
        idArticulo: "62745df595c45c183877cf23",
        precio: 210,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2101",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 97,
        valor: 9573.900000000001,
        inventario: 97,
        idArticulo: "62745dfd95c45c183877d2d2",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2304",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 96,
        valor: 9542.400000000001,
        inventario: 96,
        idArticulo: "631129128c32950016f84b07",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2304",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 96,
        valor: 9542.400000000001,
        inventario: 96,
        idArticulo: "631129128c32950016f84b07",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MONIQUE",
        nombre: "MONIQUE-MG2002",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 63,
        valor: 9487.8,
        inventario: 63,
        idArticulo: "62745e0295c45c183877d4ef",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2307",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 9478.8,
        inventario: 60,
        idArticulo: "62ed920b50fb0700166b367c",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2301",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 9478.8,
        inventario: 60,
        idArticulo: "62ed90b350fb0700166b351c",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2307",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 9478.8,
        inventario: 60,
        idArticulo: "62ed920b50fb0700166b367c",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "FILIP",
        nombre: "FILIP-KV2204",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 48,
        valor: 9417.6,
        inventario: 48,
        idArticulo: "62745dfb95c45c183877d1eb",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "JFK",
        nombre: "JFK-XT2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 9360,
        inventario: 45,
        idArticulo: "62d924f7b99c0e0016567eac",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "DONNA",
        nombre: "DONNA-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 43,
        valor: 9339.6,
        inventario: 43,
        idArticulo: "62745dfa95c45c183877d18f",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N106",
        nombre: "22528-B",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 29,
        valor: 9304.650000000001,
        inventario: 29,
        idArticulo: "628be7f195692838f4c58aa2",
        precio: 390,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "BIKERHOOD",
        nombre: "BIKERHOOD-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 42,
        valor: 9143.4,
        inventario: 42,
        idArticulo: "62745df795c45c183877d02d",
        precio: 335,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 9135,
        inventario: 45,
        idArticulo: "62745df795c45c183877d05d",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2305",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 9091.8,
        inventario: 60,
        idArticulo: "62ed91c250fb0700166b3662",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2305",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 9091.8,
        inventario: 60,
        idArticulo: "62ed91c250fb0700166b3662",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2310",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 9091.8,
        inventario: 60,
        idArticulo: "62ed92ad50fb0700166b3733",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SIENAHOOD",
        nombre: "SIENAHOOD-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 44,
        valor: 9081.6,
        inventario: 44,
        idArticulo: "62745e0895c45c183877d7be",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2104",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 92,
        valor: 9080.4,
        inventario: 92,
        idArticulo: "62745dfd95c45c183877d2d6",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2308",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 9027.6,
        inventario: 60,
        idArticulo: "62ed923750fb0700166b36dc",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2306",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 8962.8,
        inventario: 60,
        idArticulo: "62ed91e550fb0700166b366f",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2312",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 60,
        valor: 8962.8,
        inventario: 60,
        idArticulo: "62ed92eb50fb0700166b3766",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JFK",
        nombre: "JFK-XT2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 43,
        valor: 8944,
        inventario: 43,
        idArticulo: "62745dfd95c45c183877d2ce",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2210",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 44,
        valor: 8932,
        inventario: 44,
        idArticulo: "62745df895c45c183877d06b",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KATY",
        nombre: "KATY-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 8919,
        inventario: 30,
        idArticulo: "62745dfe95c45c183877d368",
        precio: 490,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "CARRERA",
        nombre: "CARRERA-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 8910,
        inventario: 30,
        idArticulo: "62745df995c45c183877d0f3",
        precio: 465,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "KISS",
        nombre: "KISS-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 76,
        valor: 8842.6,
        inventario: 76,
        idArticulo: "629e9092f4d9d700160d9785",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N024",
        nombre: "6392",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 63,
        valor: 8833.230000000001,
        inventario: 63,
        idArticulo: "62745df395c45c183877ce73",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2202",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 52,
        valor: 8798.400000000001,
        inventario: 52,
        idArticulo: "62745e0295c45c183877d537",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "N120",
        nombre: "CO85002(20A)",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 80,
        valor: 8784,
        inventario: 80,
        idArticulo: "628be7f195692838f4c58ad4",
        precio: 130,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2020",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "1915",
        nombre: "1915-1-2D",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 31,
        valor: 8742,
        inventario: 31,
        idArticulo: "632cb8bf519b4700162c1ff8",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2210",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 8730,
        inventario: 30,
        idArticulo: "62745dfc95c45c183877d27f",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 8730,
        inventario: 30,
        idArticulo: "62745dfc95c45c183877d264",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "SHAKIRA",
        nombre: "SHAKIRA-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 36,
        valor: 8706.6,
        inventario: 36,
        idArticulo: "634c4adad74f160016d19f48",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 54,
        valor: 8688.6,
        inventario: 54,
        idArticulo: "62745dff95c45c183877d3b2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2206",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 54,
        valor: 8688.6,
        inventario: 54,
        idArticulo: "62745dff95c45c183877d3be",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 8662.5,
        inventario: 45,
        idArticulo: "62745e0295c45c183877d55b",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 8662.5,
        inventario: 45,
        idArticulo: "62745e0395c45c183877d566",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 8662.5,
        inventario: 45,
        idArticulo: "62745e0295c45c183877d561",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2201",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 51,
        valor: 8629.2,
        inventario: 51,
        idArticulo: "62745e0295c45c183877d530",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BARELA",
        nombre: "BARELA-MG2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 40,
        valor: 8628,
        inventario: 40,
        idArticulo: "62745df795c45c183877d01b",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SIENA",
        nombre: "SIENA-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 8626.5,
        inventario: 45,
        idArticulo: "62745e0895c45c183877d7c6",
        precio: 305,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2104",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 72,
        valor: 8618.4,
        inventario: 72,
        idArticulo: "62745dfd95c45c183877d302",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2116",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 72,
        valor: 8618.4,
        inventario: 72,
        idArticulo: "62745dfd95c45c183877d31c",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "D19",
        nombre: "D1915(21B)",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 119,
        valor: 8568,
        inventario: 119,
        idArticulo: "62745dfa95c45c183877d15f",
        precio: 85,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N122",
        nombre: "D1916(21B)",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 119,
        valor: 8568,
        inventario: 119,
        idArticulo: "628be7f195692838f4c58ada",
        precio: 85,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-150",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 84,
        valor: 8568,
        inventario: 84,
        idArticulo: "64134e224043fc001409b70e",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-153",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 84,
        valor: 8568,
        inventario: 84,
        idArticulo: "64134ec2bd849b0014e4d687",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-158",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 84,
        valor: 8568,
        inventario: 84,
        idArticulo: "64134f4a4043fc001409b785",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-157",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 84,
        valor: 8568,
        inventario: 84,
        idArticulo: "64134f3137f14e001485c70a",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2108",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 60,
        valor: 8567.999999999998,
        inventario: 60,
        idArticulo: "62745e0595c45c183877d694",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2104",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 60,
        valor: 8567.999999999998,
        inventario: 60,
        idArticulo: "62745e0595c45c183877d687",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "0024",
        nombre: "0024-2-2",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 8565.599999999999,
        inventario: 48,
        idArticulo: "634c556a8a10be00166f7855",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "GIRLZ",
        nombre: "GIRLZ-LE2105",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 110,
        valor: 8547,
        inventario: 110,
        idArticulo: "62745dfb95c45c183877d213",
        precio: 165,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 8532,
        inventario: 45,
        idArticulo: "62745dfa95c45c183877d195",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 8532,
        inventario: 45,
        idArticulo: "62745dfa95c45c183877d193",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "FELIX",
        nombre: "FELIX-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 38,
        valor: 8502.5,
        inventario: 38,
        idArticulo: "62745dfa95c45c183877d1cb",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KENT",
        nombre: "KENT-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 36,
        valor: 8499.6,
        inventario: 36,
        idArticulo: "62745dfe95c45c183877d380",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2113",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 71,
        valor: 8498.7,
        inventario: 71,
        idArticulo: "62745dfd95c45c183877d312",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 8473.05,
        inventario: 45,
        idArticulo: "62745e0395c45c183877d5c7",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 44,
        valor: 8470,
        inventario: 44,
        idArticulo: "62745e0295c45c183877d563",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 29,
        valor: 8439,
        inventario: 29,
        idArticulo: "62745dfc95c45c183877d276",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2101",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 52,
        valor: 8408.400000000001,
        inventario: 52,
        idArticulo: "62745dfc95c45c183877d2a0",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2108",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 52,
        valor: 8408.400000000001,
        inventario: 52,
        idArticulo: "62745dfc95c45c183877d2b4",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2102",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 52,
        valor: 8408.400000000001,
        inventario: 52,
        idArticulo: "62745dfc95c45c183877d2a2",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2104",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 52,
        valor: 8408.400000000001,
        inventario: 52,
        idArticulo: "62745dfc95c45c183877d2ac",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2306",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 96,
        valor: 8407.68,
        inventario: 96,
        idArticulo: "631129368c32950016f84b5d",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2306",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 96,
        valor: 8407.68,
        inventario: 96,
        idArticulo: "631129368c32950016f84b5d",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TANIA",
        nombre: "TANIA-TY2203",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 36,
        valor: 8406,
        inventario: 36,
        idArticulo: "62745e0995c45c183877d815",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "GIRLZ",
        nombre: "GIRLZ-LE2105",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 108,
        valor: 8391.6,
        inventario: 108,
        idArticulo: "62745dfb95c45c183877d213",
        precio: 165,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2207",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 54,
        valor: 8380.8,
        inventario: 54,
        idArticulo: "62745dfe95c45c183877d358",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2205",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 54,
        valor: 8380.8,
        inventario: 54,
        idArticulo: "62745dfe95c45c183877d354",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KISS",
        nombre: "KISS-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 72,
        valor: 8377.199999999999,
        inventario: 72,
        idArticulo: "629e9092f4d9d700160d9785",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OTIS",
        nombre: "OTIS-MG2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 46,
        valor: 8376.6,
        inventario: 46,
        idArticulo: "62745e0495c45c183877d5f2",
        precio: 265,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RHINO",
        nombre: "RHINO-DG2101",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 72,
        valor: 8374.32,
        inventario: 72,
        idArticulo: "62745e0695c45c183877d714",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "N077",
        nombre: "7301C",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 65,
        valor: 8353.800000000001,
        inventario: 65,
        idArticulo: "62745df595c45c183877cf27",
        precio: 160,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "LEVIS",
        nombre: "LEVIS-LE2304",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 48,
        valor: 8346.24,
        inventario: 48,
        idArticulo: "62c5b130883f0f0017d03200",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "CARRERA",
        nombre: "CARRERA-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 28,
        valor: 8316,
        inventario: 28,
        idArticulo: "62745df995c45c183877d0ef",
        precio: 465,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "PERU",
        nombre: "PERU-LW2204",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 60,
        valor: 8304,
        inventario: 60,
        idArticulo: "62745e0595c45c183877d655",
        precio: 130,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 44,
        valor: 8284.76,
        inventario: 44,
        idArticulo: "62745e0395c45c183877d5cc",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2112",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 58,
        valor: 8282.4,
        inventario: 58,
        idArticulo: "62745e0595c45c183877d6a2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 43,
        valor: 8277.5,
        inventario: 43,
        idArticulo: "62745e0295c45c183877d563",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BRADYBOY",
        nombre: "BRADYBOY-XT2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 63,
        valor: 8268.75,
        inventario: 63,
        idArticulo: "62745df895c45c183877d0ac",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2102",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 51,
        valor: 8246.7,
        inventario: 51,
        idArticulo: "62745dfc95c45c183877d2a2",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "VERSALLESPLUS",
        nombre: "VERSALLESPLUS-LW2203",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 55,
        valor: 8239,
        inventario: 55,
        idArticulo: "62745e0b95c45c183877d8c1",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VANE",
        nombre: "VANE-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 28,
        valor: 8184.400000000001,
        inventario: 28,
        idArticulo: "62745e0a95c45c183877d885",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "NEMO",
        nombre: "NEMO-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 43,
        valor: 8152.8,
        inventario: 43,
        idArticulo: "62745e0295c45c183877d525",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2116",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 68,
        valor: 8139.6,
        inventario: 68,
        idArticulo: "62745dfd95c45c183877d31c",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ZGL",
        nombre: "ZGL-LE2102",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 79,
        valor: 8129.1,
        inventario: 79,
        idArticulo: "62745e0b95c45c183877d8e5",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ALDOPLUS",
        nombre: "ALDOPLUS-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 35,
        valor: 8090.25,
        inventario: 35,
        idArticulo: "62745df695c45c183877cf8d",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2103",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 72,
        valor: 8089.2,
        inventario: 72,
        idArticulo: "62745df895c45c183877d099",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2104",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 72,
        valor: 8089.2,
        inventario: 72,
        idArticulo: "62745df895c45c183877d09b",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2108",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 72,
        valor: 8089.2,
        inventario: 72,
        idArticulo: "62745df895c45c183877d0a9",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "CLOE",
        nombre: "CLOE-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 48,
        valor: 8054.400000000001,
        inventario: 48,
        idArticulo: "62745df995c45c183877d127",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "VERSALLES",
        nombre: "VERSALLES-LW2204",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 60,
        valor: 8051.999999999999,
        inventario: 60,
        idArticulo: "62745e0b95c45c183877d8b1",
        precio: 125,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERSALLES",
        nombre: "VERSALLES-LW2203",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 60,
        valor: 8051.999999999999,
        inventario: 60,
        idArticulo: "62745e0b95c45c183877d8af",
        precio: 125,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TEO",
        nombre: "TEO-LE2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 8004,
        inventario: 48,
        idArticulo: "62745e0995c45c183877d813",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TEO",
        nombre: "TEO-LE2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 8004,
        inventario: 48,
        idArticulo: "62745e0995c45c183877d81d",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TEO",
        nombre: "TEO-LE2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 8004,
        inventario: 48,
        idArticulo: "62745e0995c45c183877d81d",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 8000.999999999999,
        inventario: 45,
        idArticulo: "62745df895c45c183877d08f",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 8000.999999999999,
        inventario: 45,
        idArticulo: "62745df895c45c183877d07e",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2305",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 96,
        valor: 7994.88,
        inventario: 96,
        idArticulo: "6311296c8c32950016f84bc4",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 7992,
        inventario: 48,
        idArticulo: "62745e0495c45c183877d602",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 7992,
        inventario: 48,
        idArticulo: "62745e0495c45c183877d604",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 7992,
        inventario: 48,
        idArticulo: "62745e0495c45c183877d604",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2105",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 7992,
        inventario: 48,
        idArticulo: "62745e0495c45c183877d60f",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SHAKIRA",
        nombre: "SHAKIRA-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 33,
        valor: 7981.05,
        inventario: 33,
        idArticulo: "62745e0895c45c183877d7ae",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 7980,
        inventario: 24,
        idArticulo: "62745e0795c45c183877d75a",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 7980,
        inventario: 24,
        idArticulo: "62745e0795c45c183877d756",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 72,
        valor: 7959.599999999999,
        inventario: 72,
        idArticulo: "62745e0395c45c183877d596",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIMPLUS",
        nombre: "KIMPLUS-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 50,
        valor: 7948.999999999999,
        inventario: 50,
        idArticulo: "62745dff95c45c183877d3ea",
        precio: 255,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PARIS",
        nombre: "PARIS-HN2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 7942.5,
        inventario: 45,
        idArticulo: "62745e0495c45c183877d623",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "PARIS",
        nombre: "PARIS-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 7942.5,
        inventario: 45,
        idArticulo: "62745e0495c45c183877d61b",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2208",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 30,
        valor: 7942.5,
        inventario: 30,
        idArticulo: "62745df595c45c183877cf51",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 7929.6,
        inventario: 48,
        idArticulo: "62745e0395c45c183877d570",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIM",
        nombre: "KIM-LE2204",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 54,
        valor: 7927.200000000001,
        inventario: 54,
        idArticulo: "62745dff95c45c183877d3e6",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PACO",
        nombre: "PACO-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 35,
        valor: 7896,
        inventario: 35,
        idArticulo: "62745e0495c45c183877d614",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TABO",
        nombre: "TABO-XT2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 36,
        valor: 7876.8,
        inventario: 36,
        idArticulo: "628be7f495692838f4c58ba0",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "SALOMON",
        nombre: "SALOMON-HN2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 7862.400000000001,
        inventario: 48,
        idArticulo: "628be7f395692838f4c58b7a",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "MARIO",
        nombre: "MARIO-XT2303",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 144,
        valor: 7856.64,
        inventario: 144,
        idArticulo: "62ed65369416cf0016d47fb8",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "MARIO",
        nombre: "MARIO-XT2302",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 144,
        valor: 7856.64,
        inventario: 144,
        idArticulo: "62ed65119416cf0016d47fa1",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 7852.5,
        inventario: 45,
        idArticulo: "62745e0695c45c183877d6cc",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MASSIMO",
        nombre: "MASSIMO-HN2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 39,
        valor: 7803.9,
        inventario: 39,
        idArticulo: "62745e0195c45c183877d4ba",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2107",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 69,
        valor: 7752.15,
        inventario: 69,
        idArticulo: "62745df895c45c183877d0a5",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 72,
        valor: 7732.8,
        inventario: 72,
        idArticulo: "62745e0395c45c183877d578",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 72,
        valor: 7732.8,
        inventario: 72,
        idArticulo: "62745e0395c45c183877d578",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "VIENA",
        nombre: "VIENA-HN2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 7721.999999999999,
        inventario: 30,
        idArticulo: "62745e0b95c45c183877d8d1",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "KENDALL",
        nombre: "KENDALL-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 72,
        valor: 7714.8,
        inventario: 72,
        idArticulo: "62745dfe95c45c183877d36c",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROBERTHOOD",
        nombre: "ROBERTHOOD-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 38,
        valor: 7714,
        inventario: 38,
        idArticulo: "62745e0795c45c183877d726",
        precio: 335,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RC26",
        nombre: "RC26FWGSET008",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 44,
        valor: 7695.6,
        inventario: 44,
        idArticulo: "628be7f395692838f4c58b56",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2127",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 47,
        valor: 7683.09,
        inventario: 47,
        idArticulo: "62745e0595c45c183877d6a8",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2302",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 7675.200000000001,
        inventario: 48,
        idArticulo: "63503bed6819510016f65231",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2302",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 7675.200000000001,
        inventario: 48,
        idArticulo: "63503bed6819510016f65231",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GV",
        nombre: "GV-1817",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 74,
        valor: 7659,
        inventario: 74,
        idArticulo: "628be7f295692838f4c58b1c",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ALDO",
        nombre: "ALDO-BL2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 36,
        valor: 7650,
        inventario: 36,
        idArticulo: "62745df595c45c183877cf81",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 71,
        valor: 7625.400000000001,
        inventario: 71,
        idArticulo: "62745e0395c45c183877d576",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N121",
        nombre: "CO95004(20A)",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 68,
        valor: 7609.200000000001,
        inventario: 68,
        idArticulo: "628be7f195692838f4c58ad6",
        precio: 140,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2020",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "LEO",
        nombre: "LEO-LE2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 7600.799999999999,
        inventario: 48,
        idArticulo: "62745e0095c45c183877d457",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TANIAPLUS",
        nombre: "TANIAPLUS-TY2203",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 29,
        valor: 7586.400000000001,
        inventario: 29,
        idArticulo: "62745e0995c45c183877d804",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-155",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 74,
        valor: 7548,
        inventario: 74,
        idArticulo: "64134effbd849b0014e4d6bd",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "PETER",
        nombre: "PETER-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 33,
        valor: 7540.5,
        inventario: 33,
        idArticulo: "62745e0595c45c183877d66b",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2211",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 43,
        valor: 7503.5,
        inventario: 43,
        idArticulo: "62745e0695c45c183877d6e8",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIKA",
        nombre: "NIKA-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 79,
        valor: 7489.2,
        inventario: 79,
        idArticulo: "62745e0395c45c183877d58b",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KOTY",
        nombre: "KOTY-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 44,
        valor: 7458,
        inventario: 44,
        idArticulo: "62745e0095c45c183877d404",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MAKONG",
        nombre: "MA2020KONG103(20A)",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 48,
        valor: 7449.599999999999,
        inventario: 48,
        idArticulo: "62745e0195c45c183877d4ae",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2020",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "CAPRI",
        nombre: "CAPRI-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 59,
        valor: 7434,
        inventario: 59,
        idArticulo: "62745df895c45c183877d0dc",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NONA",
        nombre: "NONA-MG2302",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 7428,
        inventario: 24,
        idArticulo: "638fc1654f9d8300162cde8c",
        precio: 420,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DL27",
        nombre: "DL27FWWCOT104",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 24,
        valor: 7392.960000000001,
        inventario: 24,
        idArticulo: "628be7f295692838f4c58b0e",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "FELIX",
        nombre: "FELIX-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 33,
        valor: 7383.75,
        inventario: 33,
        idArticulo: "62745dfb95c45c183877d1d5",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ATS",
        nombre: "ATS-153",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 72,
        valor: 7344,
        inventario: 72,
        idArticulo: "64134ec2bd849b0014e4d687",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-147",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 72,
        valor: 7344,
        inventario: 72,
        idArticulo: "64134db037f14e001485c681",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-151",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 72,
        valor: 7344,
        inventario: 72,
        idArticulo: "64134e484043fc001409b713",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-149",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 72,
        valor: 7344,
        inventario: 72,
        idArticulo: "64134df737f14e001485c696",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "POLAR",
        nombre: "POLAR-LR2003(20A)",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 7320,
        inventario: 24,
        idArticulo: "628be7f395692838f4c58b46",
        precio: 450,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2020",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEE",
        nombre: "LEE-LE2102",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 69,
        valor: 7317.45,
        inventario: 69,
        idArticulo: "62745e0095c45c183877d449",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "HELLO",
        nombre: "HELLO-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 54,
        valor: 7303.5,
        inventario: 54,
        idArticulo: "62745dfc95c45c183877d25a",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2306",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 7262.400000000001,
        inventario: 48,
        idArticulo: "63503cb66819510016f65316",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIGI",
        nombre: "GIGI-LE2303",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 7262.400000000001,
        inventario: 48,
        idArticulo: "63503c1fd03b310016e5f9e9",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2204",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 58,
        valor: 7250,
        inventario: 58,
        idArticulo: "62745e0295c45c183877d503",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERSALLES",
        nombre: "VERSALLES-LW2203",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 54,
        valor: 7246.799999999999,
        inventario: 54,
        idArticulo: "62745e0b95c45c183877d8af",
        precio: 125,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2101",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 73,
        valor: 7205.1,
        inventario: 73,
        idArticulo: "62745dfd95c45c183877d2d2",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYO",
        nombre: "TOKYO-LW2113",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 46,
        valor: 7201.3,
        inventario: 46,
        idArticulo: "62745e0995c45c183877d82f",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BHI21",
        nombre: "BHI21-0405",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 48,
        valor: 7200,
        inventario: 48,
        idArticulo: "62745df795c45c183877d02b",
        precio: 170,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2204",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 72,
        valor: 7192.799999999999,
        inventario: 72,
        idArticulo: "62745dfd95c45c183877d2f4",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERSALLESPLUS",
        nombre: "VERSALLESPLUS-LW2203",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 48,
        valor: 7190.400000000001,
        inventario: 48,
        idArticulo: "62745e0b95c45c183877d8c1",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "LOLA",
        nombre: "LOLA-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 38,
        valor: 7185.8,
        inventario: 38,
        idArticulo: "62745e0195c45c183877d489",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 42,
        valor: 7114.800000000001,
        inventario: 42,
        idArticulo: "62745e0a95c45c183877d858",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2301",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 7109.099999999999,
        inventario: 45,
        idArticulo: "62ed90b350fb0700166b351c",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2311",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 7109.099999999999,
        inventario: 45,
        idArticulo: "62ed92d050fb0700166b3740",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2101",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 72,
        valor: 7106.400000000001,
        inventario: 72,
        idArticulo: "62745dfd95c45c183877d2d2",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2102",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 72,
        valor: 7106.400000000001,
        inventario: 72,
        idArticulo: "62745dfd95c45c183877d2d4",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2105",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 72,
        valor: 7106.400000000001,
        inventario: 72,
        idArticulo: "62745dfd95c45c183877d2e2",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DNZM",
        nombre: "DNZM-LE2306C",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 59,
        valor: 7100.65,
        inventario: 59,
        idArticulo: "62ed76109416cf0016d49727",
        precio: 154,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "0024",
        nombre: "0024-5-1",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 35,
        valor: 7073.5,
        inventario: 35,
        idArticulo: "634c55678a10be00166f7852",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N032",
        nombre: "7291",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 44,
        valor: 7048.800000000001,
        inventario: 44,
        idArticulo: "62745df495c45c183877cea1",
        precio: 200,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NIKA",
        nombre: "NIKA-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 74,
        valor: 7015.2,
        inventario: 74,
        idArticulo: "62745e0395c45c183877d588",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2204",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 70,
        valor: 6992.999999999999,
        inventario: 70,
        idArticulo: "62745dfd95c45c183877d2f4",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SHELLY",
        nombre: "SHELLY-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 6972,
        inventario: 30,
        idArticulo: "62745e0895c45c183877d7b2",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ERVIN",
        nombre: "ERVIN-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 32,
        valor: 6966.4,
        inventario: 32,
        idArticulo: "62745dfa95c45c183877d1c3",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ERVIN",
        nombre: "ERVIN-MG2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 32,
        valor: 6966.4,
        inventario: 32,
        idArticulo: "62745dfa95c45c183877d1c5",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2109",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 51,
        valor: 6961.5,
        inventario: 51,
        idArticulo: "62745e0a95c45c183877d89d",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "DONNA",
        nombre: "DONNA-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 32,
        valor: 6950.4,
        inventario: 32,
        idArticulo: "62745dfa95c45c183877d18f",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "WARIO",
        nombre: "WARIO-XT2303",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 120,
        valor: 6934.8,
        inventario: 120,
        idArticulo: "62ed65cc9416cf0016d4804e",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "VERSALLESHOOD",
        nombre: "VERSALLESHOOD-LW2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 45,
        valor: 6930,
        inventario: 45,
        idArticulo: "62745e0a95c45c183877d8a9",
        precio: 135,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERSALLESHOOD",
        nombre: "VERSALLESHOOD-LW2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 45,
        valor: 6930,
        inventario: 45,
        idArticulo: "62745e0a95c45c183877d8a9",
        precio: 135,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 43,
        valor: 6918.7,
        inventario: 43,
        idArticulo: "62745dfe95c45c183877d38e",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "RENO",
        nombre: "RENO-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 39,
        valor: 6830.07,
        inventario: 39,
        idArticulo: "62745e0695c45c183877d702",
        precio: 255,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RC26",
        nombre: "RC26FWGSET010",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 39,
        valor: 6821.1,
        inventario: 39,
        idArticulo: "628be7f395692838f4c58b54",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "TANIAPLUS",
        nombre: "TANIAPLUS-TY2202",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 26,
        valor: 6801.6,
        inventario: 26,
        idArticulo: "62745e0995c45c183877d802",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N054",
        nombre: "1918(20B)",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 40,
        valor: 6800,
        inventario: 40,
        idArticulo: "62745df495c45c183877cedf",
        precio: 235,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2020",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NICOLE",
        nombre: "NICOLE-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 54,
        valor: 6750,
        inventario: 54,
        idArticulo: "62745e0295c45c183877d54f",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BIKERHOOD",
        nombre: "BIKERHOOD-MG2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 31,
        valor: 6748.7,
        inventario: 31,
        idArticulo: "62745df795c45c183877d035",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2302",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 6722.099999999999,
        inventario: 45,
        idArticulo: "62ed90f750fb0700166b35d0",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "FELIX",
        nombre: "FELIX-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 6712.5,
        inventario: 30,
        idArticulo: "62745dfa95c45c183877d1cb",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROBERTHOOD",
        nombre: "ROBERTHOOD-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 33,
        valor: 6699,
        inventario: 33,
        idArticulo: "62745e0795c45c183877d724",
        precio: 335,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OSLO",
        nombre: "OSLO-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 45,
        valor: 6672.15,
        inventario: 45,
        idArticulo: "62745e0495c45c183877d5eb",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2305",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 80,
        valor: 6662.4,
        inventario: 80,
        idArticulo: "6311296c8c32950016f84bc4",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MONACO",
        nombre: "MONACO-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 52,
        valor: 6661.2,
        inventario: 52,
        idArticulo: "62745e0195c45c183877d4de",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N100",
        nombre: "0504-4-3",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 6652.799999999999,
        inventario: 24,
        idArticulo: "628be7f195692838f4c58a8c",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "KILE",
        nombre: "KILE-LE2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 36,
        valor: 6636.599999999999,
        inventario: 36,
        idArticulo: "628be7f495692838f4c58bb2",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 35,
        valor: 6590.15,
        inventario: 35,
        idArticulo: "62745e0395c45c183877d5c9",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ZGL",
        nombre: "ZGL-LE2101",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 64,
        valor: 6585.6,
        inventario: 64,
        idArticulo: "62745e0b95c45c183877d8e3",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N071",
        nombre: "7287B",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 42,
        valor: 6577.2,
        inventario: 42,
        idArticulo: "62745df595c45c183877cf15",
        precio: 210,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "MOSCU",
        nombre: "MOSCU-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 39,
        valor: 6470.1,
        inventario: 39,
        idArticulo: "62745e0295c45c183877d4f8",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KILE",
        nombre: "KILE-LE2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 35,
        valor: 6452.25,
        inventario: 35,
        idArticulo: "628be7f495692838f4c58bb2",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KENDALL",
        nombre: "KENDALL-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 60,
        valor: 6429,
        inventario: 60,
        idArticulo: "62745dfe95c45c183877d376",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KENDALL",
        nombre: "KENDALL-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 60,
        valor: 6429,
        inventario: 60,
        idArticulo: "62745dfe95c45c183877d376",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MAYA",
        nombre: "MAYA-MG2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 40,
        valor: 6384,
        inventario: 40,
        idArticulo: "62745e0195c45c183877d4be",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MAYA",
        nombre: "MAYA-MG2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 40,
        valor: 6384,
        inventario: 40,
        idArticulo: "62745e0195c45c183877d4c2",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BORO",
        nombre: "BORO-XT2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 27,
        valor: 6374.7,
        inventario: 27,
        idArticulo: "62745df795c45c183877d056",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BRADYBOY",
        nombre: "BRADYBOY-XT2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 48,
        valor: 6300,
        inventario: 48,
        idArticulo: "62745df895c45c183877d0ae",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 31,
        valor: 6293,
        inventario: 31,
        idArticulo: "62745df795c45c183877d065",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JFK",
        nombre: "JFK-XT2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 6240,
        inventario: 30,
        idArticulo: "62745dfd95c45c183877d2c6",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "HELLO",
        nombre: "HELLO-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 46,
        valor: 6221.5,
        inventario: 46,
        idArticulo: "629e9144f4d9d700160d97ca",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PERUHOOD",
        nombre: "PERUHOOD-LW2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 40,
        valor: 6208.000000000001,
        inventario: 40,
        idArticulo: "62745e0495c45c183877d643",
        precio: 140,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2112",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 51,
        valor: 6104.7,
        inventario: 51,
        idArticulo: "62745dfd95c45c183877d314",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBERT",
        nombre: "ROBERT-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 6090,
        inventario: 30,
        idArticulo: "62745e0795c45c183877d72c",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 6090,
        inventario: 30,
        idArticulo: "62745df795c45c183877d05d",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2103",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 54,
        valor: 6066.9,
        inventario: 54,
        idArticulo: "62745df895c45c183877d099",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2106",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 54,
        valor: 6066.9,
        inventario: 54,
        idArticulo: "62745df895c45c183877d0a1",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2101",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 54,
        valor: 6066.9,
        inventario: 54,
        idArticulo: "628be7f495692838f4c58b9e",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "22#",
        nombre: "22#7975",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 42,
        valor: 6050.1,
        inventario: 42,
        idArticulo: "634c5761d74f160016d1a0d3",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SHELLY",
        nombre: "SHELLY-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 26,
        valor: 6042.400000000001,
        inventario: 26,
        idArticulo: "62745e0895c45c183877d7b2",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "RBRSEV",
        nombre: "RBRSEV-2203",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 52,
        valor: 6034.08,
        inventario: 52,
        idArticulo: "628be7f395692838f4c58b3e",
        precio: 6.36,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2203",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 48,
        valor: 6000,
        inventario: 48,
        idArticulo: "62745e0295c45c183877d505",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2112",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 42,
        valor: 5997.599999999999,
        inventario: 42,
        idArticulo: "62745e0595c45c183877d6a2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2104",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 36,
        valor: 5994,
        inventario: 36,
        idArticulo: "62745e0495c45c183877d609",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "VERSALLESPLUS",
        nombre: "VERSALLESPLUS-LW2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 40,
        valor: 5992,
        inventario: 40,
        idArticulo: "62745e0b95c45c183877d8bf",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "LOLAHOOD",
        nombre: "LOLAHOOD-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 5988,
        inventario: 30,
        idArticulo: "62745e0195c45c183877d483",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "CATAR",
        nombre: "CATAR-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 47,
        valor: 5971.349999999999,
        inventario: 47,
        idArticulo: "62745df995c45c183877d113",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 54,
        valor: 5969.7,
        inventario: 54,
        idArticulo: "62745e0395c45c183877d596",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 54,
        valor: 5969.7,
        inventario: 54,
        idArticulo: "62745e0395c45c183877d598",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKO",
        nombre: "NIKO-MG2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 40,
        valor: 5964,
        inventario: 40,
        idArticulo: "62745e0395c45c183877d5af",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 37,
        valor: 5953.3,
        inventario: 37,
        idArticulo: "62745dfe95c45c183877d38e",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "PERU",
        nombre: "PERU-LW2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 43,
        valor: 5951.2,
        inventario: 43,
        idArticulo: "62745e0595c45c183877d651",
        precio: 130,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBINHOOD",
        nombre: "ROBINHOOD-XT2203",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 51,
        valor: 5918.549999999999,
        inventario: 51,
        idArticulo: "62745e0795c45c183877d736",
        precio: 190,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "RBRSEV",
        nombre: "RBRSEV-2205",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 51,
        valor: 5918.04,
        inventario: 51,
        idArticulo: "62cf2dcafb6b4e00175ad7d3",
        precio: 6.36,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ROMA",
        nombre: "ROMA-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 29,
        valor: 5907.299999999999,
        inventario: 29,
        idArticulo: "62745e0795c45c183877d763",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KENT",
        nombre: "KENT-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 25,
        valor: 5902.5,
        inventario: 25,
        idArticulo: "62745dfe95c45c183877d37e",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2211",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 29,
        valor: 5887,
        inventario: 29,
        idArticulo: "62745df895c45c183877d073",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "COSMIC",
        nombre: "COSMIC-XT2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 5856,
        inventario: 24,
        idArticulo: "62745df995c45c183877d13d",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "OTIS",
        nombre: "OTIS-MG2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 32,
        valor: 5827.2,
        inventario: 32,
        idArticulo: "62745e0495c45c183877d5f7",
        precio: 265,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBIN",
        nombre: "ROBIN-XT2203",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 52,
        valor: 5816.2,
        inventario: 52,
        idArticulo: "62745e0795c45c183877d740",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 54,
        valor: 5799.6,
        inventario: 54,
        idArticulo: "62745e0395c45c183877d576",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2205",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 54,
        valor: 5799.6,
        inventario: 54,
        idArticulo: "62745e0395c45c183877d581",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 36,
        valor: 5792.400000000001,
        inventario: 36,
        idArticulo: "62745dff95c45c183877d3b6",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2205",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 36,
        valor: 5792.400000000001,
        inventario: 36,
        idArticulo: "62745dfe95c45c183877d392",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2208",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 36,
        valor: 5792.400000000001,
        inventario: 36,
        idArticulo: "62745dff95c45c183877d3a0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2208",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 36,
        valor: 5792.400000000001,
        inventario: 36,
        idArticulo: "62745dff95c45c183877d3c0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 36,
        valor: 5792.400000000001,
        inventario: 36,
        idArticulo: "62745dfe95c45c183877d386",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2206",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 36,
        valor: 5792.400000000001,
        inventario: 36,
        idArticulo: "62745dff95c45c183877d398",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KENDALL",
        nombre: "KENDALL-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 54,
        valor: 5786.1,
        inventario: 54,
        idArticulo: "62745dfe95c45c183877d36c",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MC",
        nombre: "MC02N",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 43,
        valor: 5778.125,
        inventario: 43,
        idArticulo: "632b3d65c3a7610016acafd4",
        precio: 212.66,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2102",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 48,
        valor: 5745.6,
        inventario: 48,
        idArticulo: "62745dfd95c45c183877d2fa",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2101",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 48,
        valor: 5745.6,
        inventario: 48,
        idArticulo: "62745dfd95c45c183877d2fc",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2105",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 48,
        valor: 5745.6,
        inventario: 48,
        idArticulo: "62745dfd95c45c183877d30a",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2112",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 48,
        valor: 5745.6,
        inventario: 48,
        idArticulo: "62745dfd95c45c183877d314",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2114",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 48,
        valor: 5745.6,
        inventario: 48,
        idArticulo: "62745dfd95c45c183877d31e",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2111",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 48,
        valor: 5745.6,
        inventario: 48,
        idArticulo: "62745dfd95c45c183877d310",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 5688,
        inventario: 30,
        idArticulo: "62745dfa95c45c183877d19f",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MARIO",
        nombre: "MARIO-XT2302",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 104,
        valor: 5674.24,
        inventario: 104,
        idArticulo: "62ed65119416cf0016d47fa1",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "PEACH",
        nombre: "PEACH-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 32,
        valor: 5657.92,
        inventario: 32,
        idArticulo: "62745e0495c45c183877d63a",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 5648.7,
        inventario: 30,
        idArticulo: "62745e0395c45c183877d5c9",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 5648.7,
        inventario: 30,
        idArticulo: "62745e0395c45c183877d5c7",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "DINO",
        nombre: "DINO-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 56,
        valor: 5644.8,
        inventario: 56,
        idArticulo: "62745dfa95c45c183877d173",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 35,
        valor: 5631.5,
        inventario: 35,
        idArticulo: "62745dfe95c45c183877d386",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2207",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 35,
        valor: 5631.5,
        inventario: 35,
        idArticulo: "62745dff95c45c183877d39c",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2101",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 47,
        valor: 5625.900000000001,
        inventario: 47,
        idArticulo: "62745dfd95c45c183877d2fc",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "22#",
        nombre: "22#0975",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 39,
        valor: 5617.950000000001,
        inventario: 39,
        idArticulo: "634c57648a10be00166f7894",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "INDI",
        nombre: "INDIGIRLS-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 25,
        valor: 5600,
        inventario: 25,
        idArticulo: "62c3483b27a4e10017c93505",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIRLZ",
        nombre: "GIRLZ-LE2102",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 72,
        valor: 5594.400000000001,
        inventario: 72,
        idArticulo: "62745dfb95c45c183877d20d",
        precio: 165,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 36,
        valor: 5587.2,
        inventario: 36,
        idArticulo: "62745dfe95c45c183877d34a",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2207",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 36,
        valor: 5587.2,
        inventario: 36,
        idArticulo: "62745dfe95c45c183877d358",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ALDOPLUS",
        nombre: "ALDOPLUS-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 5547.6,
        inventario: 24,
        idArticulo: "62745df695c45c183877cf8d",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "N025",
        nombre: "6400",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 66,
        valor: 5544,
        inventario: 66,
        idArticulo: "62745df395c45c183877ce7b",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 50,
        valor: 5527.5,
        inventario: 50,
        idArticulo: "62745e0395c45c183877d59a",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "USA",
        nombre: "USA-LR2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 35,
        valor: 5512.5,
        inventario: 35,
        idArticulo: "62745e0a95c45c183877d87c",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEMO",
        nombre: "NEMO-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 29,
        valor: 5498.4,
        inventario: 29,
        idArticulo: "62745e0295c45c183877d527",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2108",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 34,
        valor: 5497.8,
        inventario: 34,
        idArticulo: "62745dfc95c45c183877d2b4",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ALDOBOY",
        nombre: "ALDOBOY-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 5483.999999999999,
        inventario: 30,
        idArticulo: "62745df695c45c183877cf86",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "ALDOBOY",
        nombre: "ALDOBOY-BL2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 5483.999999999999,
        inventario: 30,
        idArticulo: "62745df595c45c183877cf7f",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 29,
        valor: 5460.41,
        inventario: 29,
        idArticulo: "62745e0395c45c183877d5cc",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "N022",
        nombre: "6384",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 65,
        valor: 5460,
        inventario: 65,
        idArticulo: "62745df395c45c183877ce6f",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "RENO",
        nombre: "RENO-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 31,
        valor: 5429.03,
        inventario: 31,
        idArticulo: "62745e0695c45c183877d702",
        precio: 255,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RS23",
        nombre: "RS23FWBPNT002",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 106,
        valor: 5406,
        inventario: 106,
        idArticulo: "62745e0895c45c183877d78a",
        precio: 35,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2017",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 5333.999999999999,
        inventario: 30,
        idArticulo: "62745df895c45c183877d08f",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 5333.999999999999,
        inventario: 30,
        idArticulo: "62745df895c45c183877d089",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2305",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 64,
        valor: 5329.92,
        inventario: 64,
        idArticulo: "6311296c8c32950016f84bc4",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2305",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 64,
        valor: 5329.92,
        inventario: 64,
        idArticulo: "6311296c8c32950016f84bc4",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 16,
        valor: 5320,
        inventario: 16,
        idArticulo: "62745e0795c45c183877d756",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "KIM",
        nombre: "KIM-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 36,
        valor: 5284.8,
        inventario: 36,
        idArticulo: "62745dff95c45c183877d3e0",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIM",
        nombre: "KIM-LE2204",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 36,
        valor: 5284.8,
        inventario: 36,
        idArticulo: "62745dff95c45c183877d3e6",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OTIS",
        nombre: "OTIS-MG2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 29,
        valor: 5280.9,
        inventario: 29,
        idArticulo: "62745e0495c45c183877d5f4",
        precio: 265,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N090",
        nombre: "6308",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 33,
        valor: 5266.8,
        inventario: 33,
        idArticulo: "628be7f095692838f4c58a80",
        precio: 245,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-HN2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 31,
        valor: 5251.400000000001,
        inventario: 31,
        idArticulo: "628be7f495692838f4c58bae",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "VERSALLESPLUS",
        nombre: "VERSALLESPLUS-LW2204",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 35,
        valor: 5243,
        inventario: 35,
        idArticulo: "62745e0b95c45c183877d8c3",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 5235,
        inventario: 30,
        idArticulo: "62745e0695c45c183877d6de",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SILVIA",
        nombre: "SILVIA-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 29,
        valor: 5209.85,
        inventario: 29,
        idArticulo: "62745e0895c45c183877d7cc",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "GIRLZ",
        nombre: "GIRLZ-LE2104",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 67,
        valor: 5205.900000000001,
        inventario: 67,
        idArticulo: "62745dfb95c45c183877d211",
        precio: 165,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ALISONKIDS",
        nombre: "ALISONKIDS-HN2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 32,
        valor: 5174.400000000001,
        inventario: 32,
        idArticulo: "62745df695c45c183877cfa7",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2107",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 46,
        valor: 5168.099999999999,
        inventario: 46,
        idArticulo: "62745df895c45c183877d0a5",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "COLIN",
        nombre: "COLIN-MG2104",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 39,
        valor: 5159.700000000001,
        inventario: 39,
        idArticulo: "62e40000dd6410001620e5d1",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "HGL",
        nombre: "HGL-LE2101",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 63,
        valor: 5159.7,
        inventario: 63,
        idArticulo: "62745dfc95c45c183877d27a",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 29,
        valor: 5156.2,
        inventario: 29,
        idArticulo: "62745df895c45c183877d08f",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIM",
        nombre: "KIM-LE2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 35,
        valor: 5138,
        inventario: 35,
        idArticulo: "62745dff95c45c183877d3e2",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ALDO",
        nombre: "ALDO-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 5100,
        inventario: 24,
        idArticulo: "62745df595c45c183877cf7d",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2203",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 51,
        valor: 5094.9,
        inventario: 51,
        idArticulo: "62745dfd95c45c183877d2f0",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KITY",
        nombre: "KITY-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 5085,
        inventario: 30,
        idArticulo: "62745dff95c45c183877d3f8",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2109",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 37,
        valor: 5050.5,
        inventario: 37,
        idArticulo: "62745e0a95c45c183877d89d",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "BIKER",
        nombre: "BIKER-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 4998,
        inventario: 24,
        idArticulo: "62745df795c45c183877d039",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BIKER",
        nombre: "BIKER-MG2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 4998,
        inventario: 24,
        idArticulo: "62745df795c45c183877d03d",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIM",
        nombre: "KIM-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 34,
        valor: 4991.200000000001,
        inventario: 34,
        idArticulo: "62745dff95c45c183877d3e0",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROBINHOOD",
        nombre: "ROBINHOOD-XT2201",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 43,
        valor: 4990.15,
        inventario: 43,
        idArticulo: "62745e0795c45c183877d732",
        precio: 190,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KIDZ",
        nombre: "KIDZ-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 29,
        valor: 4976.4,
        inventario: 29,
        idArticulo: "62745dff95c45c183877d3c9",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERSALLES",
        nombre: "VERSALLES-LW2204",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 37,
        valor: 4965.4,
        inventario: 37,
        idArticulo: "62745e0b95c45c183877d8b1",
        precio: 125,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ZGL",
        nombre: "ZGL-LE2102",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 48,
        valor: 4939.200000000001,
        inventario: 48,
        idArticulo: "62745e0b95c45c183877d8e5",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MONIQUEHOOD",
        nombre: "MONIQUEHOOD-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 31,
        valor: 4929,
        inventario: 31,
        idArticulo: "62745e0195c45c183877d4e4",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2104",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 36,
        valor: 4914,
        inventario: 36,
        idArticulo: "62745e0a95c45c183877d88e",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "ATS",
        nombre: "ATS-150",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 48,
        valor: 4896,
        inventario: 48,
        idArticulo: "64134e224043fc001409b70e",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-157",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 48,
        valor: 4896,
        inventario: 48,
        idArticulo: "64134f3137f14e001485c70a",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2213",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 28,
        valor: 4886,
        inventario: 28,
        idArticulo: "62745e0695c45c183877d6ec",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2112",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 4867.200000000001,
        inventario: 30,
        idArticulo: "628be7f395692838f4c58b84",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2109",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 34,
        valor: 4855.2,
        inventario: 34,
        idArticulo: "62745e0595c45c183877d696",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N058",
        nombre: "2912-9-4R",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 37,
        valor: 4824.0599999999995,
        inventario: 37,
        idArticulo: "62745df495c45c183877ceef",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2205",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 48,
        valor: 4795.2,
        inventario: 48,
        idArticulo: "62745dfd95c45c183877d2f6",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2301",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 4739.4,
        inventario: 30,
        idArticulo: "62ed90b350fb0700166b351c",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2311",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 4739.4,
        inventario: 30,
        idArticulo: "62ed92d050fb0700166b3740",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2103",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 48,
        valor: 4737.6,
        inventario: 48,
        idArticulo: "62745dfd95c45c183877d2d8",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2104",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 48,
        valor: 4737.6,
        inventario: 48,
        idArticulo: "62745dfd95c45c183877d2d6",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "FILIP",
        nombre: "FILIP-KV2203",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 24,
        valor: 4708.8,
        inventario: 24,
        idArticulo: "62745dfb95c45c183877d1ed",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "TOKYO",
        nombre: "TOKYO-LW2114",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 4696.5,
        inventario: 30,
        idArticulo: "62745e0995c45c183877d82d",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2206",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 47,
        valor: 4695.299999999999,
        inventario: 47,
        idArticulo: "62745dfd95c45c183877d2f8",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2204",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 30,
        valor: 4656,
        inventario: 30,
        idArticulo: "62745dfe95c45c183877d350",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2205",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 30,
        valor: 4656,
        inventario: 30,
        idArticulo: "62745dfe95c45c183877d354",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2107",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 34,
        valor: 4641,
        inventario: 34,
        idArticulo: "62745e0a95c45c183877d899",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2103",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 47,
        valor: 4638.900000000001,
        inventario: 47,
        idArticulo: "62745dfd95c45c183877d2d8",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N127",
        nombre: "7287CH",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 29,
        valor: 4619.700000000001,
        inventario: 29,
        idArticulo: "62e3fcf9dd6410001620e30e",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "BRADYBOY",
        nombre: "BRADYBOY-XT2105",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 35,
        valor: 4593.75,
        inventario: 35,
        idArticulo: "62745df895c45c183877d0b6",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2309",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 4545.9,
        inventario: 30,
        idArticulo: "62ed926450fb0700166b36e9",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2107",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 46,
        valor: 4540.2,
        inventario: 46,
        idArticulo: "62745dfd95c45c183877d2e4",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYO",
        nombre: "TOKYO-LW2117",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 29,
        valor: 4539.950000000001,
        inventario: 29,
        idArticulo: "62745e0995c45c183877d833",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2103",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 28,
        valor: 4527.6,
        inventario: 28,
        idArticulo: "62745dfc95c45c183877d2a6",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ISABEL",
        nombre: "ISABEL-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 25,
        valor: 4515,
        inventario: 25,
        idArticulo: "62745dfc95c45c183877d297",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N052",
        nombre: "0031-2-1",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 29,
        valor: 4506.6,
        inventario: 29,
        idArticulo: "62745df495c45c183877cedd",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "VERSALLESPLUS",
        nombre: "VERSALLESPLUS-LW2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 30,
        valor: 4494,
        inventario: 30,
        idArticulo: "62745e0b95c45c183877d8bd",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERSALLESPLUS",
        nombre: "VERSALLESPLUS-LW2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 30,
        valor: 4494,
        inventario: 30,
        idArticulo: "62745e0b95c45c183877d8bf",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DHL",
        nombre: "DHL-LE2102",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 45,
        valor: 4488.75,
        inventario: 45,
        idArticulo: "62745dfa95c45c183877d16b",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2313",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 30,
        valor: 4481.4,
        inventario: 30,
        idArticulo: "62ed933450fb0700166b37c2",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KATY",
        nombre: "KATY-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 4459.5,
        inventario: 15,
        idArticulo: "62745dfe95c45c183877d368",
        precio: 490,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "ALISONPLUS",
        nombre: "ALISONPLUS-HN2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 18,
        valor: 4410,
        inventario: 18,
        idArticulo: "62745df695c45c183877cfac",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "LEGOHOOD",
        nombre: "LEGOHOOD-MG2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 27,
        valor: 4403.7,
        inventario: 27,
        idArticulo: "628be7f395692838f4c58b8c",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2202",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 26,
        valor: 4399.200000000001,
        inventario: 26,
        idArticulo: "62745e0295c45c183877d537",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2204",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 26,
        valor: 4399.200000000001,
        inventario: 26,
        idArticulo: "62745e0295c45c183877d539",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "CM20",
        nombre: "CM-2013",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 21,
        valor: 4365.27,
        inventario: 21,
        idArticulo: "62745df995c45c183877d135",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 4365,
        inventario: 15,
        idArticulo: "62745dfc95c45c183877d26c",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "VERSALLESPLUS",
        nombre: "VERSALLESPLUS-LW2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 29,
        valor: 4344.200000000001,
        inventario: 29,
        idArticulo: "62745e0b95c45c183877d8bd",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ISABEL",
        nombre: "ISABEL-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 4334.4,
        inventario: 24,
        idArticulo: "62745dfc95c45c183877d297",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BARELAHOOD",
        nombre: "BARELAHOOD-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 20,
        valor: 4314,
        inventario: 20,
        idArticulo: "62745df795c45c183877d012",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2112",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 30,
        valor: 4283.999999999999,
        inventario: 30,
        idArticulo: "62745e0595c45c183877d6a2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2106",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 30,
        valor: 4283.999999999999,
        inventario: 30,
        idArticulo: "62745e0595c45c183877d690",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2108",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 30,
        valor: 4283.999999999999,
        inventario: 30,
        idArticulo: "62745e0595c45c183877d694",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 22,
        valor: 4235,
        inventario: 22,
        idArticulo: "62745e0295c45c183877d55d",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2108",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 26,
        valor: 4204.200000000001,
        inventario: 26,
        idArticulo: "62745dfc95c45c183877d2b4",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "VERSALLESPLUS",
        nombre: "VERSALLESPLUS-LW2204",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 28,
        valor: 4194.400000000001,
        inventario: 28,
        idArticulo: "62745e0b95c45c183877d8c3",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KISS",
        nombre: "KISS-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 36,
        valor: 4188.599999999999,
        inventario: 36,
        idArticulo: "62745dff95c45c183877d3f6",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 26,
        valor: 4183.400000000001,
        inventario: 26,
        idArticulo: "62745dfe95c45c183877d388",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "MC",
        nombre: "MC02F",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 31,
        valor: 4165.625,
        inventario: 31,
        idArticulo: "632b3d8cc3a7610016acafe1",
        precio: 212.66,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "CARRERA",
        nombre: "CARRERA-KV2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 14,
        valor: 4158,
        inventario: 14,
        idArticulo: "62745df995c45c183877d101",
        precio: 465,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "CARRERA",
        nombre: "CARRERA-KV2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 14,
        valor: 4158,
        inventario: 14,
        idArticulo: "62745df995c45c183877d103",
        precio: 465,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "LOREN",
        nombre: "LOREN-KV2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 36,
        valor: 4158,
        inventario: 36,
        idArticulo: "62745e0195c45c183877d48c",
        precio: 215,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2101",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 37,
        valor: 4156.95,
        inventario: 37,
        idArticulo: "628be7f495692838f4c58b9e",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROXY",
        nombre: "ROXY-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 20,
        valor: 4074,
        inventario: 20,
        idArticulo: "62745e0895c45c183877d780",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2210",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 14,
        valor: 4074,
        inventario: 14,
        idArticulo: "62745dfc95c45c183877d27f",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HG",
        nombre: "HG-ROMA2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 20,
        valor: 4073.9999999999995,
        inventario: 20,
        idArticulo: "62745dfc95c45c183877d27c",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2101",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 36,
        valor: 4044.6,
        inventario: 36,
        idArticulo: "628be7f495692838f4c58b9e",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2103",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 36,
        valor: 4044.6,
        inventario: 36,
        idArticulo: "62745df895c45c183877d099",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2104",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 25,
        valor: 4042.5000000000005,
        inventario: 25,
        idArticulo: "62745dfc95c45c183877d2ac",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 26,
        valor: 4035.2,
        inventario: 26,
        idArticulo: "62745dfe95c45c183877d348",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PARKER",
        nombre: "PARKER-XT2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 4032,
        inventario: 24,
        idArticulo: "62745e0495c45c183877d62e",
        precio: 375,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "VERSALLES",
        nombre: "VERSALLES-LW2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 30,
        valor: 4025.9999999999995,
        inventario: 30,
        idArticulo: "62745e0a95c45c183877d8ab",
        precio: 125,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RIKO",
        nombre: "RIKO-HN2204",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 25,
        valor: 3996.25,
        inventario: 25,
        idArticulo: "62745e0795c45c183877d722",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "N037",
        nombre: "7301",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 24,
        valor: 3996,
        inventario: 24,
        idArticulo: "62745df495c45c183877ceb1",
        precio: 210,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2104",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 3996,
        inventario: 24,
        idArticulo: "62745e0495c45c183877d609",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 12,
        valor: 3990,
        inventario: 12,
        idArticulo: "62745e0795c45c183877d756",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2204",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 15,
        valor: 3971.25,
        inventario: 15,
        idArticulo: "62745df595c45c183877cf4d",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2204",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 15,
        valor: 3971.25,
        inventario: 15,
        idArticulo: "62745df595c45c183877cf4d",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIM",
        nombre: "KIM-LE2204",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 27,
        valor: 3963.6000000000004,
        inventario: 27,
        idArticulo: "62745dff95c45c183877d3e6",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "CATAR",
        nombre: "CATAR-LW2106",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 31,
        valor: 3938.5499999999997,
        inventario: 31,
        idArticulo: "62745df995c45c183877d10f",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SALOMON",
        nombre: "SALOMON-HN2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 3931.2000000000003,
        inventario: 24,
        idArticulo: "628be7f395692838f4c58b7c",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "N103",
        nombre: "1910(20B)",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 23,
        valor: 3910,
        inventario: 23,
        idArticulo: "628be7f195692838f4c58a9a",
        precio: 235,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2020",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "TOM",
        nombre: "TOM-LE2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 3901.2,
        inventario: 24,
        idArticulo: "62745e0a95c45c183877d865",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIK",
        nombre: "NIK-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 38,
        valor: 3881.7000000000003,
        inventario: 38,
        idArticulo: "62745e0395c45c183877d5a8",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 36,
        valor: 3866.4,
        inventario: 36,
        idArticulo: "62745e0395c45c183877d576",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2205",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 36,
        valor: 3866.4,
        inventario: 36,
        idArticulo: "62745e0395c45c183877d581",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 36,
        valor: 3866.4,
        inventario: 36,
        idArticulo: "62745e0395c45c183877d583",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 36,
        valor: 3866.4,
        inventario: 36,
        idArticulo: "62745e0395c45c183877d57f",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 36,
        valor: 3866.4,
        inventario: 36,
        idArticulo: "62745e0395c45c183877d583",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "VIENA",
        nombre: "VIENA-HN2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 3860.9999999999995,
        inventario: 15,
        idArticulo: "62745e0b95c45c183877d8d1",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "CLOE",
        nombre: "CLOE-LE2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 23,
        valor: 3859.4,
        inventario: 23,
        idArticulo: "62745df995c45c183877d123",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 23,
        valor: 3829.5,
        inventario: 23,
        idArticulo: "62745e0495c45c183877d606",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "LUCABABY",
        nombre: "LUCABABY-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 26,
        valor: 3821.22,
        inventario: 26,
        idArticulo: "62745e0195c45c183877d495",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "BEBE",
        idFamilia: "62744a8b0b6417001680035a",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ANITA",
        nombre: "ANITA-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 20,
        valor: 3810,
        inventario: 20,
        idArticulo: "62745df695c45c183877cff0",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBIN",
        nombre: "ROBIN-XT2207",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 34,
        valor: 3802.8999999999996,
        inventario: 34,
        idArticulo: "62745e0795c45c183877d74a",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ISABEL",
        nombre: "ISABEL-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 21,
        valor: 3792.6,
        inventario: 21,
        idArticulo: "62745dfc95c45c183877d29c",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OLIVIAPLUS",
        nombre: "OLIVIAPLUS-HN2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 18,
        valor: 3780,
        inventario: 18,
        idArticulo: "62745e0395c45c183877d5c1",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "N025",
        nombre: "6400",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 45,
        valor: 3780,
        inventario: 45,
        idArticulo: "62745df395c45c183877ce7b",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "TOM",
        nombre: "TOM-LE2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 23,
        valor: 3738.6499999999996,
        inventario: 23,
        idArticulo: "62745e0a95c45c183877d865",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GV",
        nombre: "GV-1816",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 36,
        valor: 3726,
        inventario: 36,
        idArticulo: "628be7f295692838f4c58b18",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "PERUPLUS",
        nombre: "PERUPLUS-LW2203",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 24,
        valor: 3716.16,
        inventario: 24,
        idArticulo: "62745e0595c45c183877d65f",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2202",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 14,
        valor: 3706.5,
        inventario: 14,
        idArticulo: "62745df595c45c183877cf3f",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2206",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 14,
        valor: 3706.5,
        inventario: 14,
        idArticulo: "62745df595c45c183877cf47",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N117",
        nombre: "CH85012(20A)",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 20,
        valor: 3696,
        inventario: 20,
        idArticulo: "628be7f195692838f4c58ac6",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2020",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA568#401",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 16,
        valor: 3680,
        inventario: 16,
        idArticulo: "6308f072b511770016a3b078",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROLL",
        nombre: "ROLL-LR2001",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 21,
        valor: 3675,
        inventario: 21,
        idArticulo: "62e4011add6410001620e86c",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 21,
        valor: 3664.5,
        inventario: 21,
        idArticulo: "62745e0695c45c183877d6d8",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA-MG2203",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 16,
        valor: 3634.4,
        inventario: 16,
        idArticulo: "62745dfe95c45c183877d336",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "USA",
        nombre: "USA-LR2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 23,
        valor: 3622.5,
        inventario: 23,
        idArticulo: "62745e0a95c45c183877d87c",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DL2",
        nombre: "DL23FWMPNT025",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 180,
        valor: 3600,
        inventario: 180,
        idArticulo: "62745dfa95c45c183877d17f",
        precio: 30,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2205",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 36,
        valor: 3596.3999999999996,
        inventario: 36,
        idArticulo: "62745dfd95c45c183877d2f6",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 22,
        valor: 3539.8,
        inventario: 22,
        idArticulo: "62745dfe95c45c183877d388",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "N023",
        nombre: "6388",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 21,
        valor: 3517.71,
        inventario: 21,
        idArticulo: "62745df395c45c183877ce6d",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ZGL",
        nombre: "ZGL-LE2102",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 34,
        valor: 3498.6000000000004,
        inventario: 34,
        idArticulo: "62745e0b95c45c183877d8e5",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N035",
        nombre: "7297",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 21,
        valor: 3496.5,
        inventario: 21,
        idArticulo: "62745df495c45c183877cea7",
        precio: 210,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "GIRLZ",
        nombre: "GIRLZ-LE2106",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 45,
        valor: 3496.5,
        inventario: 45,
        idArticulo: "62745dfb95c45c183877d215",
        precio: 165,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2214",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 20,
        valor: 3490,
        inventario: 20,
        idArticulo: "62745e0695c45c183877d6ee",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DONNA",
        nombre: "DONNA-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 16,
        valor: 3475.2,
        inventario: 16,
        idArticulo: "62745dfa95c45c183877d18d",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 18,
        valor: 3465,
        inventario: 18,
        idArticulo: "62745e0295c45c183877d55f",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N069",
        nombre: "7280B",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 22,
        valor: 3445.2,
        inventario: 22,
        idArticulo: "62745df495c45c183877cf11",
        precio: 210,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "DIXIE",
        nombre: "DIXIE-MG2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 20,
        valor: 3423,
        inventario: 20,
        idArticulo: "62745dfa95c45c183877d16f",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DIXIE",
        nombre: "DIXIE-MG2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 20,
        valor: 3423,
        inventario: 20,
        idArticulo: "62745dfa95c45c183877d171",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 22,
        valor: 3414.3999999999996,
        inventario: 22,
        idArticulo: "62745dfe95c45c183877d348",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OREGON",
        nombre: "OREGON-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 23,
        valor: 3395.72,
        inventario: 23,
        idArticulo: "62745e0395c45c183877d5d4",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OREGON",
        nombre: "OREGON-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 23,
        valor: 3395.72,
        inventario: 23,
        idArticulo: "62745e0495c45c183877d5d9",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N080",
        nombre: "7304CH",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 25,
        valor: 3392.4999999999995,
        inventario: 25,
        idArticulo: "62745df595c45c183877cf31",
        precio: 130,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-HN2211",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 20,
        valor: 3388,
        inventario: 20,
        idArticulo: "63226d88283a510016948a83",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2205",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 21,
        valor: 3378.9,
        inventario: 21,
        idArticulo: "62745dfe95c45c183877d392",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KYOTORIB",
        nombre: "KYOTORIB-LW2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 18,
        valor: 3367.7999999999997,
        inventario: 18,
        idArticulo: "62745e0095c45c183877d42f",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RIKO",
        nombre: "RIKO-HN2203",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 21,
        valor: 3356.85,
        inventario: 21,
        idArticulo: "62745e0795c45c183877d720",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "FELIX",
        nombre: "FELIX-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 3356.25,
        inventario: 15,
        idArticulo: "62745dfb95c45c183877d1cd",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N040",
        nombre: "7340",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 26,
        valor: 3341.5200000000004,
        inventario: 26,
        idArticulo: "62745df495c45c183877ceb9",
        precio: 210,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NIKA",
        nombre: "NIKA-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 35,
        valor: 3318,
        inventario: 35,
        idArticulo: "62745e0395c45c183877d58b",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 19,
        valor: 3315.5,
        inventario: 19,
        idArticulo: "62745e0695c45c183877d6da",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2205",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 33,
        valor: 3296.7,
        inventario: 33,
        idArticulo: "62745dfd95c45c183877d2f6",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CARRERA",
        nombre: "CARRERA-KV2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 11,
        valor: 3267,
        inventario: 11,
        idArticulo: "62745df995c45c183877d0ff",
        precio: 465,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "N088",
        nombre: "6123",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 21,
        valor: 3263.4,
        inventario: 21,
        idArticulo: "628be7f095692838f4c58a76",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SHELLY",
        nombre: "SHELLY-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 14,
        valor: 3253.6,
        inventario: 14,
        idArticulo: "631627b7cbb5560016284d76",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 19,
        valor: 3218.6000000000004,
        inventario: 19,
        idArticulo: "62745e0a95c45c183877d85d",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N065",
        nombre: "647AUSVEBE516",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 63,
        valor: 3213,
        inventario: 63,
        idArticulo: "62745df495c45c183877cf03",
        precio: 60,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 29,
        valor: 3205.95,
        inventario: 29,
        idArticulo: "62745e0395c45c183877d598",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PEACHHOOD",
        nombre: "PEACHHOOD-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 17,
        valor: 3197.02,
        inventario: 17,
        idArticulo: "62745e0495c45c183877d640",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2204",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 32,
        valor: 3196.7999999999997,
        inventario: 32,
        idArticulo: "62745dfd95c45c183877d2f4",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MAYA",
        nombre: "MAYA-MG2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 20,
        valor: 3192,
        inventario: 20,
        idArticulo: "62745e0195c45c183877d4c2",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MAYA",
        nombre: "MAYA-MG2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 20,
        valor: 3192,
        inventario: 20,
        idArticulo: "62745e0195c45c183877d4c0",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PERU",
        nombre: "PERU-LW2203",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 23,
        valor: 3183.2000000000003,
        inventario: 23,
        idArticulo: "62745e0595c45c183877d64d",
        precio: 130,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CATAR",
        nombre: "CATAR-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 25,
        valor: 3176.25,
        inventario: 25,
        idArticulo: "62745df995c45c183877d115",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BRADYBOY",
        nombre: "BRADYBOY-XT2105",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 3150,
        inventario: 24,
        idArticulo: "62745df895c45c183877d0b6",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "N056",
        nombre: "1977-1-1R",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 18,
        valor: 3136.3200000000006,
        inventario: 18,
        idArticulo: "62745df495c45c183877cee9",
        precio: 285,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JFK",
        nombre: "JFK-XT2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 3120,
        inventario: 15,
        idArticulo: "62745dfd95c45c183877d2c6",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "JFK",
        nombre: "JFK-XT2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 3120,
        inventario: 15,
        idArticulo: "62745dfd95c45c183877d2d0",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "CM20",
        nombre: "CM-2017",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 15,
        valor: 3118.05,
        inventario: 15,
        idArticulo: "62745df995c45c183877d137",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2111",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 26,
        valor: 3112.2000000000003,
        inventario: 26,
        idArticulo: "62745dfd95c45c183877d310",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SIENAHOOD",
        nombre: "SIENAHOOD-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 3096,
        inventario: 15,
        idArticulo: "62745e0895c45c183877d7ba",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SIENAHOOD",
        nombre: "SIENAHOOD-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 3096,
        inventario: 15,
        idArticulo: "62745e0895c45c183877d7c4",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2101",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 19,
        valor: 3072.3,
        inventario: 19,
        idArticulo: "62745dfc95c45c183877d2a0",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2207",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 19,
        valor: 3057.1,
        inventario: 19,
        idArticulo: "62745dff95c45c183877d39c",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "ROBERT",
        nombre: "ROBERT-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 3045,
        inventario: 15,
        idArticulo: "62745e0795c45c183877d72c",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOLIVIA",
        nombre: "BOLIVIA-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 3024,
        inventario: 24,
        idArticulo: "62745df795c45c183877d04b",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N022",
        nombre: "6384",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 36,
        valor: 3024,
        inventario: 36,
        idArticulo: "62745df395c45c183877ce6f",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N107",
        nombre: "2912-10-4R",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 24,
        valor: 3024,
        inventario: 24,
        idArticulo: "628be7f195692838f4c58aa4",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ROBINHOOD",
        nombre: "ROBINHOOD-XT2201",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 26,
        valor: 3017.2999999999997,
        inventario: 26,
        idArticulo: "62745e0795c45c183877d732",
        precio: 190,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KYOTORIB",
        nombre: "KYOTORIB-LW2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 16,
        valor: 2993.6,
        inventario: 16,
        idArticulo: "62745e0095c45c183877d42d",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2117",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 25,
        valor: 2992.5,
        inventario: 25,
        idArticulo: "62745dfd95c45c183877d322",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N05",
        nombre: "2420",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 18,
        valor: 2977.38,
        inventario: 18,
        idArticulo: "62745df395c45c183877ce7f",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "HGL",
        nombre: "HGL-LE2102",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 36,
        valor: 2948.4,
        inventario: 36,
        idArticulo: "628be7f295692838f4c58b22",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PACO",
        nombre: "PACO-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 13,
        valor: 2932.7999999999997,
        inventario: 13,
        idArticulo: "62745e0495c45c183877d614",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BIKER",
        nombre: "BIKER-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 14,
        valor: 2915.5,
        inventario: 14,
        idArticulo: "62745df795c45c183877d037",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PIAMONTE",
        nombre: "PIAMONTE-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 19,
        valor: 2912.7,
        inventario: 19,
        idArticulo: "62745e0595c45c183877d675",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBIN",
        nombre: "ROBIN-XT2205",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 26,
        valor: 2908.1,
        inventario: 26,
        idArticulo: "62745e0795c45c183877d748",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 18,
        valor: 2896.2000000000003,
        inventario: 18,
        idArticulo: "62745dff95c45c183877d3b0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 2887.5,
        inventario: 15,
        idArticulo: "62745e0295c45c183877d563",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 2887.5,
        inventario: 15,
        idArticulo: "62745e0295c45c183877d561",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 2887.5,
        inventario: 15,
        idArticulo: "62cee609fb6b4e00175aa0b2",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "22#",
        nombre: "22#0975",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 20,
        valor: 2881,
        inventario: 20,
        idArticulo: "634c57648a10be00166f7894",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "22#",
        nombre: "22#0979",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 20,
        valor: 2881,
        inventario: 20,
        idArticulo: "634c575ed74f160016d1a0ce",
        precio: 265,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RIKO",
        nombre: "RIKO-HN2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 18,
        valor: 2877.2999999999997,
        inventario: 18,
        idArticulo: "62745e0695c45c183877d717",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2113",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 24,
        valor: 2872.8,
        inventario: 24,
        idArticulo: "62745dfd95c45c183877d312",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2113",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 24,
        valor: 2872.8,
        inventario: 24,
        idArticulo: "62745dfd95c45c183877d312",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CLOE",
        nombre: "CLOE-LE2204",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 17,
        valor: 2852.6000000000004,
        inventario: 17,
        idArticulo: "62745df995c45c183877d129",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2210",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 16,
        valor: 2844.7999999999997,
        inventario: 16,
        idArticulo: "62745df895c45c183877d094",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 2844,
        inventario: 15,
        idArticulo: "62745dfa95c45c183877d19f",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROBERT",
        nombre: "ROBERT-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 14,
        valor: 2842,
        inventario: 14,
        idArticulo: "62745e0795c45c183877d728",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "LOLA",
        nombre: "LOLA-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 2836.5,
        inventario: 15,
        idArticulo: "62745e0195c45c183877d487",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "RC26",
        nombre: "RC26FWGSET007",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 16,
        valor: 2798.4,
        inventario: 16,
        idArticulo: "628be7f395692838f4c58b52",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "OREGON",
        nombre: "MH-OREGONLW2001B(20A)",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 19,
        valor: 2793,
        inventario: 19,
        idArticulo: "628be7f295692838f4c58b30",
        precio: 175,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2020",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROCKY",
        nombre: "ROCKY-LR2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 9,
        valor: 2772,
        inventario: 9,
        idArticulo: "62c3489527a4e10017c9351f",
        precio: 450,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BRADYBOY",
        nombre: "BRADYBOY-XT2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 21,
        valor: 2756.25,
        inventario: 21,
        idArticulo: "62745df895c45c183877d0ae",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2115",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 23,
        valor: 2753.1,
        inventario: 23,
        idArticulo: "62745dfd95c45c183877d320",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2125B",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 16,
        valor: 2744,
        inventario: 16,
        idArticulo: "62745e0595c45c183877d6a6",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ALDOBOY",
        nombre: "ALDOBOY-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 2741.9999999999995,
        inventario: 15,
        idArticulo: "62745df695c45c183877cf86",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA-MG2202",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 12,
        valor: 2725.8,
        inventario: 12,
        idArticulo: "62745dfe95c45c183877d332",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 2666.9999999999995,
        inventario: 15,
        idArticulo: "62745df895c45c183877d07e",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N010",
        nombre: "6323",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 16,
        valor: 2646.56,
        inventario: 16,
        idArticulo: "62745df395c45c183877ce8d",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 16,
        valor: 2643.2,
        inventario: 16,
        idArticulo: "62cee5b0fb6b4e00175aa0a5",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2102",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 22,
        valor: 2633.4,
        inventario: 22,
        idArticulo: "62745dfd95c45c183877d2fa",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N072",
        nombre: "7292C",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 20,
        valor: 2612.4,
        inventario: 20,
        idArticulo: "62745df595c45c183877cf13",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "MJ",
        nombre: "MJ-02",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 18,
        valor: 2595.6,
        inventario: 18,
        idArticulo: "62745e0195c45c183877d4d2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RS90",
        nombre: "RS9007L",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 13,
        valor: 2579.98,
        inventario: 13,
        idArticulo: "62745e0895c45c183877d7a8",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "REX",
        nombre: "REX-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 2574,
        inventario: 15,
        idArticulo: "62745e0695c45c183877d712",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SHELLYPLUS",
        nombre: "SHELLYPLUS-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 10,
        valor: 2571.3999999999996,
        inventario: 10,
        idArticulo: "62745e0895c45c183877d7b8",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N040",
        nombre: "7340",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 20,
        valor: 2570.4,
        inventario: 20,
        idArticulo: "62745df495c45c183877ceb9",
        precio: 210,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "MC",
        nombre: "MC02P",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 19,
        valor: 2553.125,
        inventario: 19,
        idArticulo: "632b3de5c3a7610016acb063",
        precio: 212.66,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "ROX",
        nombre: "ROX-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 2542.5,
        inventario: 15,
        idArticulo: "62745e0795c45c183877d77a",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KOTY",
        nombre: "KOTY-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 2542.5,
        inventario: 15,
        idArticulo: "62745e0095c45c183877d404",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N078",
        nombre: "7301CH",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 19,
        valor: 2538.4,
        inventario: 19,
        idArticulo: "62745df595c45c183877cf25",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2101",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 21,
        valor: 2513.7000000000003,
        inventario: 21,
        idArticulo: "62745dfd95c45c183877d2fc",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "GIRLZ",
        nombre: "GIRLZ-LE2102",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 32,
        valor: 2486.4,
        inventario: 32,
        idArticulo: "62745dfb95c45c183877d20d",
        precio: 165,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N073",
        nombre: "7297C",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 21,
        valor: 2469.6,
        inventario: 21,
        idArticulo: "62745df595c45c183877cf1b",
        precio: 210,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-144",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 24,
        valor: 2448,
        inventario: 24,
        idArticulo: "64134bcdbd849b0014e4d5ef",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-147",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 24,
        valor: 2448,
        inventario: 24,
        idArticulo: "64134db037f14e001485c681",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-146",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 24,
        valor: 2448,
        inventario: 24,
        idArticulo: "64134d7737f14e001485c661",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ATS",
        nombre: "ATS-155",
        linea: "PLAYERA",
        idLinea: "627449f60b641700168002d9",
        disponible: 24,
        valor: 2448,
        inventario: 24,
        idArticulo: "64134effbd849b0014e4d6bd",
        precio: 125,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "0024",
        nombre: "0024-5-1",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 12,
        valor: 2425.2,
        inventario: 12,
        idArticulo: "634c55678a10be00166f7852",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIDZ",
        nombre: "KIDZ-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 14,
        valor: 2402.4,
        inventario: 14,
        idArticulo: "62745dff95c45c183877d3c7",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2202",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 24,
        valor: 2397.6,
        inventario: 24,
        idArticulo: "62745dfd95c45c183877d2e8",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2204",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 24,
        valor: 2397.6,
        inventario: 24,
        idArticulo: "62745dfd95c45c183877d2f4",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "HABANA",
        nombre: "HABANA-LW2107",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 2394,
        inventario: 15,
        idArticulo: "62a756ee9dc78b00164af402",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2307",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 2369.7,
        inventario: 15,
        idArticulo: "62ed920b50fb0700166b367c",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2311",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 2369.7,
        inventario: 15,
        idArticulo: "62ed92d050fb0700166b3740",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2106",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 24,
        valor: 2368.8,
        inventario: 24,
        idArticulo: "62745dfd95c45c183877d2e0",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "FILIP",
        nombre: "FILIP-KV2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 12,
        valor: 2354.4,
        inventario: 12,
        idArticulo: "62745dfb95c45c183877d1e7",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "TOKYO",
        nombre: "TOKYO-LW2114",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 2348.25,
        inventario: 15,
        idArticulo: "62745e0995c45c183877d82d",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2107",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 17,
        valor: 2320.5,
        inventario: 17,
        idArticulo: "62745e0a95c45c183877d899",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "N026",
        nombre: "6405",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 17,
        valor: 2312.17,
        inventario: 17,
        idArticulo: "62745df395c45c183877ce8f",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "TURIN",
        nombre: "TURIN-DG2101",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 19,
        valor: 2274.3,
        inventario: 19,
        idArticulo: "62745e0a95c45c183877d86a",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "RS23",
        nombre: "RS23FWBPNT002",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 44,
        valor: 2244,
        inventario: 44,
        idArticulo: "62745e0895c45c183877d78a",
        precio: 35,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "FELIX",
        nombre: "FELIX-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 10,
        valor: 2237.5,
        inventario: 10,
        idArticulo: "62745dfa95c45c183877d1c7",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 11,
        valor: 2233,
        inventario: 11,
        idArticulo: "62745df895c45c183877d06f",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "0024",
        nombre: "0024-5-1",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 11,
        valor: 2223.1,
        inventario: 11,
        idArticulo: "634c55678a10be00166f7852",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OREGON",
        nombre: "OREGON-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 2214.6,
        inventario: 15,
        idArticulo: "62745e0395c45c183877d5d4",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OREGON",
        nombre: "OREGON-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 2214.6,
        inventario: 15,
        idArticulo: "62745e0495c45c183877d5db",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OREGON",
        nombre: "OREGON-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 2214.6,
        inventario: 15,
        idArticulo: "62745e0495c45c183877d5d9",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2206",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 22,
        valor: 2197.7999999999997,
        inventario: 22,
        idArticulo: "62745dfd95c45c183877d2f8",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2108",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 16,
        valor: 2184,
        inventario: 16,
        idArticulo: "62745e0a95c45c183877d89b",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "N020",
        nombre: "6375",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 26,
        valor: 2184,
        inventario: 26,
        idArticulo: "62745df395c45c183877ce6b",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ISABEL",
        nombre: "ISABEL-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 12,
        valor: 2167.2,
        inventario: 12,
        idArticulo: "62745dfc95c45c183877d29c",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ISABEL",
        nombre: "ISABEL-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 12,
        valor: 2167.2,
        inventario: 12,
        idArticulo: "62745dfc95c45c183877d29a",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MJ",
        nombre: "MJ-04",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 15,
        valor: 2163,
        inventario: 15,
        idArticulo: "62745e0195c45c183877d4d6",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CAPRI",
        nombre: "CAPRI-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 17,
        valor: 2142,
        inventario: 17,
        idArticulo: "62745df995c45c183877d0e1",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2107",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 15,
        valor: 2141.9999999999995,
        inventario: 15,
        idArticulo: "62745e0595c45c183877d692",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JACK",
        nombre: "JACK-LE2303",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 14,
        valor: 2121.42,
        inventario: 14,
        idArticulo: "62ed913550fb0700166b35f4",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N129",
        nombre: "7094CH",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 11,
        valor: 2096.05,
        inventario: 11,
        idArticulo: "62e3fccfdd6410001620e2a4",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2212",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 12,
        valor: 2094,
        inventario: 12,
        idArticulo: "62745e0695c45c183877d6ea",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 11,
        valor: 2085.6,
        inventario: 11,
        idArticulo: "62745dfa95c45c183877d193",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ALDOPLUS",
        nombre: "ALDOPLUS-BL2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 9,
        valor: 2080.3500000000004,
        inventario: 9,
        idArticulo: "62745df695c45c183877cf88",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KOTY",
        nombre: "KOTY-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 12,
        valor: 2034,
        inventario: 12,
        idArticulo: "62745e0095c45c183877d404",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2107",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 18,
        valor: 2022.3,
        inventario: 18,
        idArticulo: "62745df895c45c183877d0a5",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2108",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 18,
        valor: 2022.3,
        inventario: 18,
        idArticulo: "62745df895c45c183877d0a9",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "VERSALLES",
        nombre: "VERSALLES-LW2204",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 15,
        valor: 2012.9999999999998,
        inventario: 15,
        idArticulo: "62745e0b95c45c183877d8b1",
        precio: 125,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 18,
        valor: 1989.9,
        inventario: 18,
        idArticulo: "62745e0395c45c183877d594",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "RS27",
        nombre: "RS27FWMJCT034",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 6,
        valor: 1988.1,
        inventario: 6,
        idArticulo: "628be7f395692838f4c58b6a",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "1915",
        nombre: "1915-4-2D",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 7,
        valor: 1974,
        inventario: 7,
        idArticulo: "632cb8de519b4700162c2005",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 1965.6,
        inventario: 15,
        idArticulo: "62745dfe95c45c183877d32e",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROSY",
        nombre: "ROSY-TY2202",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 7,
        valor: 1945.2999999999997,
        inventario: 7,
        idArticulo: "62745e0795c45c183877d776",
        precio: 480,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "GIO",
        nombre: "GIO-KV2201D",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 12,
        valor: 1941.6,
        inventario: 12,
        idArticulo: "62745dfb95c45c183877d203",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 18,
        valor: 1933.2,
        inventario: 18,
        idArticulo: "62745e0395c45c183877d57f",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2205",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 18,
        valor: 1933.2,
        inventario: 18,
        idArticulo: "62745e0395c45c183877d581",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N020",
        nombre: "6375",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 23,
        valor: 1932,
        inventario: 23,
        idArticulo: "62745df395c45c183877ce6b",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "RENO",
        nombre: "RENO-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 11,
        valor: 1926.4299999999998,
        inventario: 11,
        idArticulo: "62745e0695c45c183877d704",
        precio: 255,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 11,
        valor: 1919.5,
        inventario: 11,
        idArticulo: "62745e0695c45c183877d6de",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2106",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 17,
        valor: 1909.9499999999998,
        inventario: 17,
        idArticulo: "62745df895c45c183877d0a1",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2106",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 13,
        valor: 1856.4,
        inventario: 13,
        idArticulo: "62745e0595c45c183877d690",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N114",
        nombre: "A8-86",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 10,
        valor: 1839.6,
        inventario: 10,
        idArticulo: "628be7f195692838f4c58ac8",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "2990",
        nombre: "2990-2-4D",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 11,
        valor: 1826.88,
        inventario: 11,
        idArticulo: "63f5651d685e130014b5e52c",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N093",
        nombre: "6427",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 12,
        valor: 1814.4,
        inventario: 12,
        idArticulo: "628be7f195692838f4c58a88",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2103",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 16,
        valor: 1797.6,
        inventario: 16,
        idArticulo: "62745df895c45c183877d099",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2114",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 15,
        valor: 1795.5,
        inventario: 15,
        idArticulo: "62745dfd95c45c183877d31e",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TURIN",
        nombre: "TURIN-DG2105",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 15,
        valor: 1795.5,
        inventario: 15,
        idArticulo: "62745e0a95c45c183877d877",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "GABO",
        nombre: "GABO-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 8,
        valor: 1794.4,
        inventario: 8,
        idArticulo: "62745dfb95c45c183877d1f9",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "MONACO",
        nombre: "MONACO-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 14,
        valor: 1793.4,
        inventario: 14,
        idArticulo: "62745e0195c45c183877d4e0",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 10,
        valor: 1778,
        inventario: 10,
        idArticulo: "62745df895c45c183877d082",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2208",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 11,
        valor: 1769.9,
        inventario: 11,
        idArticulo: "62745dff95c45c183877d3a0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "CRCM",
        nombre: "CRCM-118",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 8,
        valor: 1747.2,
        inventario: 8,
        idArticulo: "628be7f295692838f4c58b06",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "CRCD",
        nombre: "CRCD-014",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 6,
        valor: 1746,
        inventario: 6,
        idArticulo: "62745df995c45c183877d145",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIKA",
        nombre: "NIKA-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 18,
        valor: 1706.4,
        inventario: 18,
        idArticulo: "62745e0395c45c183877d586",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ELZA",
        nombre: "ELZA-LW2112",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 17,
        valor: 1702.89,
        inventario: 17,
        idArticulo: "62745dfa95c45c183877d1a7",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 9,
        valor: 1694.61,
        inventario: 9,
        idArticulo: "62745e0395c45c183877d5c9",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 10,
        valor: 1694,
        inventario: 10,
        idArticulo: "62745e0a95c45c183877d852",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BIKER",
        nombre: "BIKER-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 8,
        valor: 1666,
        inventario: 8,
        idArticulo: "62745df795c45c183877d037",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N108",
        nombre: "2925-3-7R",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 7,
        valor: 1646.4,
        inventario: 7,
        idArticulo: "628be7f195692838f4c58aac",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2103",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 12,
        valor: 1638,
        inventario: 12,
        idArticulo: "62745e0a95c45c183877d88b",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "CAPRI",
        nombre: "CAPRI-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 13,
        valor: 1638,
        inventario: 13,
        idArticulo: "62a755209dc78b00164af13a",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ISABEL",
        nombre: "ISABEL-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 9,
        valor: 1625.4,
        inventario: 9,
        idArticulo: "62745dfc95c45c183877d29a",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2204",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 10,
        valor: 1609,
        inventario: 10,
        idArticulo: "62745dfe95c45c183877d390",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 10,
        valor: 1609,
        inventario: 10,
        idArticulo: "62745dff95c45c183877d3b6",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 9,
        valor: 1600.1999999999998,
        inventario: 9,
        idArticulo: "62745df895c45c183877d085",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ALISON",
        nombre: "ALISON-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 9,
        valor: 1587.6,
        inventario: 9,
        idArticulo: "62745df695c45c183877cfa5",
        precio: 400,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "GABO",
        nombre: "GABO-KV2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 7,
        valor: 1570.1,
        inventario: 7,
        idArticulo: "62745dfb95c45c183877d1ff",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "ROBIN",
        nombre: "ROBIN-XT2206",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 14,
        valor: 1565.9,
        inventario: 14,
        idArticulo: "62745e0795c45c183877d746",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2303",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 15,
        valor: 1555.5,
        inventario: 15,
        idArticulo: "631128a68c32950016f84a4a",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROCKY",
        nombre: "ROCKY-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 1540,
        inventario: 5,
        idArticulo: "62745e0795c45c183877d75d",
        precio: 450,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "CATAR",
        nombre: "CATAR-LW2105",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 12,
        valor: 1524.6,
        inventario: 12,
        idArticulo: "62745df995c45c183877d111",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ELZA",
        nombre: "ELZA-LW2106",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 15,
        valor: 1502.5499999999997,
        inventario: 15,
        idArticulo: "62745dfa95c45c183877d1a3",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "2916",
        nombre: "2916-2-1R",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 6,
        valor: 1500,
        inventario: 6,
        idArticulo: "632cb99d519b4700162c2252",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2108",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 15,
        valor: 1480.5,
        inventario: 15,
        idArticulo: "62745dfd95c45c183877d2ea",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "WILSON",
        nombre: "WILSON-LR2002(20A)",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 1478.4,
        inventario: 4,
        idArticulo: "628be7f395692838f4c58b8e",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2020",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "COSMIC",
        nombre: "COSMIC-XT2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 6,
        valor: 1464,
        inventario: 6,
        idArticulo: "62745df995c45c183877d13d",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2205",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 9,
        valor: 1448.1,
        inventario: 9,
        idArticulo: "62745dfe95c45c183877d392",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "N097",
        nombre: "9296",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 7,
        valor: 1440.6,
        inventario: 7,
        idArticulo: "628be7f195692838f4c58a8a",
        precio: 245,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "MAYA10",
        nombre: "MAYA10-004(21A)",
        linea: "BIKINI",
        idLinea: "627449d00b641700168002ae",
        disponible: 12,
        valor: 1438.5600000000002,
        inventario: 12,
        idArticulo: "62745e0195c45c183877d4bc",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N119",
        nombre: "CO65003(20A)",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 9,
        valor: 1436.4,
        inventario: 9,
        idArticulo: "628be7f195692838f4c58ad2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2020",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "RS26",
        nombre: "RS26FWWSET002",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 8,
        valor: 1432.8,
        inventario: 8,
        idArticulo: "62745e0895c45c183877d7a2",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "HG",
        nombre: "HG-ROXY2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 7,
        valor: 1421,
        inventario: 7,
        idArticulo: "62745dfc95c45c183877d285",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2107",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 14,
        valor: 1381.8,
        inventario: 14,
        idArticulo: "62745dfd95c45c183877d2e4",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CINDY",
        nombre: "CINDY-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 1380,
        inventario: 4,
        idArticulo: "62745df995c45c183877d121",
        precio: 535,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N060",
        nombre: "2923-5-9R",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 6,
        valor: 1358.2800000000002,
        inventario: 6,
        idArticulo: "62745df495c45c183877cef2",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-HN2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 8,
        valor: 1355.2,
        inventario: 8,
        idArticulo: "628be7f495692838f4c58bae",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2207",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 8,
        valor: 1353.6,
        inventario: 8,
        idArticulo: "62745e0295c45c183877d544",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 7,
        valor: 1347.5,
        inventario: 7,
        idArticulo: "62745e0295c45c183877d557",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N087",
        nombre: "655",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 12,
        valor: 1342.8000000000002,
        inventario: 12,
        idArticulo: "628c04e6af38570016a10c96",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SINGAPUR",
        nombre: "SINGAPUR-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 7,
        valor: 1323.7,
        inventario: 7,
        idArticulo: "62745e0895c45c183877d7dc",
        precio: 235,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MILAN",
        nombre: "MILAN03",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 1315.8000000000002,
        inventario: 5,
        idArticulo: "628be7f295692838f4c58b32",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NEO",
        nombre: "NEO-LE2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 8,
        valor: 1266.8,
        inventario: 8,
        idArticulo: "62745e0295c45c183877d52c",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OLIVIAPLUS",
        nombre: "OLIVIAPLUS-HN2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 6,
        valor: 1260,
        inventario: 6,
        idArticulo: "62745e0395c45c183877d5c1",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "JFK",
        nombre: "JFK-XT2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 6,
        valor: 1248,
        inventario: 6,
        idArticulo: "62745dfd95c45c183877d2d0",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "JFK",
        nombre: "JFK-XT2202Q",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 6,
        valor: 1248,
        inventario: 6,
        idArticulo: "62745dfd95c45c183877d2c0",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ALISON",
        nombre: "ALISON-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 7,
        valor: 1234.8,
        inventario: 7,
        idArticulo: "62745df695c45c183877cfaa",
        precio: 400,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 7,
        valor: 1221.5,
        inventario: 7,
        idArticulo: "62745e0695c45c183877d6d6",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JENIFER",
        nombre: "JENIFER-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 1209.25,
        inventario: 5,
        idArticulo: "62745dfd95c45c183877d2c4",
        precio: 400,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "CABO",
        nombre: "CABO-LW2003(20A)",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 8,
        valor: 1195.1200000000001,
        inventario: 8,
        idArticulo: "62745df895c45c183877d0de",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2020",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIKO",
        nombre: "NIKO-MG2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 8,
        valor: 1192.8,
        inventario: 8,
        idArticulo: "62745e0395c45c183877d5b5",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-HN2210",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 7,
        valor: 1185.8,
        inventario: 7,
        idArticulo: "631918426a8c640016eee057",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "RC26",
        nombre: "RC26FWGSET003",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 7,
        valor: 1176,
        inventario: 7,
        idArticulo: "628be7f395692838f4c58b40",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "TANIA",
        nombre: "TANIA-TY2202",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 5,
        valor: 1167.5,
        inventario: 5,
        idArticulo: "62745e0995c45c183877d80d",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "8709XX",
        nombre: "8709XX",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1160,
        valor: 1160,
        inventario: 1160,
        idArticulo: "6312456c6babbb001670211f",
        precio: 165,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LAX",
        nombre: "LAX-XT2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 6,
        valor: 1159.8,
        inventario: 6,
        idArticulo: "62745e0095c45c183877d43e",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA-MG2205",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 5,
        valor: 1135.75,
        inventario: 5,
        idArticulo: "62745dfe95c45c183877d340",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "FELIX",
        nombre: "FELIX-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 1118.75,
        inventario: 5,
        idArticulo: "62745dfb95c45c183877d1d5",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "QUEENS",
        nombre: "QUEENS-LR2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 1099,
        inventario: 5,
        idArticulo: "62745e0695c45c183877d6fc",
        precio: 345,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIRLZ",
        nombre: "GIRLZ-LE2103",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 14,
        valor: 1087.8,
        inventario: 14,
        idArticulo: "62745dfb95c45c183877d20f",
        precio: 165,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DNZM",
        nombre: "DNZM-LE2303C",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 9,
        valor: 1083.1499999999999,
        inventario: 9,
        idArticulo: "62ed75729416cf0016d49569",
        precio: 154,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2023",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "UGANDA",
        nombre: "UGANDA-LW2104",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 8,
        valor: 1066.8,
        inventario: 8,
        idArticulo: "628be7f395692838f4c58b88",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "HG",
        nombre: "HG-TOMMY2107",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 7,
        valor: 1050,
        inventario: 7,
        idArticulo: "62745dfc95c45c183877d288",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SYDNEY",
        nombre: "SYDNEY-LW2002(20A)",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 1050,
        inventario: 5,
        idArticulo: "628be7f395692838f4c58b78",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2020",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JFK",
        nombre: "JFK-XT2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 1040,
        inventario: 5,
        idArticulo: "62745dfd95c45c183877d2ce",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "N112",
        nombre: "2990-6-2D",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 6,
        valor: 1035.3600000000001,
        inventario: 6,
        idArticulo: "628be7f195692838f4c58ab2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "KITY",
        nombre: "KITY-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 6,
        valor: 1017,
        inventario: 6,
        idArticulo: "62745dff95c45c183877d3ff",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KOTY",
        nombre: "KOTY-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 6,
        valor: 1017,
        inventario: 6,
        idArticulo: "62745e0095c45c183877d404",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROX",
        nombre: "ROX-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 6,
        valor: 1017,
        inventario: 6,
        idArticulo: "62745e0795c45c183877d77a",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 997.5,
        inventario: 3,
        idArticulo: "62745e0795c45c183877d756",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "LUIGI",
        nombre: "LUIGI-BL2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 983.4,
        inventario: 4,
        idArticulo: "62745e0195c45c183877d4aa",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "LEGOHOOD",
        nombre: "LEGOHOOD-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 6,
        valor: 978.5999999999999,
        inventario: 6,
        idArticulo: "628be7f395692838f4c58b8a",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "LUCA",
        nombre: "LUCA-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 977,
        inventario: 5,
        idArticulo: "62745e0195c45c183877d49d",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SHAKIRA",
        nombre: "SHAKIRA-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 967.4,
        inventario: 4,
        idArticulo: "62745e0895c45c183877d7ae",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LAX",
        nombre: "LAX-XT2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 5,
        valor: 966.4999999999999,
        inventario: 5,
        idArticulo: "62745e0095c45c183877d43a",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "N104",
        nombre: "2017DA",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 6,
        valor: 961.1999999999999,
        inventario: 6,
        idArticulo: "628be7f195692838f4c58a9e",
        precio: 200,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "RS27",
        nombre: "RS27FWWJCT059",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 6,
        valor: 961.1999999999999,
        inventario: 6,
        idArticulo: "628be7f395692838f4c58b74",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "IBIZA",
        nombre: "IBIZA-MG2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 950,
        inventario: 5,
        idArticulo: "62e40081dd6410001620e783",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "BORO",
        nombre: "BORO-XT2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 944.4,
        inventario: 4,
        idArticulo: "62745df795c45c183877d056",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KYOTORIB",
        nombre: "KYOTORIB-LW2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 935.5,
        inventario: 5,
        idArticulo: "62745e0095c45c183877d42a",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TANIA",
        nombre: "TANIA-TY2201",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 4,
        valor: 934,
        inventario: 4,
        idArticulo: "62745e0995c45c183877d808",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "KILE",
        nombre: "KILE-LE2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 5,
        valor: 921.75,
        inventario: 5,
        idArticulo: "628be7f495692838f4c58bb0",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KILE",
        nombre: "KILE-LE2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 5,
        valor: 921.75,
        inventario: 5,
        idArticulo: "628be7f495692838f4c58bb2",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SILVIA",
        nombre: "SILVIA-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 898.25,
        inventario: 5,
        idArticulo: "62745e0895c45c183877d7d2",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OSLO",
        nombre: "OSLO-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 6,
        valor: 889.6199999999999,
        inventario: 6,
        idArticulo: "62745e0495c45c183877d5e5",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VANE",
        nombre: "VANE-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 876.9000000000001,
        inventario: 3,
        idArticulo: "62745e0a95c45c183877d885",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "BIKERHOOD",
        nombre: "BIKERHOOD-MG2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 870.8,
        inventario: 4,
        idArticulo: "62745df795c45c183877d035",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "22#",
        nombre: "22#7975",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 6,
        valor: 864.3000000000001,
        inventario: 6,
        idArticulo: "634c5761d74f160016d1a0d3",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "1912",
        nombre: "1912-4-4D",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 862,
        inventario: 4,
        idArticulo: "632b3183c3a7610016ac8ef2",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "KIDZ",
        nombre: "KIDZ-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 858,
        inventario: 5,
        idArticulo: "62745dff95c45c183877d3c9",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "REX",
        nombre: "REX-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 858,
        inventario: 5,
        idArticulo: "62745e0695c45c183877d712",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "REX",
        nombre: "REX-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 858,
        inventario: 5,
        idArticulo: "62745e0695c45c183877d710",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ALDO",
        nombre: "ALDO-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 850,
        inventario: 4,
        idArticulo: "62745df595c45c183877cf7d",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KITY",
        nombre: "KITY-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 847.5,
        inventario: 5,
        idArticulo: "62745dff95c45c183877d3f8",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROX",
        nombre: "ROX-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 847.5,
        inventario: 5,
        idArticulo: "628be7f495692838f4c58bb6",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N099",
        nombre: "90300017",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 4,
        valor: 840,
        inventario: 4,
        idArticulo: "628be7f195692838f4c58a92",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "LUCY",
        nombre: "LUCY-LW2102",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 7,
        valor: 837.9,
        inventario: 7,
        idArticulo: "62e400ebdd6410001620e854",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ROSY",
        nombre: "ROSY-TY2201",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 3,
        valor: 833.6999999999999,
        inventario: 3,
        idArticulo: "62745e0795c45c183877d771",
        precio: 480,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "DL2",
        nombre: "DL26FWWSET010",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 5,
        valor: 832.5,
        inventario: 5,
        idArticulo: "62745dfa95c45c183877d17b",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2017",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "PERU",
        nombre: "PERU-LW2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 6,
        valor: 830.4000000000001,
        inventario: 6,
        idArticulo: "62745e0595c45c183877d651",
        precio: 130,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2102",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 6,
        valor: 819,
        inventario: 6,
        idArticulo: "62745e0a95c45c183877d889",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "LEGOHOOD",
        nombre: "LEGOHOOD-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 5,
        valor: 815.5,
        inventario: 5,
        idArticulo: "628be7f395692838f4c58b8a",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "LEGOHOOD",
        nombre: "LEGOHOOD-MG2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 5,
        valor: 815.5,
        inventario: 5,
        idArticulo: "628be7f395692838f4c58b8c",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BINGO",
        nombre: "BINGO-LW2104",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 8,
        valor: 806.4,
        inventario: 8,
        idArticulo: "62745df795c45c183877d041",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERSALLES",
        nombre: "VERSALLES-LW2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 6,
        valor: 805.1999999999999,
        inventario: 6,
        idArticulo: "62745e0a95c45c183877d8ad",
        precio: 125,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 5,
        valor: 804.5,
        inventario: 5,
        idArticulo: "62745dff95c45c183877d3b2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "N021",
        nombre: "6383",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 6,
        valor: 803.46,
        inventario: 6,
        idArticulo: "62745df395c45c183877ce71",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 6,
        valor: 786.24,
        inventario: 6,
        idArticulo: "62745dfe95c45c183877d326",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "FILIP",
        nombre: "FILIP-KV2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 4,
        valor: 784.8000000000001,
        inventario: 4,
        idArticulo: "62745dfb95c45c183877d1e9",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "TANIAPLUS",
        nombre: "TANIAPLUS-TY2203",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 3,
        valor: 784.8000000000001,
        inventario: 3,
        idArticulo: "62745e0995c45c183877d804",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "TANIAPLUS",
        nombre: "TANIAPLUS-TY2202",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 3,
        valor: 784.8000000000001,
        inventario: 3,
        idArticulo: "62745e0995c45c183877d802",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "TANIAPLUS",
        nombre: "TANIAPLUS-TY2201",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 3,
        valor: 784.8000000000001,
        inventario: 3,
        idArticulo: "62745e0995c45c183877d7ff",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JAPAN",
        nombre: "JAPAN-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 782.2500000000001,
        inventario: 5,
        idArticulo: "62745dfd95c45c183877d2ba",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "LAX",
        nombre: "LAX-XT2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 4,
        valor: 773.1999999999999,
        inventario: 4,
        idArticulo: "62745e0095c45c183877d438",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "LAX",
        nombre: "LAX-XT2205Q",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 4,
        valor: 773.1999999999999,
        inventario: 4,
        idArticulo: "62745e0095c45c183877d441",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "LAX",
        nombre: "LAX-XT2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 4,
        valor: 773.1999999999999,
        inventario: 4,
        idArticulo: "62745e0095c45c183877d438",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "PAULA",
        nombre: "PAULA-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 769.8000000000001,
        inventario: 3,
        idArticulo: "62745e0495c45c183877d631",
        precio: 400,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N101",
        nombre: "12BVG147",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 5,
        valor: 760.05,
        inventario: 5,
        idArticulo: "628be7f195692838f4c58a90",
        precio: 190,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N085",
        nombre: "201774",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 760,
        inventario: 4,
        idArticulo: "6282b37f6794e50016899c5c",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SINGAPUR",
        nombre: "SINGAPUR-LW2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 756.4,
        inventario: 4,
        idArticulo: "62745e0895c45c183877d7e3",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SINGAPUR",
        nombre: "SINGAPUR-LW2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 756.4,
        inventario: 4,
        idArticulo: "62745e0895c45c183877d7e1",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SINGAPUR",
        nombre: "SINGAPUR-LW2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 756.4,
        inventario: 4,
        idArticulo: "62745e0895c45c183877d7de",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2207",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 6,
        valor: 750,
        inventario: 6,
        idArticulo: "62745e0295c45c183877d50c",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KYOTORIB",
        nombre: "KYOTORIB-LW2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 748.4,
        inventario: 4,
        idArticulo: "62745e0095c45c183877d42d",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2102",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 6,
        valor: 743.4000000000001,
        inventario: 6,
        idArticulo: "62745dff95c45c183877d3a4",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "OSLO",
        nombre: "OSLO-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 741.3499999999999,
        inventario: 5,
        idArticulo: "62745e0495c45c183877d5e2",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OREGON",
        nombre: "OREGON-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 738.1999999999999,
        inventario: 5,
        idArticulo: "62745e0495c45c183877d5db",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OREGON",
        nombre: "OREGON-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 738.1999999999999,
        inventario: 5,
        idArticulo: "62745e0495c45c183877d5d9",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OREGON",
        nombre: "OREGON-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 738.1999999999999,
        inventario: 5,
        idArticulo: "62745e0395c45c183877d5d4",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "LUIGI",
        nombre: "LUIGI-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 737.55,
        inventario: 3,
        idArticulo: "62745e0195c45c183877d4b0",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KENTHOOD",
        nombre: "KENTHOOD-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 733.5,
        inventario: 3,
        idArticulo: "62745dfe95c45c183877d37a",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "ALDOBOY",
        nombre: "ALDOBOY-BL2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 731.1999999999999,
        inventario: 4,
        idArticulo: "62745df595c45c183877cf7f",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "OLIVIA",
        nombre: "OLIVIA-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 730.8,
        inventario: 4,
        idArticulo: "62745e0395c45c183877d5be",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "GV",
        nombre: "GV-1815",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 7,
        valor: 724.5,
        inventario: 7,
        idArticulo: "628be7f295692838f4c58b1a",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "GV",
        nombre: "GV-1818",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 7,
        valor: 724.5,
        inventario: 7,
        idArticulo: "628be7f295692838f4c58b1e",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SILVIA",
        nombre: "SILVIA-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 718.6,
        inventario: 4,
        idArticulo: "62745e0895c45c183877d7d0",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SILVIA",
        nombre: "SILVIA-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 718.6,
        inventario: 4,
        idArticulo: "62745e0895c45c183877d7cc",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIK",
        nombre: "NIK-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 7,
        valor: 715.0500000000001,
        inventario: 7,
        idArticulo: "62745e0395c45c183877d5a8",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "RENO",
        nombre: "RENO-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 700.52,
        inventario: 4,
        idArticulo: "62745e0695c45c183877d704",
        precio: 255,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TANIA",
        nombre: "TANIA-TY2204",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 3,
        valor: 700.5,
        inventario: 3,
        idArticulo: "62745e0995c45c183877d80f",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "TANIA",
        nombre: "TANIA-TY2203",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 3,
        valor: 700.5,
        inventario: 3,
        idArticulo: "62745e0995c45c183877d815",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "RC26",
        nombre: "RC26FWGSET009",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 4,
        valor: 699.6,
        inventario: 4,
        idArticulo: "628be7f395692838f4c58b58",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2210",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 698,
        inventario: 4,
        idArticulo: "62745e0695c45c183877d6e0",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 698,
        inventario: 4,
        idArticulo: "62745e0695c45c183877d6dc",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 698,
        inventario: 4,
        idArticulo: "62745e0695c45c183877d6da",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 698,
        inventario: 4,
        idArticulo: "62745e0695c45c183877d6d8",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ALDOPLUS",
        nombre: "ALDOPLUS-BL2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 693.45,
        inventario: 3,
        idArticulo: "62745df695c45c183877cf88",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "REX",
        nombre: "REX-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 686.4,
        inventario: 4,
        idArticulo: "62745e0695c45c183877d70e",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PETER",
        nombre: "PETER-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 685.5,
        inventario: 3,
        idArticulo: "62745e0595c45c183877d66b",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "ROX",
        nombre: "ROX-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 678,
        inventario: 4,
        idArticulo: "62745e0795c45c183877d77c",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 677.6,
        inventario: 4,
        idArticulo: "62745e0a95c45c183877d861",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 677.6,
        inventario: 4,
        idArticulo: "62745e0a95c45c183877d85d",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 677.6,
        inventario: 4,
        idArticulo: "62745e0a95c45c183877d858",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2205",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 4,
        valor: 676.8000000000001,
        inventario: 4,
        idArticulo: "62745e0295c45c183877d53d",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "TEO",
        nombre: "TEO-LE2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 667,
        inventario: 4,
        idArticulo: "62745e0995c45c183877d811",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N094",
        nombre: "7631",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 666.75,
        inventario: 5,
        idArticulo: "628be7f195692838f4c58a8e",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 665,
        inventario: 2,
        idArticulo: "62745e0795c45c183877d75a",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 665,
        inventario: 2,
        idArticulo: "62745e0795c45c183877d750",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HANS",
        nombre: "HANS-TY2203",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 3,
        valor: 660.3,
        inventario: 3,
        idArticulo: "62745dfc95c45c183877d246",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "DL2",
        nombre: "DL23FWBSET004",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 11,
        valor: 660,
        inventario: 11,
        idArticulo: "62745dfa95c45c183877d181",
        precio: 70,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "QUEENS",
        nombre: "QUEENS-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 659.4000000000001,
        inventario: 3,
        idArticulo: "62745e0695c45c183877d6f2",
        precio: 345,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2127",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 653.88,
        inventario: 4,
        idArticulo: "62745e0595c45c183877d6a8",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIKOHOOD",
        nombre: "NIKOHOOD-MG2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 646.88,
        inventario: 4,
        idArticulo: "62745e0395c45c183877d5aa",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BEN",
        nombre: "BEN-KV2003(20A)",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 5,
        valor: 635.25,
        inventario: 5,
        idArticulo: "628be7f295692838f4c58af6",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2020",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "BOLIVIA",
        nombre: "BOLIVIA-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 630,
        inventario: 5,
        idArticulo: "62745df795c45c183877d049",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TEXAS",
        nombre: "TEXAS-LR2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 630,
        inventario: 3,
        idArticulo: "62745e0995c45c183877d821",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TEXAS",
        nombre: "TEXAS-LR2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 630,
        inventario: 3,
        idArticulo: "62745e0995c45c183877d823",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 626,
        inventario: 2,
        idArticulo: "62745dfc95c45c183877d28e",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BIKER",
        nombre: "BIKER-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 624.75,
        inventario: 3,
        idArticulo: "62745df795c45c183877d037",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BIKER",
        nombre: "BIKER-MG2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 624.75,
        inventario: 3,
        idArticulo: "62745df795c45c183877d03d",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CM20",
        nombre: "CM-2013",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3,
        valor: 623.61,
        inventario: 3,
        idArticulo: "62745df995c45c183877d135",
        precio: 260,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "KELLY",
        nombre: "KELLY-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 619.8,
        inventario: 2,
        idArticulo: "62745dfe95c45c183877d36a",
        precio: 490,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "MANHATAN",
        nombre: "MANHATAN-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 616,
        inventario: 2,
        idArticulo: "62745e0195c45c183877d4b2",
        precio: 450,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROCKY",
        nombre: "ROCKY-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 616,
        inventario: 2,
        idArticulo: "62745e0795c45c183877d75d",
        precio: 450,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MANHATAN",
        nombre: "MANHATAN-LR2201F",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 616,
        inventario: 2,
        idArticulo: "62c33d4a27a4e10017c9284a",
        precio: 450,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROXY",
        nombre: "ROXY-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 611.0999999999999,
        inventario: 3,
        idArticulo: "62745e0895c45c183877d782",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N092",
        nombre: "6363",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 611.0999999999999,
        inventario: 3,
        idArticulo: "628be7f095692838f4c58a7e",
        precio: 245,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "LEGO",
        nombre: "LEGO-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 4,
        valor: 610.4,
        inventario: 4,
        idArticulo: "628be7f495692838f4c58b98",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 609,
        inventario: 3,
        idArticulo: "62745df895c45c183877d06d",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PAQUITO",
        nombre: "PAQUITO-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 601.2,
        inventario: 3,
        idArticulo: "62745e0495c45c183877d618",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MASSIMO",
        nombre: "MASSIMO-HN2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3,
        valor: 600.3,
        inventario: 3,
        idArticulo: "62745e0195c45c183877d4ba",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "KATY",
        nombre: "KATY-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 594.6,
        inventario: 2,
        idArticulo: "62745dfe95c45c183877d368",
        precio: 490,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "CARRERA",
        nombre: "CARRERA-KV2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 594,
        inventario: 2,
        idArticulo: "62745df995c45c183877d101",
        precio: 465,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "CARRERA",
        nombre: "CARRERA-KV2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 594,
        inventario: 2,
        idArticulo: "62745df995c45c183877d0fd",
        precio: 465,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "CARRERA",
        nombre: "CARRERA-KV2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 594,
        inventario: 2,
        idArticulo: "62745df995c45c183877d0f5",
        precio: 465,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "CARRERA",
        nombre: "CARRERA-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 594,
        inventario: 2,
        idArticulo: "62745df995c45c183877d0f3",
        precio: 465,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "FILIP",
        nombre: "FILIP-KV2205",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3,
        valor: 588.6,
        inventario: 3,
        idArticulo: "62745dfb95c45c183877d1ef",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 582,
        inventario: 2,
        idArticulo: "62745dfc95c45c183877d278",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 582,
        inventario: 2,
        idArticulo: "62745dfc95c45c183877d266",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 582,
        inventario: 2,
        idArticulo: "62745dfc95c45c183877d264",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 582,
        inventario: 2,
        idArticulo: "62745dfc95c45c183877d26a",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 582,
        inventario: 2,
        idArticulo: "62745dfc95c45c183877d25e",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "LAX",
        nombre: "LAX-XT2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 3,
        valor: 579.9,
        inventario: 3,
        idArticulo: "62745e0095c45c183877d43c",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ALASKA",
        nombre: "ALASKA-LW2106",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 575.4,
        inventario: 4,
        idArticulo: "62745df595c45c183877cf7b",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SINGAPUR",
        nombre: "SINGAPUR-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 567.3,
        inventario: 3,
        idArticulo: "628be7f495692838f4c58b9c",
        precio: 235,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 564.87,
        inventario: 3,
        idArticulo: "62745e0395c45c183877d5d0",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 564.87,
        inventario: 3,
        idArticulo: "62745e0395c45c183877d5ce",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 564.87,
        inventario: 3,
        idArticulo: "62745e0395c45c183877d5cc",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 564.87,
        inventario: 3,
        idArticulo: "62745e0395c45c183877d5c9",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ORBIT",
        nombre: "ORBIT-XT2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 564.87,
        inventario: 3,
        idArticulo: "62745e0395c45c183877d5c7",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KYOTORIB",
        nombre: "KYOTORIB-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 561.3,
        inventario: 3,
        idArticulo: "62745e0095c45c183877d428",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KYOTORIB",
        nombre: "KYOTORIB-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 561.3,
        inventario: 3,
        idArticulo: "62745e0095c45c183877d424",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ALISON",
        nombre: "ALISON-HN2202",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 2,
        valor: 557.3,
        inventario: 2,
        idArticulo: "62745df695c45c183877cfa1",
        precio: 400,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "ALISON",
        nombre: "ALISON-HN2201",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 2,
        valor: 557.3,
        inventario: 2,
        idArticulo: "62745df695c45c183877cf9d",
        precio: 400,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "ROSY",
        nombre: "ROSY-TY2202",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 2,
        valor: 555.8,
        inventario: 2,
        idArticulo: "62745e0795c45c183877d776",
        precio: 480,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "MC",
        nombre: "MC02T",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 537.5,
        inventario: 4,
        idArticulo: "632b3dafc3a7610016acb043",
        precio: 212.66,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "UGANDA",
        nombre: "UGANDA-LW2101",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 4,
        valor: 533.4,
        inventario: 4,
        idArticulo: "628be7f395692838f4c58b86",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ALISON",
        nombre: "ALISON-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 529.2,
        inventario: 3,
        idArticulo: "62745df695c45c183877cfaa",
        precio: 400,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RENO",
        nombre: "RENO-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 525.39,
        inventario: 3,
        idArticulo: "62745e0695c45c183877d704",
        precio: 255,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RC26",
        nombre: "RC26FWGSET006",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 3,
        valor: 524.7,
        inventario: 3,
        idArticulo: "628be7f395692838f4c58b50",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 523.5,
        inventario: 3,
        idArticulo: "62745e0695c45c183877d6ce",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 523.5,
        inventario: 3,
        idArticulo: "62745e0695c45c183877d6cc",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 523.5,
        inventario: 3,
        idArticulo: "62745e0695c45c183877d6ca",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIDZ",
        nombre: "KIDZ-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 514.8,
        inventario: 3,
        idArticulo: "62745dff95c45c183877d3c7",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VIENA",
        nombre: "VIENA-HN2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 514.8,
        inventario: 2,
        idArticulo: "62745e0b95c45c183877d8cf",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "KOTY",
        nombre: "KOTY-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 508.5,
        inventario: 3,
        idArticulo: "62745dff95c45c183877d3fd",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 508.20000000000005,
        inventario: 3,
        idArticulo: "62745e0a95c45c183877d852",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 508.20000000000005,
        inventario: 3,
        idArticulo: "62745e0a95c45c183877d84e",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2204",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3,
        valor: 507.6,
        inventario: 3,
        idArticulo: "62745e0295c45c183877d539",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2202",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3,
        valor: 507.6,
        inventario: 3,
        idArticulo: "62745e0295c45c183877d537",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2207",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3,
        valor: 507.6,
        inventario: 3,
        idArticulo: "62745e0295c45c183877d544",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "CLOE",
        nombre: "CLOE-LE2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 3,
        valor: 503.4,
        inventario: 3,
        idArticulo: "62745df995c45c183877d125",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TEO",
        nombre: "TEO-LE2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 500.25,
        inventario: 3,
        idArticulo: "62745e0995c45c183877d81d",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TEO",
        nombre: "TEO-LE2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 500.25,
        inventario: 3,
        idArticulo: "62745e0995c45c183877d813",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TEO",
        nombre: "TEO-LE2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 500.25,
        inventario: 3,
        idArticulo: "62745e0995c45c183877d811",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2201",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 4,
        valor: 500,
        inventario: 4,
        idArticulo: "62745e0295c45c183877d4fa",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2101",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 4,
        valor: 495.6,
        inventario: 4,
        idArticulo: "62745dff95c45c183877d39e",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "NIZA",
        nombre: "NIZA-LW2103",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 4,
        valor: 493.16,
        inventario: 4,
        idArticulo: "628be7f395692838f4c58b3c",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2126",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 490.41,
        inventario: 3,
        idArticulo: "62745e0595c45c183877d6aa",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KENTHOOD",
        nombre: "KENTHOOD-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 489,
        inventario: 2,
        idArticulo: "62745dfe95c45c183877d37c",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "NIKOHOOD",
        nombre: "NIKOHOOD-MG2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 485.16,
        inventario: 3,
        idArticulo: "62745e0395c45c183877d5ac",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SHAKIRA",
        nombre: "SHAKIRA-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 483.7,
        inventario: 2,
        idArticulo: "62745e0895c45c183877d7ae",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 3,
        valor: 482.70000000000005,
        inventario: 3,
        idArticulo: "62745dfe95c45c183877d386",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 3,
        valor: 482.70000000000005,
        inventario: 3,
        idArticulo: "62745dff95c45c183877d3b0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 3,
        valor: 482.70000000000005,
        inventario: 3,
        idArticulo: "62745dff95c45c183877d3b6",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2207",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 3,
        valor: 482.70000000000005,
        inventario: 3,
        idArticulo: "62745dff95c45c183877d3bc",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2208",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 3,
        valor: 482.70000000000005,
        inventario: 3,
        idArticulo: "62745dff95c45c183877d3c0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2205",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 3,
        valor: 482.70000000000005,
        inventario: 3,
        idArticulo: "62745dff95c45c183877d3c2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "N124",
        nombre: "MD2",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 480.6,
        inventario: 3,
        idArticulo: "628be7f295692838f4c58ae4",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "LEO",
        nombre: "LEO-LE2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 475.04999999999995,
        inventario: 3,
        idArticulo: "62745e0095c45c183877d45e",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEO",
        nombre: "LEO-LE2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 475.04999999999995,
        inventario: 3,
        idArticulo: "62745e0095c45c183877d457",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KENT",
        nombre: "KENT-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 472.2,
        inventario: 2,
        idArticulo: "62745dfe95c45c183877d380",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "N073",
        nombre: "7297C",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 4,
        valor: 470.4,
        inventario: 4,
        idArticulo: "62745df595c45c183877cf1b",
        precio: 210,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JAPAN",
        nombre: "JAPAN-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 469.35,
        inventario: 3,
        idArticulo: "62745dfd95c45c183877d2b8",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PERUHOOD",
        nombre: "PERUHOOD-LW2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3,
        valor: 465.6,
        inventario: 3,
        idArticulo: "62745e0495c45c183877d645",
        precio: 140,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PERUHOOD",
        nombre: "PERUHOOD-LW2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3,
        valor: 465.6,
        inventario: 3,
        idArticulo: "62745e0495c45c183877d643",
        precio: 140,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIO",
        nombre: "JULIO-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 464.8,
        inventario: 2,
        idArticulo: "62745dfe95c45c183877d342",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SHELLY",
        nombre: "SHELLY-LE2202A",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 464.8,
        inventario: 2,
        idArticulo: "62745e0895c45c183877d7b4",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PERUPLUS",
        nombre: "PERUPLUS-LW2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3,
        valor: 464.52,
        inventario: 3,
        idArticulo: "62745e0595c45c183877d65a",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERSALLESHOOD",
        nombre: "VERSALLESHOOD-LW2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3,
        valor: 462,
        inventario: 3,
        idArticulo: "62745e0a95c45c183877d8a6",
        precio: 135,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "LEGO",
        nombre: "LEGO-MG2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3,
        valor: 457.79999999999995,
        inventario: 3,
        idArticulo: "628be7f495692838f4c58b96",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA-MG2204",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 2,
        valor: 454.3,
        inventario: 2,
        idArticulo: "62745dfe95c45c183877d338",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PACO",
        nombre: "PACO-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 451.2,
        inventario: 2,
        idArticulo: "62745e0495c45c183877d614",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "VERSALLESPLUS",
        nombre: "VERSALLESPLUS-LW2203",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3,
        valor: 449.4,
        inventario: 3,
        idArticulo: "62745e0b95c45c183877d8c1",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERSALLESPLUS",
        nombre: "VERSALLESPLUS-LW2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3,
        valor: 449.4,
        inventario: 3,
        idArticulo: "62745e0b95c45c183877d8bf",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERSALLESPLUS",
        nombre: "VERSALLESPLUS-LW2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3,
        valor: 449.4,
        inventario: 3,
        idArticulo: "62745e0b95c45c183877d8bd",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "GABO",
        nombre: "GABO-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 448.6,
        inventario: 2,
        idArticulo: "62745dfb95c45c183877d1f9",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "FELIX",
        nombre: "FELIX-LE2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 447.5,
        inventario: 2,
        idArticulo: "62745dfb95c45c183877d1d7",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "FELIX",
        nombre: "FELIX-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 447.5,
        inventario: 2,
        idArticulo: "62745dfa95c45c183877d1cb",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "OSLO",
        nombre: "OSLO-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 444.80999999999995,
        inventario: 3,
        idArticulo: "62745e0495c45c183877d5eb",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TANIAGIRLS",
        nombre: "TANIAGIRLS-XT2212",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 2,
        valor: 441.3,
        inventario: 2,
        idArticulo: "631f4d34803c1f0016a3134c",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "HANS",
        nombre: "HANS-TY2202",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 2,
        valor: 440.2,
        inventario: 2,
        idArticulo: "62745dfc95c45c183877d244",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "QUEENS",
        nombre: "QUEENS-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 439.6,
        inventario: 2,
        idArticulo: "62745e0695c45c183877d6fa",
        precio: 345,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TABO",
        nombre: "TABO-XT2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 437.6,
        inventario: 2,
        idArticulo: "628be7f495692838f4c58ba0",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ELZAPLUS",
        nombre: "ELZAPLUS-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 436.8,
        inventario: 4,
        idArticulo: "62745dfa95c45c183877d1a9",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BIKERHOOD",
        nombre: "BIKERHOOD-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 435.4,
        inventario: 2,
        idArticulo: "62745df795c45c183877d02d",
        precio: 335,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ALDO",
        nombre: "ALDO-BL2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 425,
        inventario: 2,
        idArticulo: "62745df595c45c183877cf81",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "JFK",
        nombre: "JFK-XT2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 416,
        inventario: 2,
        idArticulo: "62745dfd95c45c183877d2c6",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "PERU",
        nombre: "PERU-LW2204",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3,
        valor: 415.20000000000005,
        inventario: 3,
        idArticulo: "62745e0595c45c183877d655",
        precio: 130,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PERU",
        nombre: "PERU-LW2203",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3,
        valor: 415.20000000000005,
        inventario: 3,
        idArticulo: "62745e0595c45c183877d64d",
        precio: 130,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PERU",
        nombre: "PERU-LW2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3,
        valor: 415.20000000000005,
        inventario: 3,
        idArticulo: "62745e0595c45c183877d64f",
        precio: 130,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DL27",
        nombre: "DL27FWMJCT132",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 409.92,
        inventario: 2,
        idArticulo: "628be7f295692838f4c58b0c",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ROXY",
        nombre: "ROXY-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 407.4,
        inventario: 2,
        idArticulo: "62745e0895c45c183877d77e",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 406,
        inventario: 2,
        idArticulo: "62745df795c45c183877d063",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 406,
        inventario: 2,
        idArticulo: "62745df795c45c183877d061",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 406,
        inventario: 2,
        idArticulo: "62745df795c45c183877d059",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "VERSALLES",
        nombre: "VERSALLES-LW2204",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3,
        valor: 402.6,
        inventario: 3,
        idArticulo: "62745e0b95c45c183877d8b1",
        precio: 125,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERSALLES",
        nombre: "VERSALLES-LW2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3,
        valor: 402.6,
        inventario: 3,
        idArticulo: "62745e0a95c45c183877d8ad",
        precio: 125,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERSALLES",
        nombre: "VERSALLES-LW2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 3,
        valor: 402.6,
        inventario: 3,
        idArticulo: "62745e0a95c45c183877d8ab",
        precio: 125,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BRADYBOY",
        nombre: "BRADYBOY-XT2104",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 393.75,
        inventario: 3,
        idArticulo: "62745df895c45c183877d0ba",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "FILIP",
        nombre: "FILIP-KV2206",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 2,
        valor: 392.4,
        inventario: 2,
        idArticulo: "62745dfb95c45c183877d1f1",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "FILIP",
        nombre: "FILIP-KV2204",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 2,
        valor: 392.4,
        inventario: 2,
        idArticulo: "62745dfb95c45c183877d1eb",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "FILIP",
        nombre: "FILIP-KV2203",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 2,
        valor: 392.4,
        inventario: 2,
        idArticulo: "62745dfb95c45c183877d1ed",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "FILIP",
        nombre: "FILIP-KV2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 2,
        valor: 392.4,
        inventario: 2,
        idArticulo: "62745dfb95c45c183877d1e9",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "FILIP",
        nombre: "FILIP-KV2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 2,
        valor: 392.4,
        inventario: 2,
        idArticulo: "62745dfb95c45c183877d1e7",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HANSBOY",
        nombre: "HANSBOY-TY2201",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 2,
        valor: 390.3,
        inventario: 2,
        idArticulo: "62745dfc95c45c183877d249",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 385,
        inventario: 2,
        idArticulo: "62745e0295c45c183877d563",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 385,
        inventario: 2,
        idArticulo: "62745e0295c45c183877d55b",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 385,
        inventario: 2,
        idArticulo: "62745e0295c45c183877d557",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 385,
        inventario: 2,
        idArticulo: "62745e0395c45c183877d566",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MONACO",
        nombre: "MONACO-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 3,
        valor: 384.29999999999995,
        inventario: 3,
        idArticulo: "62745e0195c45c183877d4e0",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NEMO",
        nombre: "NEMO-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 379.2,
        inventario: 2,
        idArticulo: "62745e0295c45c183877d51e",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEMO",
        nombre: "NEMO-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 379.2,
        inventario: 2,
        idArticulo: "62745e0295c45c183877d527",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LOLA",
        nombre: "LOLA-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 378.2,
        inventario: 2,
        idArticulo: "62745e0195c45c183877d487",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "SINGAPUR",
        nombre: "SINGAPUR-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 378.2,
        inventario: 2,
        idArticulo: "62745e0895c45c183877d7dc",
        precio: 235,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2205",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3,
        valor: 375,
        inventario: 3,
        idArticulo: "62745e0295c45c183877d507",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2204",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3,
        valor: 375,
        inventario: 3,
        idArticulo: "62745e0295c45c183877d503",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2201",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 3,
        valor: 375,
        inventario: 3,
        idArticulo: "62745e0295c45c183877d4fa",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KYOTORIB",
        nombre: "KYOTORIB-LW2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 374.2,
        inventario: 2,
        idArticulo: "62745e0095c45c183877d42f",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KYOTORIB",
        nombre: "KYOTORIB-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 374.2,
        inventario: 2,
        idArticulo: "62745e0095c45c183877d420",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KYLE",
        nombre: "KYLE-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 368.7,
        inventario: 2,
        idArticulo: "62745e0095c45c183877d406",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KYLE",
        nombre: "KYLE-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 368.7,
        inventario: 2,
        idArticulo: "62745e0095c45c183877d40a",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N089",
        nombre: "6130",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2,
        valor: 365.4,
        inventario: 2,
        idArticulo: "628be7f095692838f4c58a7a",
        precio: 245,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "OLIVIA",
        nombre: "OLIVIA-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 365.4,
        inventario: 2,
        idArticulo: "62745e0395c45c183877d5be",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KILA",
        nombre: "KILA-HN2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 2,
        valor: 358.2,
        inventario: 2,
        idArticulo: "62745dff95c45c183877d3de",
        precio: 235,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 355.6,
        inventario: 2,
        idArticulo: "62745df895c45c183877d07e",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 355.6,
        inventario: 2,
        idArticulo: "62745df895c45c183877d075",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "RENO",
        nombre: "RENO-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 350.26,
        inventario: 2,
        idArticulo: "62745e0695c45c183877d70b",
        precio: 255,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2212",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 349,
        inventario: 2,
        idArticulo: "62745e0695c45c183877d6ea",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 349,
        inventario: 2,
        idArticulo: "62745e0695c45c183877d6de",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 349,
        inventario: 2,
        idArticulo: "62745e0695c45c183877d6c8",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 338.8,
        inventario: 2,
        idArticulo: "62745e0a95c45c183877d850",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 338.8,
        inventario: 2,
        idArticulo: "62745e0a95c45c183877d84b",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2207",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2,
        valor: 338.4,
        inventario: 2,
        idArticulo: "62745e0295c45c183877d544",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2203",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2,
        valor: 338.4,
        inventario: 2,
        idArticulo: "62745e0295c45c183877d53b",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2201",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2,
        valor: 338.4,
        inventario: 2,
        idArticulo: "62745e0295c45c183877d530",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2208",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2,
        valor: 338.4,
        inventario: 2,
        idArticulo: "628be7f295692838f4c58b34",
        precio: 250,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "N025",
        nombre: "6400",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 4,
        valor: 336,
        inventario: 4,
        idArticulo: "62745df395c45c183877ce7b",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ROBIN",
        nombre: "ROBIN-XT2207",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 3,
        valor: 335.54999999999995,
        inventario: 3,
        idArticulo: "62745e0795c45c183877d74a",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 332.5,
        inventario: 1,
        idArticulo: "62745e0795c45c183877d758",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "ROBY",
        nombre: "ROBY-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 332.5,
        inventario: 1,
        idArticulo: "62745e0795c45c183877d74d",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "MOSCU",
        nombre: "MOSCU-LW2104",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 331.8,
        inventario: 2,
        idArticulo: "62745e0295c45c183877d4f6",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MOSCU",
        nombre: "MOSCU-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 331.8,
        inventario: 2,
        idArticulo: "62745e0295c45c183877d4f1",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MOSCU",
        nombre: "MOSCU-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 331.8,
        inventario: 2,
        idArticulo: "62745e0295c45c183877d4f8",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 3,
        valor: 331.65,
        inventario: 3,
        idArticulo: "62745e0395c45c183877d594",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 330.4,
        inventario: 2,
        idArticulo: "62745e0395c45c183877d572",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 330.4,
        inventario: 2,
        idArticulo: "62745e0395c45c183877d570",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2108",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2,
        valor: 323.40000000000003,
        inventario: 2,
        idArticulo: "62745dfc95c45c183877d2b4",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 3,
        valor: 322.20000000000005,
        inventario: 3,
        idArticulo: "62745e0395c45c183877d578",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2,
        valor: 321.8,
        inventario: 2,
        idArticulo: "62745dfe95c45c183877d388",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2,
        valor: 321.8,
        inventario: 2,
        idArticulo: "62745dfe95c45c183877d38e",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2205",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2,
        valor: 321.8,
        inventario: 2,
        idArticulo: "62745dfe95c45c183877d392",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2207",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2,
        valor: 321.8,
        inventario: 2,
        idArticulo: "62745dff95c45c183877d39c",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2208",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2,
        valor: 321.8,
        inventario: 2,
        idArticulo: "62745dff95c45c183877d3a0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2204",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2,
        valor: 321.8,
        inventario: 2,
        idArticulo: "62745dfe95c45c183877d390",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "MONIQUEHOOD",
        nombre: "MONIQUEHOOD-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 2,
        valor: 318,
        inventario: 2,
        idArticulo: "62745e0195c45c183877d4e4",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "LEO",
        nombre: "LEO-LE2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 316.7,
        inventario: 2,
        idArticulo: "62745e0095c45c183877d460",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEO",
        nombre: "NEO-LE2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 316.7,
        inventario: 2,
        idArticulo: "62745e0295c45c183877d52a",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TOKYO",
        nombre: "TOKYO-LW2116",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 313.1,
        inventario: 2,
        idArticulo: "62745e0995c45c183877d831",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "INDI",
        nombre: "INDI-LR2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 313,
        inventario: 1,
        idArticulo: "62c3424127a4e10017c93084",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JAPAN",
        nombre: "JAPAN-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 312.90000000000003,
        inventario: 2,
        idArticulo: "62745dfd95c45c183877d2c2",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PERUPLUS",
        nombre: "PERUPLUS-LW2203",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 2,
        valor: 309.68,
        inventario: 2,
        idArticulo: "62745e0595c45c183877d65f",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MANHATAN",
        nombre: "MANHATAN-LR2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 308,
        inventario: 1,
        idArticulo: "62745e0195c45c183877d4ac",
        precio: 450,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROCKY",
        nombre: "ROCKY-LR2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 308,
        inventario: 1,
        idArticulo: "62745e0795c45c183877d75f",
        precio: 450,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "VERSALLESHOOD",
        nombre: "VERSALLESHOOD-LW2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 2,
        valor: 308,
        inventario: 2,
        idArticulo: "62745e0a95c45c183877d8a9",
        precio: 135,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "DL2",
        nombre: "DL27FWMCOT016",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1,
        valor: 307.62,
        inventario: 1,
        idArticulo: "62745dfa95c45c183877d183",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2017",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2204",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 3,
        valor: 299.7,
        inventario: 3,
        idArticulo: "62745dfd95c45c183877d2f4",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2202",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 3,
        valor: 299.7,
        inventario: 3,
        idArticulo: "62745dfd95c45c183877d2e8",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CARRERA",
        nombre: "CARRERA-KV2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 297,
        inventario: 1,
        idArticulo: "62745df995c45c183877d0fd",
        precio: 465,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "CARRERA",
        nombre: "CARRERA-KV2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 297,
        inventario: 1,
        idArticulo: "62745df995c45c183877d103",
        precio: 465,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "CARRERA",
        nombre: "CARRERA-KV2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 297,
        inventario: 1,
        idArticulo: "62745df995c45c183877d0ff",
        precio: 465,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "CARRERA",
        nombre: "CARRERA-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 297,
        inventario: 1,
        idArticulo: "62745df995c45c183877d0ef",
        precio: 465,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "CARRERA",
        nombre: "CARRERA-KV2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 297,
        inventario: 1,
        idArticulo: "62745df995c45c183877d0ff",
        precio: 465,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "CARRERA",
        nombre: "CARRERA-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 297,
        inventario: 1,
        idArticulo: "62745df995c45c183877d0ef",
        precio: 465,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "CARRERA",
        nombre: "CARRERA-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 297,
        inventario: 1,
        idArticulo: "62745df995c45c183877d0ef",
        precio: 465,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 291,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d268",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 291,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d270",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2210",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 291,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d27f",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 291,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d26c",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "CRCD",
        nombre: "CRCD-014",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 291,
        inventario: 1,
        idArticulo: "62745df995c45c183877d145",
        precio: 460,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "HENRY",
        nombre: "HENRY-KV2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 291,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d276",
        precio: 430,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "MJ",
        nombre: "MJ-02",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2,
        valor: 288.4,
        inventario: 2,
        idArticulo: "62745e0195c45c183877d4d2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2110",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2,
        valor: 285.59999999999997,
        inventario: 2,
        idArticulo: "62745e0595c45c183877d699",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2104",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2,
        valor: 285.59999999999997,
        inventario: 2,
        idArticulo: "62745e0595c45c183877d687",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2103",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2,
        valor: 285.59999999999997,
        inventario: 2,
        idArticulo: "62745e0595c45c183877d685",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TOKYO",
        nombre: "TOKYO-LW2121",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 59,
        valor: 275.53,
        inventario: 59,
        idArticulo: "62745e0995c45c183877d83d",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2109",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 2,
        valor: 273,
        inventario: 2,
        idArticulo: "62745e0a95c45c183877d89d",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "N075",
        nombre: "7300CH-M",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2,
        valor: 271.4,
        inventario: 2,
        idArticulo: "62745df595c45c183877cf1f",
        precio: 130,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ALICE",
        nombre: "ALICE-TY2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 271.3,
        inventario: 1,
        idArticulo: "62745df695c45c183877cf9a",
        precio: 360,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "VERSALLES",
        nombre: "VERSALLES-LW2203",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 2,
        valor: 268.4,
        inventario: 2,
        idArticulo: "62745e0b95c45c183877d8af",
        precio: 125,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2206",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1,
        valor: 264.75,
        inventario: 1,
        idArticulo: "62745df595c45c183877cf47",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2204",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1,
        valor: 264.75,
        inventario: 1,
        idArticulo: "62745df595c45c183877cf4d",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ADINA",
        nombre: "ADINA-MG2201",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1,
        valor: 264.75,
        inventario: 1,
        idArticulo: "62745df595c45c183877cf36",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VIENA",
        nombre: "VIENA-HN2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 257.4,
        inventario: 1,
        idArticulo: "62745e0b95c45c183877d8d3",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "VIENA",
        nombre: "VIENA-HN2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 257.4,
        inventario: 1,
        idArticulo: "62745e0b95c45c183877d8d1",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "PAULA",
        nombre: "PAULA-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 256.6,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d635",
        precio: 400,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MONACO",
        nombre: "MONACO-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 256.2,
        inventario: 2,
        idArticulo: "62745e0195c45c183877d4e2",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MONACO",
        nombre: "MONACO-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 256.2,
        inventario: 2,
        idArticulo: "62745e0195c45c183877d4de",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ENZOPLUS",
        nombre: "ENZOPLUS-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 254.1,
        inventario: 2,
        idArticulo: "62745dfa95c45c183877d1b5",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CATAR",
        nombre: "CATAR-LW2104",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 254.1,
        inventario: 2,
        idArticulo: "62745df995c45c183877d117",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N087",
        nombre: "2010-4-2",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 250,
        inventario: 1,
        idArticulo: "628be7f495692838f4c58b9a",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2020",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "RC",
        nombre: "RC-014",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 250,
        inventario: 1,
        idArticulo: "62b342c9ba9b160016396245",
        precio: 223,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "PACOPLUS",
        nombre: "PACOPLUS-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 246.6,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d616",
        precio: 365,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ALISONPLUS",
        nombre: "ALISONPLUS-HN2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 245,
        inventario: 1,
        idArticulo: "62745df695c45c183877cfac",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "STEFY",
        nombre: "STEFY-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 243.6,
        inventario: 2,
        idArticulo: "62745e0995c45c183877d7fd",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2104",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2,
        valor: 239.4,
        inventario: 2,
        idArticulo: "62745dfd95c45c183877d302",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2114",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2,
        valor: 239.4,
        inventario: 2,
        idArticulo: "62745dfd95c45c183877d31e",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2105",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2,
        valor: 239.4,
        inventario: 2,
        idArticulo: "62745dfd95c45c183877d30a",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KENT",
        nombre: "KENT-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 236.1,
        inventario: 1,
        idArticulo: "62745dfe95c45c183877d37e",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "JONATHAN",
        nombre: "JONATHAN-MG2003(20A)",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 235.2,
        inventario: 1,
        idArticulo: "628be7f295692838f4c58b2e",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2020",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N059",
        nombre: "2921-3-6R",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 232.74,
        inventario: 1,
        idArticulo: "62745df495c45c183877ceed",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "KISS",
        nombre: "KISS-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2,
        valor: 232.7,
        inventario: 2,
        idArticulo: "62745dff95c45c183877d3f6",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KISS",
        nombre: "KISS-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2,
        valor: 232.7,
        inventario: 2,
        idArticulo: "629e90dbf4d9d700160d97a0",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LIAMPLUS",
        nombre: "LIAMPLUS-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 232.7,
        inventario: 1,
        idArticulo: "62745e0095c45c183877d478",
        precio: 365,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "JULIO",
        nombre: "JULIO-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 232.4,
        inventario: 1,
        idArticulo: "62745dfe95c45c183877d342",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SHELLY",
        nombre: "SHELLY-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 232.4,
        inventario: 1,
        idArticulo: "62745e0895c45c183877d7b2",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SHELLY",
        nombre: "SHELLY-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 232.4,
        inventario: 1,
        idArticulo: "631627b7cbb5560016284d76",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JULIO",
        nombre: "JULIO-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 232.4,
        inventario: 1,
        idArticulo: "62745dfe95c45c183877d342",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "RBRSEV",
        nombre: "RBRSEV-2206",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 2,
        valor: 232.08,
        inventario: 2,
        idArticulo: "62cf2deffb6b4e00175ad7e0",
        precio: 6.36,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ALDOPLUS",
        nombre: "ALDOPLUS-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 231.15,
        inventario: 1,
        idArticulo: "62745df695c45c183877cf8d",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "DIXIE",
        nombre: "DIXIE-MG2104",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 230,
        inventario: 1,
        idArticulo: "628be7f495692838f4c58bac",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA568#405",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1,
        valor: 230,
        inventario: 1,
        idArticulo: "6308f06eb511770016a3b05a",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA-MG2205",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1,
        valor: 227.15,
        inventario: 1,
        idArticulo: "62745dfe95c45c183877d340",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA-MG2204",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1,
        valor: 227.15,
        inventario: 1,
        idArticulo: "62745dfe95c45c183877d338",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA-MG2202",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1,
        valor: 227.15,
        inventario: 1,
        idArticulo: "62745dfe95c45c183877d332",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JULIA",
        nombre: "JULIA-MG2201",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1,
        valor: 227.15,
        inventario: 1,
        idArticulo: "62745dfe95c45c183877d334",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PACO",
        nombre: "PACO-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 225.6,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d611",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2104",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2,
        valor: 224.7,
        inventario: 2,
        idArticulo: "62745df895c45c183877d09b",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GABO",
        nombre: "GABO-KV2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 224.3,
        inventario: 1,
        idArticulo: "62745dfb95c45c183877d1ff",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "GABO",
        nombre: "GABO-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 224.3,
        inventario: 1,
        idArticulo: "62745dfb95c45c183877d1fb",
        precio: 350,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "ROBIN",
        nombre: "ROBIN-XT2201",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 2,
        valor: 223.7,
        inventario: 2,
        idArticulo: "628be7f395692838f4c58b66",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2,
        valor: 221.1,
        inventario: 2,
        idArticulo: "62745e0395c45c183877d596",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2,
        valor: 221.1,
        inventario: 2,
        idArticulo: "62745e0395c45c183877d598",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "HANS",
        nombre: "HANS-TY2202",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1,
        valor: 220.1,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d244",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "HANS",
        nombre: "HANS-TY2201",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1,
        valor: 220.1,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d242",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "MAYA",
        nombre: "MAYA-MG2105",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 220,
        inventario: 1,
        idArticulo: "628be7f495692838f4c58ba7",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ERVIN",
        nombre: "ERVIN-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 217.7,
        inventario: 1,
        idArticulo: "62745dfa95c45c183877d1c3",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "LIAM",
        nombre: "LIAM-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 215.9,
        inventario: 1,
        idArticulo: "62745e0095c45c183877d470",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "LIAM",
        nombre: "LIAM-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 215.9,
        inventario: 1,
        idArticulo: "62745e0095c45c183877d472",
        precio: 340,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "BARELA",
        nombre: "BARELA-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 215.7,
        inventario: 1,
        idArticulo: "62745df795c45c183877d019",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BARELA",
        nombre: "BARELA-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 215.7,
        inventario: 1,
        idArticulo: "62745df795c45c183877d014",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BARELAHOOD",
        nombre: "BARELAHOOD-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 215.7,
        inventario: 1,
        idArticulo: "62745df795c45c183877d016",
        precio: 280,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TANIAGIRLZ",
        nombre: "TANIAGIRLZ-TY2202",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1,
        valor: 215.4,
        inventario: 1,
        idArticulo: "628be7f495692838f4c58bbf",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TANIAGIRLZ",
        nombre: "TANIAGIRLZ-TY2203",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1,
        valor: 215.4,
        inventario: 1,
        idArticulo: "628be7f495692838f4c58bc1",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TANIAGIRLZ",
        nombre: "TANIAGIRLZ-TY2201",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 1,
        valor: 215.4,
        inventario: 1,
        idArticulo: "628be7f495692838f4c58bbb",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2205",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2,
        valor: 214.8,
        inventario: 2,
        idArticulo: "62745e0395c45c183877d581",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2,
        valor: 214.8,
        inventario: 2,
        idArticulo: "62745e0395c45c183877d583",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2,
        valor: 214.8,
        inventario: 2,
        idArticulo: "62745e0395c45c183877d57f",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKABLOCK",
        nombre: "NIKABLOCK-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2,
        valor: 214.8,
        inventario: 2,
        idArticulo: "62745e0395c45c183877d576",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BART",
        nombre: "BART-LE2201F",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 214.38,
        inventario: 1,
        idArticulo: "62c4afc1a0eb6a0017d4f3da",
        precio: 231.02,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JULIA",
        nombre: "HG-JULIA2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 34,
        valor: 212.16,
        inventario: 34,
        idArticulo: "628be7f295692838f4c58b20",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CRCD",
        nombre: "CRCD-006",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 210,
        inventario: 1,
        idArticulo: "628be7f295692838f4c58afe",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "OLIVIAPLUS",
        nombre: "OLIVIAPLUS-HN2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 210,
        inventario: 1,
        idArticulo: "62745e0395c45c183877d5c1",
        precio: 440,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA EXTRA",
        idFamilia: "62744aaa0b64170016800371",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "BIKER",
        nombre: "BIKER-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 208.25,
        inventario: 1,
        idArticulo: "62745df795c45c183877d039",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SIENAHOOD",
        nombre: "SIENAHOOD-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 206.4,
        inventario: 1,
        idArticulo: "62745e0895c45c183877d7ba",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SIENAHOOD",
        nombre: "SIENAHOOD-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 206.4,
        inventario: 1,
        idArticulo: "62745e0895c45c183877d7c4",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SIENAHOOD",
        nombre: "SIENAHOOD-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 206.4,
        inventario: 1,
        idArticulo: "62745e0895c45c183877d7be",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PP",
        nombre: "PP-DG2201",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 204,
        valor: 204,
        inventario: 204,
        idArticulo: "62745e0595c45c183877d67e",
        precio: 120,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "ROXY",
        nombre: "ROXY-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 203.7,
        inventario: 1,
        idArticulo: "62745e0895c45c183877d77e",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N096",
        nombre: "8072",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 2,
        valor: 203.7,
        inventario: 2,
        idArticulo: "628be7f195692838f4c58ac0",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ROXY",
        nombre: "ROXY-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 203.7,
        inventario: 1,
        idArticulo: "62745e0895c45c183877d782",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROXY",
        nombre: "ROXY-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 203.7,
        inventario: 1,
        idArticulo: "62745e0895c45c183877d780",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N126",
        nombre: "WLR-105",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 203.34,
        inventario: 1,
        idArticulo: "628be7f295692838f4c58ae6",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 203,
        inventario: 1,
        idArticulo: "62745df895c45c183877d06d",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 203,
        inventario: 1,
        idArticulo: "62745df795c45c183877d05d",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 203,
        inventario: 1,
        idArticulo: "62745df795c45c183877d054",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROBERT",
        nombre: "ROBERT-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 203,
        inventario: 1,
        idArticulo: "62745e0795c45c183877d72c",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBERT",
        nombre: "ROBERT-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 203,
        inventario: 1,
        idArticulo: "62745e0795c45c183877d728",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBERTHOOD",
        nombre: "ROBERTHOOD-MG2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 203,
        inventario: 1,
        idArticulo: "62745e0795c45c183877d726",
        precio: 335,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ROBERTHOOD",
        nombre: "ROBERTHOOD-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 203,
        inventario: 1,
        idArticulo: "62745e0795c45c183877d724",
        precio: 335,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RS0",
        nombre: "RS-007",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 203,
        inventario: 1,
        idArticulo: "62c377fc4a45570017e47175",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTONHOOD",
        nombre: "BOSTONHOOD-LE2210",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 203,
        inventario: 1,
        idArticulo: "62745df895c45c183877d06b",
        precio: 330,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "RM89",
        nombre: "RM8917702A",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 202.27,
        inventario: 1,
        idArticulo: "628be7f395692838f4c58b62",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "BINGO",
        nombre: "BINGO-LW2104",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 201.6,
        inventario: 2,
        idArticulo: "62745df795c45c183877d041",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BINGO",
        nombre: "BINGO-LW2105",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 201.6,
        inventario: 2,
        idArticulo: "62745df795c45c183877d047",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PAQUITO",
        nombre: "PAQUITO-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 200.4,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d618",
        precio: 320,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MASSIMO",
        nombre: "MASSIMO-HN2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 1,
        valor: 200.1,
        inventario: 1,
        idArticulo: "62745e0195c45c183877d4ba",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "N087",
        nombre: "2927-1-3R",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 200,
        inventario: 1,
        idArticulo: "628be7f495692838f4c58ba9",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2020",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2201",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 2,
        valor: 199.79999999999998,
        inventario: 2,
        idArticulo: "62745dfd95c45c183877d2e6",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOKER",
        nombre: "JOKER-MG2206",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 2,
        valor: 199.79999999999998,
        inventario: 2,
        idArticulo: "62745dfd95c45c183877d2f8",
        precio: 140,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "LOLAHOOD",
        nombre: "LOLAHOOD-KV2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 199.6,
        inventario: 1,
        idArticulo: "62745e0195c45c183877d483",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "LOLAHOOD",
        nombre: "LOLAHOOD-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 199.6,
        inventario: 1,
        idArticulo: "62745e0195c45c183877d485",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "DHL",
        nombre: "DHL-LE2102",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2,
        valor: 199.5,
        inventario: 2,
        idArticulo: "62745dfa95c45c183877d16b",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2104",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 2,
        valor: 197.4,
        inventario: 2,
        idArticulo: "62745dfd95c45c183877d2d6",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "FILIP",
        nombre: "FILIP-KV2205",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 1,
        valor: 196.20000000000002,
        inventario: 1,
        idArticulo: "62745dfb95c45c183877d1ef",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "LUCA",
        nombre: "LUCA-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 195.4,
        inventario: 1,
        idArticulo: "62745e0195c45c183877d497",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "GOLDA",
        nombre: "GOLDA-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 194.70000000000002,
        inventario: 1,
        idArticulo: "62745dfb95c45c183877d21c",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "AMS",
        nombre: "AMS-310",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 194,
        valor: 194,
        inventario: 194,
        idArticulo: "62745df695c45c183877cfcb",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "LAX",
        nombre: "LAX-XT2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 193.3,
        inventario: 1,
        idArticulo: "62c48c96a0eb6a0017d4c4c1",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "LAX",
        nombre: "LAX-XT2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 193.29999999999998,
        inventario: 1,
        idArticulo: "62745e0095c45c183877d43e",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 192.5,
        inventario: 1,
        idArticulo: "62745e0395c45c183877d566",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 192.5,
        inventario: 1,
        idArticulo: "62745e0295c45c183877d561",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 192.5,
        inventario: 1,
        idArticulo: "62745e0295c45c183877d55f",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIAHOOD",
        nombre: "NIDIAHOOD-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 192.5,
        inventario: 1,
        idArticulo: "62745e0295c45c183877d55f",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "SIENA",
        nombre: "SIENA-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 191.70000000000002,
        inventario: 1,
        idArticulo: "62745e0895c45c183877d7c6",
        precio: 305,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ANITAHOOD",
        nombre: "ANITAHOOD-MG2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 190.5,
        inventario: 1,
        idArticulo: "62745df695c45c183877cfea",
        precio: 300,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NEMO",
        nombre: "NEMO-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 189.6,
        inventario: 1,
        idArticulo: "62745e0295c45c183877d527",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKA",
        nombre: "NIKA-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2,
        valor: 189.6,
        inventario: 2,
        idArticulo: "62745e0395c45c183877d586",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 189.6,
        inventario: 1,
        idArticulo: "62745dfa95c45c183877d19f",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 189.6,
        inventario: 1,
        idArticulo: "62745dfa95c45c183877d197",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 189.6,
        inventario: 1,
        idArticulo: "62745dfa95c45c183877d195",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "DORI",
        nombre: "DORI-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 189.6,
        inventario: 1,
        idArticulo: "62745dfa95c45c183877d193",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEMO",
        nombre: "NEMO-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 189.6,
        inventario: 1,
        idArticulo: "62745e0295c45c183877d525",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKA",
        nombre: "NIKA-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2,
        valor: 189.6,
        inventario: 2,
        idArticulo: "62745e0395c45c183877d58b",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIKA",
        nombre: "NIKA-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2,
        valor: 189.6,
        inventario: 2,
        idArticulo: "62745e0395c45c183877d588",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LOLA",
        nombre: "LOLA-KV2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 189.1,
        inventario: 1,
        idArticulo: "62745e0195c45c183877d489",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "SINGAPUR",
        nombre: "SINGAPUR-LW2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 189.1,
        inventario: 1,
        idArticulo: "62745e0895c45c183877d7e7",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SINGAPUR",
        nombre: "SINGAPUR-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 189.1,
        inventario: 1,
        idArticulo: "62745e0895c45c183877d7d8",
        precio: 235,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PEACHHOOD",
        nombre: "PEACHHOOD-MG2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 1,
        valor: 188.06,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d640",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PEACHHOOD",
        nombre: "PEACHHOOD-MG2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 1,
        valor: 188.06,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d63c",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N027",
        nombre: "6410",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 2,
        valor: 184.8,
        inventario: 2,
        idArticulo: "62745df395c45c183877ce91",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "PP",
        nombre: "PP-DG2201",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 183,
        valor: 183,
        inventario: 183,
        idArticulo: "62745e0595c45c183877d67e",
        precio: 120,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "ALDOBOY",
        nombre: "ALDOBOY-BL2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 182.79999999999998,
        inventario: 1,
        idArticulo: "62745df695c45c183877cf86",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "OTIS",
        nombre: "OTIS-MG2205",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 182.1,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d5ff",
        precio: 265,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OTIS",
        nombre: "OTIS-MG2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 182.1,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d5f2",
        precio: 265,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ISABEL",
        nombre: "ISABEL-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 180.6,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d29c",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ISABEL",
        nombre: "ISABEL-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 180.6,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d29a",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "ISABEL",
        nombre: "ISABEL-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 180.6,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d297",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2207",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 177.79999999999998,
        inventario: 1,
        idArticulo: "62745df895c45c183877d089",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2206",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 177.79999999999998,
        inventario: 1,
        idArticulo: "62745df895c45c183877d087",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 177.79999999999998,
        inventario: 1,
        idArticulo: "62745df895c45c183877d085",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 177.79999999999998,
        inventario: 1,
        idArticulo: "62745df895c45c183877d082",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 177.79999999999998,
        inventario: 1,
        idArticulo: "62745df895c45c183877d080",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOSTON",
        nombre: "BOSTON-LE2208",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 177.79999999999998,
        inventario: 1,
        idArticulo: "62745df895c45c183877d08f",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PEACH",
        nombre: "PEACH-MG2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 1,
        valor: 176.81,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d638",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PARIS",
        nombre: "PARIS-HN2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 176.5,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d623",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "ALISON",
        nombre: "ALISON-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 176.4,
        inventario: 1,
        idArticulo: "62745df695c45c183877cfa5",
        precio: 400,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RENO",
        nombre: "RENO-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 175.13,
        inventario: 1,
        idArticulo: "62745e0695c45c183877d702",
        precio: 255,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2213",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 174.5,
        inventario: 1,
        idArticulo: "62745e0695c45c183877d6ec",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2214",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 174.5,
        inventario: 1,
        idArticulo: "62745e0695c45c183877d6ee",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2211",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 174.5,
        inventario: 1,
        idArticulo: "62745e0695c45c183877d6e8",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 174.5,
        inventario: 1,
        idArticulo: "62745e0695c45c183877d6d6",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RS90",
        nombre: "RS9004L",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 172.62,
        inventario: 1,
        idArticulo: "628be7f395692838f4c58b76",
        precio: 285,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "KIKI",
        nombre: "KIKI-LE2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 171.75,
        inventario: 1,
        idArticulo: "62745dff95c45c183877d3d2",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KIDZ",
        nombre: "KIDZ-LW2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 171.6,
        inventario: 1,
        idArticulo: "62745dff95c45c183877d3c7",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "REX",
        nombre: "REX-LW2203",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 171.6,
        inventario: 1,
        idArticulo: "62745e0695c45c183877d712",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 169.4,
        inventario: 1,
        idArticulo: "62745e0a95c45c183877d855",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-HN2209",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 169.4,
        inventario: 1,
        idArticulo: "628be7f495692838f4c58bae",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 2",
        idProveedor: "62744c88b6ff5e14ac65e957",
      },
      {
        codigo: "NEWYORK",
        nombre: "NEWYORK-XT2206",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 169.20000000000002,
        inventario: 1,
        idArticulo: "62745e0295c45c183877d53f",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2105",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 166.5,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d60f",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2104",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 166.5,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d609",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 166.5,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d606",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 166.5,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d602",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "OTISBOY",
        nombre: "OTISBOY-MG2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 166.5,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d604",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "MOSCU",
        nombre: "MOSCU-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 165.9,
        inventario: 1,
        idArticulo: "62745e0295c45c183877d4f4",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2202",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 165.20000000000002,
        inventario: 1,
        idArticulo: "62745e0395c45c183877d574",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 165.20000000000002,
        inventario: 1,
        idArticulo: "62745e0395c45c183877d56d",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIDIA",
        nombre: "NIDIA-LE2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 165.2,
        inventario: 1,
        idArticulo: "62cee3ccfb6b4e00175aa04e",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "AMS",
        nombre: "AMS-311",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 164,
        valor: 164,
        inventario: 164,
        idArticulo: "62745df695c45c183877cfcd",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "AMS",
        nombre: "AMS-313",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 164,
        valor: 164,
        inventario: 164,
        idArticulo: "62745df695c45c183877cfd8",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "PRAGA",
        nombre: "PRAGA-LW2139",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 163.47,
        inventario: 1,
        idArticulo: "62745e0695c45c183877d6c6",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYORIB",
        nombre: "TOKYORIB-LW2116",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 162.24,
        inventario: 1,
        idArticulo: "62745e0995c45c183877d846",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "YUKI",
        nombre: "YUKI-LW2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 162.05,
        inventario: 1,
        idArticulo: "62745e0b95c45c183877d8df",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "YUKI",
        nombre: "YUKI-LW2201",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 162.05,
        inventario: 1,
        idArticulo: "62745e0b95c45c183877d8d5",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "GIO",
        nombre: "GIO-KV2201D",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 161.8,
        inventario: 1,
        idArticulo: "62745dfb95c45c183877d203",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "NIKOHOOD",
        nombre: "NIKOHOOD-MG2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 161.72,
        inventario: 1,
        idArticulo: "62745e0395c45c183877d5aa",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2107",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 161.70000000000002,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d2b2",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2106",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 161.70000000000002,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d2b0",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2105",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 161.70000000000002,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d2ae",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2104",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 161.70000000000002,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d2ac",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2102",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 161.70000000000002,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d2a2",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "IZY",
        nombre: "IZY-WL2101",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 161.70000000000002,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d2a0",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "KIDA",
        nombre: "KIDA-DG2206",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 160.9,
        inventario: 1,
        idArticulo: "62745dff95c45c183877d398",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2206",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 160.9,
        inventario: 1,
        idArticulo: "62745dff95c45c183877d3be",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2204",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 160.9,
        inventario: 1,
        idArticulo: "62745dff95c45c183877d3b4",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "KIDO",
        nombre: "KIDO-DG2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 160.9,
        inventario: 1,
        idArticulo: "62745dff95c45c183877d3b0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "ZHL",
        nombre: "ZHL-LE2103",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 2,
        valor: 159.6,
        inventario: 2,
        idArticulo: "62745e0b95c45c183877d8ed",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "HABANA",
        nombre: "HABANA-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 159.6,
        inventario: 1,
        idArticulo: "62745dfb95c45c183877d22e",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "HABANA",
        nombre: "HABANA-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 159.6,
        inventario: 1,
        idArticulo: "62745dfb95c45c183877d22e",
        precio: 310,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MAYA",
        nombre: "MAYA-MG2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 159.6,
        inventario: 1,
        idArticulo: "62745e0195c45c183877d4c0",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NEO",
        nombre: "NEO-LE2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 158.35,
        inventario: 1,
        idArticulo: "62745e0295c45c183877d52c",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NEO",
        nombre: "NEO-LE2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 158.35,
        inventario: 1,
        idArticulo: "62745e0295c45c183877d52e",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "TOKYO",
        nombre: "TOKYO-LW2117",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 156.55,
        inventario: 1,
        idArticulo: "62745e0995c45c183877d833",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "TOKYO",
        nombre: "TOKYO-LW2113",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 156.55,
        inventario: 1,
        idArticulo: "62745e0995c45c183877d82f",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2208",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 155.2,
        inventario: 1,
        idArticulo: "62745dfe95c45c183877d35a",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2207",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 155.2,
        inventario: 1,
        idArticulo: "62745dfe95c45c183877d358",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2206",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 155.2,
        inventario: 1,
        idArticulo: "62745dfe95c45c183877d356",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2205",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 155.2,
        inventario: 1,
        idArticulo: "62745dfe95c45c183877d354",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2204",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 155.2,
        inventario: 1,
        idArticulo: "62745dfe95c45c183877d350",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 155.2,
        inventario: 1,
        idArticulo: "62745dfe95c45c183877d34a",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2202",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 155.2,
        inventario: 1,
        idArticulo: "62745dfe95c45c183877d348",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "KARDASHIAN",
        nombre: "KARDASHIAN-LE2201",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 155.2,
        inventario: 1,
        idArticulo: "62745dfe95c45c183877d346",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PERUPLUS",
        nombre: "PERUPLUS-LW2202",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 1,
        valor: 154.84,
        inventario: 1,
        idArticulo: "62745e0595c45c183877d65c",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "LEGO",
        nombre: "LEGO-MG2201",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 1,
        valor: 152.6,
        inventario: 1,
        idArticulo: "628be7f495692838f4c58b96",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MONIQUE",
        nombre: "MONIQUE-MG2002",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 1,
        valor: 150.6,
        inventario: 1,
        idArticulo: "62745e0295c45c183877d4ef",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CRCM",
        nombre: "CRCM-103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 150,
        inventario: 1,
        idArticulo: "62745df995c45c183877d14b",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OSLO",
        nombre: "OSLO-LW2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 148.26999999999998,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d5f0",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OSLO",
        nombre: "OSLO-LW2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 148.26999999999998,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d5ee",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OREGON",
        nombre: "OREGON-LW2205",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 147.64,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d5e0",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "OREGON",
        nombre: "OREGON-LW2204",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 147.64,
        inventario: 1,
        idArticulo: "62745e0495c45c183877d5de",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "KIM",
        nombre: "KIM-LE2203",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 146.8,
        inventario: 1,
        idArticulo: "62745dff95c45c183877d3e0",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "MJ",
        nombre: "MJ-04",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 144.2,
        inventario: 1,
        idArticulo: "62745e0195c45c183877d4d6",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MJ",
        nombre: "MJ-03",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 144.2,
        inventario: 1,
        idArticulo: "62745e0195c45c183877d4d4",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "MJ",
        nombre: "MJ-01",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 144.2,
        inventario: 1,
        idArticulo: "62745e0195c45c183877d4d0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2102",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 142.79999999999998,
        inventario: 1,
        idArticulo: "62745e0595c45c183877d683",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2111",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 142.79999999999998,
        inventario: 1,
        idArticulo: "62745e0595c45c183877d69f",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2109",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 142.79999999999998,
        inventario: 1,
        idArticulo: "62745e0595c45c183877d696",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2107",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 142.79999999999998,
        inventario: 1,
        idArticulo: "62745e0595c45c183877d692",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2106",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 142.79999999999998,
        inventario: 1,
        idArticulo: "62745e0595c45c183877d690",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2105",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 142.79999999999998,
        inventario: 1,
        idArticulo: "62745e0595c45c183877d68b",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2102",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 142.79999999999998,
        inventario: 1,
        idArticulo: "62745e0595c45c183877d683",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "PRADA",
        nombre: "PRADA-LE2112",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 142.79999999999998,
        inventario: 1,
        idArticulo: "62745e0595c45c183877d6a2",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BURGOS",
        nombre: "BURGOS-HT2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 138.6,
        inventario: 1,
        idArticulo: "62745df895c45c183877d0cd",
        precio: 335,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 3",
        idProveedor: "62744c9ab6ff5e14ac65eb26",
      },
      {
        codigo: "PERU",
        nombre: "PERU-LW2204",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 1,
        valor: 138.4,
        inventario: 1,
        idArticulo: "62745e0595c45c183877d655",
        precio: 130,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N019",
        nombre: "6374",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 1,
        valor: 138.10999999999999,
        inventario: 1,
        idArticulo: "62745df395c45c183877ce69",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N019",
        nombre: "6374",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 1,
        valor: 138.10999999999999,
        inventario: 1,
        idArticulo: "62745df395c45c183877ce69",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2110",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 136.5,
        inventario: 1,
        idArticulo: "62745e0a95c45c183877d8a0",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2106",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 136.5,
        inventario: 1,
        idArticulo: "62745e0a95c45c183877d897",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2104",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 136.5,
        inventario: 1,
        idArticulo: "62745e0a95c45c183877d88e",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2103",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 136.5,
        inventario: 1,
        idArticulo: "62745e0a95c45c183877d88b",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2102",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 136.5,
        inventario: 1,
        idArticulo: "62745e0a95c45c183877d889",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2101",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 136.5,
        inventario: 1,
        idArticulo: "62745e0a95c45c183877d887",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "VERONA",
        nombre: "VERONA-DG2108",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 1,
        valor: 136.5,
        inventario: 1,
        idArticulo: "62745e0a95c45c183877d89b",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "N026",
        nombre: "6405",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 1,
        valor: 136.01,
        inventario: 1,
        idArticulo: "62745df395c45c183877ce8f",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "HELLO",
        nombre: "HELLO-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 135.25,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d256",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "HELLO",
        nombre: "HELLO-LE2202",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 135.25,
        inventario: 1,
        idArticulo: "629e9144f4d9d700160d97ca",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "HELLO",
        nombre: "HELLO-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 135.25,
        inventario: 1,
        idArticulo: "62745dfc95c45c183877d25a",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "VERSALLES",
        nombre: "VERSALLES-LW2206",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 1,
        valor: 134.2,
        inventario: 1,
        idArticulo: "62745e0b95c45c183877d8bb",
        precio: 125,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "VERSALLES",
        nombre: "VERSALLES-LW2205",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 1,
        valor: 134.2,
        inventario: 1,
        idArticulo: "62745e0b95c45c183877d8b9",
        precio: 125,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "AMS",
        nombre: "AMS-310",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 134,
        valor: 134,
        inventario: 134,
        idArticulo: "62745df695c45c183877cfcb",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N021",
        nombre: "6383",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 1,
        valor: 133.91,
        inventario: 1,
        idArticulo: "62745df395c45c183877ce71",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "BRADYBOY",
        nombre: "BRADYBOY-XT2104",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 131.25,
        inventario: 1,
        idArticulo: "62745df895c45c183877d0ba",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "CATAR",
        nombre: "CATAR-LW2105",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 127.05,
        inventario: 1,
        idArticulo: "62745df995c45c183877d111",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CATAR",
        nombre: "CATAR-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 127.05,
        inventario: 1,
        idArticulo: "62745df995c45c183877d113",
        precio: 250,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CAPRI",
        nombre: "CAPRI-LW2103",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 126,
        inventario: 1,
        idArticulo: "62745df995c45c183877d0e1",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "CAPRI",
        nombre: "CAPRI-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 126,
        inventario: 1,
        idArticulo: "62745df895c45c183877d0dc",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2208",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 125,
        inventario: 1,
        idArticulo: "62745e0295c45c183877d517",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2203",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 125,
        inventario: 1,
        idArticulo: "62745e0295c45c183877d505",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "NASA",
        nombre: "NASA-MG2202",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 125,
        inventario: 1,
        idArticulo: "62745e0295c45c183877d501",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "STEFY",
        nombre: "STEFY-LW2102",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 121.8,
        inventario: 1,
        idArticulo: "62745e0995c45c183877d7fa",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "STEFY",
        nombre: "STEFY-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 121.8,
        inventario: 1,
        idArticulo: "62745e0995c45c183877d7f6",
        precio: 160,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "AMS",
        nombre: "AMS-310",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 120,
        valor: 120,
        inventario: 120,
        idArticulo: "62745df695c45c183877cfcb",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2108",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 119.7,
        inventario: 1,
        idArticulo: "62745dfd95c45c183877d30e",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2118",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 119.7,
        inventario: 1,
        idArticulo: "62745dfe95c45c183877d324",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2118",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 119.7,
        inventario: 1,
        idArticulo: "62745dfe95c45c183877d324",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2117",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 119.7,
        inventario: 1,
        idArticulo: "62745dfd95c45c183877d322",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2116",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 119.7,
        inventario: 1,
        idArticulo: "62745dfd95c45c183877d31c",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2115",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 119.7,
        inventario: 1,
        idArticulo: "62745dfd95c45c183877d320",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2113",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 119.7,
        inventario: 1,
        idArticulo: "62745dfd95c45c183877d312",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2112",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 119.7,
        inventario: 1,
        idArticulo: "62745dfd95c45c183877d314",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2111",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 119.7,
        inventario: 1,
        idArticulo: "62745dfd95c45c183877d310",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2108",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 119.7,
        inventario: 1,
        idArticulo: "62745dfd95c45c183877d30e",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2107",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 119.7,
        inventario: 1,
        idArticulo: "62745dfd95c45c183877d30c",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2103",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 119.7,
        inventario: 1,
        idArticulo: "62745dfd95c45c183877d300",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2102",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 119.7,
        inventario: 1,
        idArticulo: "62745dfd95c45c183877d2fa",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JORDAN",
        nombre: "JORDAN-MT2101",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 119.7,
        inventario: 1,
        idArticulo: "62745dfd95c45c183877d2fc",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RHINO",
        nombre: "RHINO-DG2101",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 116.31,
        inventario: 1,
        idArticulo: "62745e0695c45c183877d714",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "ROBINHOOD",
        nombre: "ROBINHOOD-XT2202",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 1,
        valor: 116.05,
        inventario: 1,
        idArticulo: "62745e0795c45c183877d734",
        precio: 190,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ROBINHOOD",
        nombre: "ROBINHOOD-XT2203",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 1,
        valor: 116.05,
        inventario: 1,
        idArticulo: "62745e0795c45c183877d736",
        precio: 190,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "BRAZIL",
        nombre: "BRAZIL-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 113.4,
        inventario: 1,
        idArticulo: "628bb02db9a3c3001601e1ce",
        precio: 260,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2108",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 112.35,
        inventario: 1,
        idArticulo: "62745df895c45c183877d0a9",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2107",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 112.35,
        inventario: 1,
        idArticulo: "62745df895c45c183877d0a5",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2106",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 112.35,
        inventario: 1,
        idArticulo: "62745df895c45c183877d0a1",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2105",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 112.35,
        inventario: 1,
        idArticulo: "62745df895c45c183877d0a3",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2103",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 112.35,
        inventario: 1,
        idArticulo: "62745df895c45c183877d099",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2102",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 112.35,
        inventario: 1,
        idArticulo: "62745df895c45c183877d097",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BOYZ",
        nombre: "BOYZ-LE2101",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 112.35,
        inventario: 1,
        idArticulo: "628be7f495692838f4c58b9e",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ROBIN",
        nombre: "ROBIN-XT2206",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 1,
        valor: 111.85,
        inventario: 1,
        idArticulo: "62745e0795c45c183877d746",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ROBIN",
        nombre: "ROBIN-XT2205",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 1,
        valor: 111.85,
        inventario: 1,
        idArticulo: "62745e0795c45c183877d748",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ROBIN",
        nombre: "ROBIN-XT2204",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 1,
        valor: 111.85,
        inventario: 1,
        idArticulo: "62745e0795c45c183877d744",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ROBIN",
        nombre: "ROBIN-XT2203",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 1,
        valor: 111.85,
        inventario: 1,
        idArticulo: "62745e0795c45c183877d740",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "ROBIN",
        nombre: "ROBIN-XT2202",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 1,
        valor: 111.85,
        inventario: 1,
        idArticulo: "62745e0795c45c183877d73a",
        precio: 180,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 7",
        idProveedor: "62744cecb6ff5e14ac65f396",
      },
      {
        codigo: "NIKBLOCK",
        nombre: "NIKBLOCK-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 110.55,
        inventario: 1,
        idArticulo: "62745e0395c45c183877d59a",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N113",
        nombre: "7362",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 107.1,
        inventario: 1,
        idArticulo: "628be7f195692838f4c58ab6",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "LEE",
        nombre: "LEE-LE2102",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 106.05,
        inventario: 1,
        idArticulo: "62745e0095c45c183877d449",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "LEE",
        nombre: "LEE-LE2101",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 106.05,
        inventario: 1,
        idArticulo: "62745e0095c45c183877d444",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N050",
        nombre: "0024-5-3",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 105,
        valor: 105,
        inventario: 105,
        idArticulo: "62745df495c45c183877ced7",
        precio: 290,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "ZGL",
        nombre: "ZGL-LE2102",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 102.9,
        inventario: 1,
        idArticulo: "62745e0b95c45c183877d8e5",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "ZGL",
        nombre: "ZGL-LE2101",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 102.9,
        inventario: 1,
        idArticulo: "62745e0b95c45c183877d8e3",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIK",
        nombre: "NIK-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 102.15,
        inventario: 1,
        idArticulo: "62745e0395c45c183877d5a8",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIK",
        nombre: "NIK-LE2203",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 102.15,
        inventario: 1,
        idArticulo: "62745e0395c45c183877d5a6",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "NIK",
        nombre: "NIK-LE2201",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 102.15,
        inventario: 1,
        idArticulo: "62745e0395c45c183877d59d",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "BINGO",
        nombre: "BINGO-LW2105",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 100.8,
        inventario: 1,
        idArticulo: "62745df795c45c183877d047",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BINGO",
        nombre: "BINGO-LW2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 100.8,
        inventario: 1,
        idArticulo: "62745df795c45c183877d03f",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "BINGO",
        nombre: "BINGO-LW2106",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 100.8,
        inventario: 1,
        idArticulo: "628be7f295692838f4c58af8",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2105",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 98.7,
        inventario: 1,
        idArticulo: "62745dfd95c45c183877d2e2",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N095",
        nombre: "8024",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 1,
        valor: 98.7,
        inventario: 1,
        idArticulo: "628be7f195692838f4c58abe",
        precio: 150,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2019",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2106",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 98.7,
        inventario: 1,
        idArticulo: "62745dfd95c45c183877d2e0",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "JOGER",
        nombre: "JOGER-MG2102",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 1,
        valor: 98.7,
        inventario: 1,
        idArticulo: "62745dfd95c45c183877d2d4",
        precio: 185,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RS23",
        nombre: "RS23FWGPNT003",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 98,
        valor: 98,
        inventario: 98,
        idArticulo: "62745e0895c45c183877d78e",
        precio: 35,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "NIKA",
        nombre: "NIKA-LE2204",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 94.8,
        inventario: 1,
        idArticulo: "62745e0395c45c183877d591",
        precio: 120,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "N011",
        nombre: "6330",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 93,
        valor: 93,
        inventario: 93,
        idArticulo: "62745df395c45c183877ce4b",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "BALIPLUS",
        nombre: "BALIPLUS-LW2101",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 1,
        valor: 91.35,
        inventario: 1,
        idArticulo: "62745df795c45c183877d007",
        precio: 230,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO EXTRA",
        idFamilia: "62744aa60b6417001680036b",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "AMS",
        nombre: "AMS-313",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 90,
        valor: 90,
        inventario: 90,
        idArticulo: "62745df695c45c183877cfd8",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SALDO",
        nombre: "SALDO350",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 87,
        valor: 87,
        inventario: 87,
        idArticulo: "6334d84a3a829500165ef05c",
        precio: 350,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SALDO",
        nombre: "SALDO150",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 86,
        valor: 86,
        inventario: 86,
        idArticulo: "6334d9d53a829500165ef2df",
        precio: 150,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SALDO",
        nombre: "SALDO380",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 79,
        valor: 79,
        inventario: 79,
        idArticulo: "6334d89d3a829500165ef111",
        precio: 380,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N029",
        nombre: "7268",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 78,
        valor: 78,
        inventario: 78,
        idArticulo: "62745df395c45c183877ce95",
        precio: 240,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "GIRLZ",
        nombre: "GIRLZ-LE2108",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 77.7,
        inventario: 1,
        idArticulo: "62745dfb95c45c183877d220",
        precio: 165,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIRLZ",
        nombre: "GIRLZ-LE2106",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 77.7,
        inventario: 1,
        idArticulo: "62745dfb95c45c183877d215",
        precio: 165,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIRLZ",
        nombre: "GIRLZ-LE2105",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 77.7,
        inventario: 1,
        idArticulo: "62745dfb95c45c183877d213",
        precio: 165,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIRLZ",
        nombre: "GIRLZ-LE2104",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 77.7,
        inventario: 1,
        idArticulo: "62745dfb95c45c183877d211",
        precio: 165,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "GIRLZ",
        nombre: "GIRLZ-LE2102",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 77.7,
        inventario: 1,
        idArticulo: "62745dfb95c45c183877d20d",
        precio: 165,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "RS23",
        nombre: "RS23FWGPNT006",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 74,
        valor: 74,
        inventario: 74,
        idArticulo: "62745e0895c45c183877d790",
        precio: 35,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SALDO",
        nombre: "SALDO280",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 72,
        valor: 72,
        inventario: 72,
        idArticulo: "636d4b62608e6100163414b0",
        precio: 280,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SALDO",
        nombre: "SALDO180",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 65,
        valor: 65,
        inventario: 65,
        idArticulo: "6334d9053a829500165ef156",
        precio: 180,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SALDO",
        nombre: "SALDO250",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 62,
        valor: 62,
        inventario: 62,
        idArticulo: "6334d82b3a829500165ef020",
        precio: 250,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "AMS",
        nombre: "AMS-315",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 60,
        valor: 60,
        inventario: 60,
        idArticulo: "62745df695c45c183877cfda",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SALDO",
        nombre: "SALDO200",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 55,
        valor: 55,
        inventario: 55,
        idArticulo: "6334d8e33a829500165ef149",
        precio: 200,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "SALDO",
        nombre: "SALDO300",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 54,
        valor: 54,
        inventario: 54,
        idArticulo: "6334d8c43a829500165ef13c",
        precio: 300,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SALDO",
        nombre: "SALDO450",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 54,
        valor: 54,
        inventario: 54,
        idArticulo: "6334d86e3a829500165ef0a8",
        precio: 450,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SALDO",
        nombre: "SALDO400",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 52,
        valor: 52,
        inventario: 52,
        idArticulo: "636d4bba608e610016341561",
        precio: 400,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N034",
        nombre: "7295",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 51,
        valor: 51,
        inventario: 51,
        idArticulo: "62745df495c45c183877cea5",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SALDO",
        nombre: "SALDO480",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 49,
        valor: 49,
        inventario: 49,
        idArticulo: "638fa4816236bf0016621742",
        precio: 480,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2019",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "AMS",
        nombre: "AMS-311",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 44,
        valor: 44,
        inventario: 44,
        idArticulo: "62745df695c45c183877cfcd",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N033",
        nombre: "7293",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 43,
        valor: 43,
        inventario: 43,
        idArticulo: "62745df495c45c183877cea3",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N016",
        nombre: "6362",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 40,
        valor: 40,
        inventario: 40,
        idArticulo: "62745df395c45c183877ce5f",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "PP",
        nombre: "PP-DG2201",
        linea: "SHORT",
        idLinea: "627449fa0b641700168002df",
        disponible: 36,
        valor: 36,
        inventario: 36,
        idArticulo: "62745e0595c45c183877d67e",
        precio: 120,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 1",
        idProveedor: "62744bd10b64170016800409",
      },
      {
        codigo: "MUESTRA",
        nombre: "MUESTRA",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 31,
        valor: 31,
        inventario: 31,
        idArticulo: "628be7f495692838f4c58bc3",
        precio: 1,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SALDO",
        nombre: "SALDO100",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 31,
        valor: 31,
        inventario: 31,
        idArticulo: "638fa3f64f9d8300162cccc0",
        precio: 100,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2019",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "AMS",
        nombre: "AMS-317",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 30,
        valor: 30,
        inventario: 30,
        idArticulo: "62745df695c45c183877cfdc",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2021",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "AMS",
        nombre: "AMS-326",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 30,
        valor: 30,
        inventario: 30,
        idArticulo: "62745df695c45c183877cfe3",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "AMS",
        nombre: "AMS-327",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 29,
        valor: 29,
        inventario: 29,
        idArticulo: "62745df695c45c183877cfe8",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "AMS",
        nombre: "AMS-311",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 27,
        valor: 27,
        inventario: 27,
        idArticulo: "62745df695c45c183877cfcd",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N030",
        nombre: "7271",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 25,
        valor: 25,
        inventario: 25,
        idArticulo: "62745df395c45c183877ce97",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N012",
        nombre: "6344",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 24,
        valor: 24,
        inventario: 24,
        idArticulo: "62745df395c45c183877ce4f",
        precio: 220,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "F-F2101",
        nombre: "F-F2101",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 24,
        valor: 24,
        inventario: 24,
        idArticulo: "63c87e61628e8a0016a46cf1",
        precio: 1,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N0001",
        nombre: "AF2842-01(21A)",
        linea: "BAÑADOR",
        idLinea: "627449c70b641700168002a2",
        disponible: 23,
        valor: 23,
        inventario: 23,
        idArticulo: "62745df595c45c183877cf5d",
        precio: 100,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N014",
        nombre: "6357",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 21,
        valor: 21,
        inventario: 21,
        idArticulo: "62745df395c45c183877ce59",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "RS23",
        nombre: "RS23SSMPNT005",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 18,
        valor: 18,
        inventario: 18,
        idArticulo: "628be7f395692838f4c58b68",
        precio: 30,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N031",
        nombre: "7276",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 17,
        valor: 17,
        inventario: 17,
        idArticulo: "62745df495c45c183877ce9f",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N046",
        nombre: "0012-1-6",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 16,
        valor: 16,
        inventario: 16,
        idArticulo: "62745df495c45c183877cecb",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "RS24",
        nombre: "RS24SSMBST206",
        linea: "CAMISA",
        idLinea: "627449d50b641700168002b4",
        disponible: 15,
        valor: 15,
        inventario: 15,
        idArticulo: "62745e0895c45c183877d79b",
        precio: 130,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N011",
        nombre: "6330",
        linea: "CAMISETA",
        idLinea: "627449d90b641700168002ba",
        disponible: 15,
        valor: 15,
        inventario: 15,
        idArticulo: "62745df395c45c183877ce4b",
        precio: 135,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N06",
        nombre: "5677",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 14,
        valor: 14,
        inventario: 14,
        idArticulo: "62745df395c45c183877ce7d",
        precio: 270,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "AMS",
        nombre: "AMS-273",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 14,
        valor: 14,
        inventario: 14,
        idArticulo: "62745df695c45c183877cfb3",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N0001",
        nombre: "AFC-2029(21A)",
        linea: "CAMISA",
        idLinea: "627449d50b641700168002b4",
        disponible: 12,
        valor: 12,
        inventario: 12,
        idArticulo: "62745df595c45c183877cf59",
        precio: 1,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N042",
        nombre: "8016",
        linea: "BERMUDA",
        idLinea: "627449cb0b641700168002a8",
        disponible: 12,
        valor: 12,
        inventario: 12,
        idArticulo: "62745df495c45c183877cebb",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N045",
        nombre: "0012-1-4",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 12,
        valor: 12,
        inventario: 12,
        idArticulo: "62745df495c45c183877cec7",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N0001",
        nombre: "AJK-602",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 12,
        valor: 12,
        inventario: 12,
        idArticulo: "62745df595c45c183877cf61",
        precio: 400,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "RS27",
        nombre: "RS27FWMPNT005",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 11,
        valor: 11,
        inventario: 11,
        idArticulo: "62745e0895c45c183877d7a4",
        precio: 180,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N038",
        nombre: "7314",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 11,
        valor: 11,
        inventario: 11,
        idArticulo: "62745df495c45c183877ceb3",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "AMS",
        nombre: "AMS-272",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 11,
        valor: 11,
        inventario: 11,
        idArticulo: "62745df695c45c183877cfaf",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N066",
        nombre: "6991(21A)",
        linea: "BIKINI",
        idLinea: "627449d00b641700168002ae",
        disponible: 9,
        valor: 9,
        inventario: 9,
        idArticulo: "62745df495c45c183877cf01",
        precio: 150,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N015",
        nombre: "6360",
        linea: "PANTALON",
        idLinea: "627449ee0b641700168002d2",
        disponible: 7,
        valor: 7,
        inventario: 7,
        idArticulo: "62745df395c45c183877ce5b",
        precio: 245,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N08",
        nombre: "6193",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 7,
        valor: 7,
        inventario: 7,
        idArticulo: "62745df395c45c183877ce83",
        precio: 200,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "099V",
        nombre: "099V#502",
        linea: "CHALECO",
        idLinea: "627449de0b641700168002c0",
        disponible: 1,
        valor: 6.38,
        inventario: 1,
        idArticulo: "6340630fd4fdc60016dec915",
        precio: 170,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Showroom",
        idArea: "625e001ae047a508c0970bfb",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "N0001",
        nombre: "AFC-2030(21A)",
        linea: "CAMISA",
        idLinea: "627449d50b641700168002b4",
        disponible: 6,
        valor: 6,
        inventario: 6,
        idArticulo: "62745df595c45c183877cf5f",
        precio: 130,
        coleccion: "PRIMAVERA VERANO",
        idColeccion: "62744aed0b6417001680039c",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "RDSVB",
        nombre: "RDSVB-223",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 5,
        valor: 5,
        inventario: 5,
        idArticulo: "62745e0695c45c183877d700",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "RDSVB",
        nombre: "RDSVB-223",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 4,
        valor: 4,
        inventario: 4,
        idArticulo: "62745e0695c45c183877d700",
        precio: 225,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑO",
        idFamilia: "62744a8f0b64170016800360",
        ano: "2021",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "AMS",
        nombre: "AMS-307",
        linea: "CONJUNTO",
        idLinea: "627449ea0b641700168002cc",
        disponible: 4,
        valor: 4,
        inventario: 4,
        idArticulo: "62745df695c45c183877cfcf",
        precio: 190,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "NIÑA",
        idFamilia: "62744a730b64170016800341",
        ano: "2021",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N130",
        nombre: "DL27FWMCOT002",
        linea: "ABRIGO",
        idLinea: "627449c10b6417001680029c",
        disponible: 3,
        valor: 3,
        inventario: 3,
        idArticulo: "62e40036dd6410001620e6a3",
        precio: 1,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N017",
        nombre: "6365",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 3,
        valor: 3,
        inventario: 3,
        idArticulo: "62745df395c45c183877ce5d",
        precio: 245,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2018",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "CT20",
        nombre: "CT2015",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 2,
        inventario: 2,
        idArticulo: "628bb17ab9a3c3001601e264",
        precio: 1,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "DAMA",
        idFamilia: "62744a370b6417001680031d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "CT20",
        nombre: "CT2027",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 2,
        inventario: 2,
        idArticulo: "628bb19ab9a3c3001601e271",
        precio: 1,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "MLE",
        nombre: "MLE-15D",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 2,
        valor: 2,
        inventario: 2,
        idArticulo: "63191d0a6a8c640016eee6ed",
        precio: 210,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 4",
        idProveedor: "62744cacb6ff5e14ac65ed3e",
      },
      {
        codigo: "CT20",
        nombre: "CT2025",
        linea: "CHAMARRA",
        idLinea: "627449e50b641700168002c6",
        disponible: 1,
        valor: 1,
        inventario: 1,
        idArticulo: "62745dfa95c45c183877d159",
        precio: 1,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 5",
        idProveedor: "62744cb4b6ff5e14ac65ee11",
      },
      {
        codigo: "Defecto",
        nombre: "Defecto",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 587,
        valor: 0,
        inventario: 587,
        idArticulo: "62745eb30b641700168004d3",
        precio: 0,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "Defecto",
        nombre: "Defecto",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 114,
        valor: 0,
        inventario: 114,
        idArticulo: "62745eb30b641700168004d3",
        precio: 0,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "CEDIS",
        idArea: "6240a0754999152b10f8e0a3",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "Defecto",
        nombre: "Defecto",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 6,
        valor: 0,
        inventario: 6,
        idArticulo: "62745eb30b641700168004d3",
        precio: 0,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "Vallejo",
        idArea: "6240a07c4999152b10f8e0a9",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "Defecto",
        nombre: "Defecto",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 46,
        valor: 0,
        inventario: 46,
        idArticulo: "62745eb30b641700168004d3",
        precio: 0,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SALDO",
        nombre: "SALDO",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 58,
        valor: 0,
        inventario: 58,
        idArticulo: "62745ed00b641700168004e0",
        precio: 0,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "Centro",
        idArea: "6201b1920f94f86068a741c6",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "SALDO",
        nombre: "SALDO",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 818,
        valor: 0,
        inventario: 818,
        idArticulo: "62745ed00b641700168004e0",
        precio: 0,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "Defecto",
        nombre: "Defecto",
        linea: "NA",
        idLinea: "6274494a0b6417001680022f",
        disponible: 221,
        valor: 0,
        inventario: 221,
        idArticulo: "62745eb30b641700168004d3",
        precio: 0,
        coleccion: "NA",
        idColeccion: "6274495d0b64170016800244",
        familia: "NA",
        idFamilia: "627449560b6417001680023d",
        ano: "2022",
        area: "Expo",
        idArea: "620d2c985813a41d98f97307",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
      {
        codigo: "N132",
        nombre: "RS23FWMHOD006",
        linea: "SUDADERA",
        idLinea: "627449fe0b641700168002e5",
        disponible: 1,
        valor: 0,
        inventario: 1,
        idArticulo: "62e40151dd6410001620e893",
        precio: 0,
        coleccion: "OTOÑO INVIERNO",
        idColeccion: "62744acc0b64170016800378",
        familia: "CABALLERO",
        idFamilia: "62744a2e0b64170016800310",
        ano: "2017",
        area: "Naucalpan",
        idArea: "6201b18c0f94f86068a741c0",
        proveedor: "Prov 6",
        idProveedor: "62c5cef2883f0f0017d062a3",
      },
    ];

    let unicosProv = array.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.idProveedor === value.idProveedor)
    );
    setProveedores(unicosProv);

    let unicosLinea = array.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.idLinea === value.idLinea)
    );
    setLineas(unicosLinea);

    let unicosCol = array.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.idColeccion === value.idColeccion)
    );
    setColecciones(unicosCol);

    let unicosFam = array.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.idFamilia === value.idFamilia)
    );
    setFamilias(unicosFam);

    setInfo(array);
  }, []);

  useMemo(() => {
    let temp = info;
    if (selectedAno) {
      temp = temp.filter((e) => e.ano.includes(selectedAno));
    }
    if (selectedColeccion) {
      temp = temp.filter((e) => e.idColeccion.includes(selectedColeccion));
    }
    if (selectedFamilia) {
      temp = temp.filter((e) => e.idFamilia.includes(selectedFamilia));
    }
    if (selectedLinea) {
      temp = temp.filter((e) => e.idLinea.includes(selectedLinea));
    }

    if (selectedProveedor) {
      temp = temp.filter((e) => e.idProveedor.includes(selectedProveedor));
    }

    let agrupadoColeccion = temp.reduce(function (groups, item) {
      const val = item["idColeccion"];
      groups[val] = groups[val] || {
        idColeccion: item.idColeccion,
        disponible: 0,
      };
      groups[val].disponible += item.disponible;
      groups[val].coleccion = item.coleccion;
      groups[val].idColeccion = item.idColeccion;
      return groups;
    }, []);

    let otraColeccion = Object.values(agrupadoColeccion).sort((a, b) =>
      a.disponible < b.disponible ? 1 : -1
    );

    setGraficasColecciones(otraColeccion);

    let agrupadoLinea = temp.reduce(function (groups, item) {
      const val = item["idLinea"];
      groups[val] = groups[val] || {
        idLinea: item.idLinea,
        disponible: 0,
      };
      groups[val].disponible += item.disponible;
      groups[val].linea = item.linea;
      groups[val].idLinea = item.idLinea;
      return groups;
    }, []);

    let otraLinea = Object.values(agrupadoLinea).sort((a, b) =>
      a.disponible < b.disponible ? 1 : -1
    );

    setGraficasLineas(otraLinea);

    let agrupadoFamilia = temp.reduce(function (groups, item) {
      const val = item["idFamilia"];
      groups[val] = groups[val] || {
        idFamilia: item.idFamilia,
        disponible: 0,
      };
      groups[val].disponible += item.disponible;
      groups[val].familia = item.familia;
      groups[val].idFamilia = item.idFamilia;
      return groups;
    }, []);

    let otraFamilia = Object.values(agrupadoFamilia).sort((a, b) =>
      a.disponible < b.disponible ? 1 : -1
    );

    setGraficasFamilias(otraFamilia);

    let agrupadoArea = temp.reduce(function (groups, item) {
      const val = item["idArea"];
      groups[val] = groups[val] || {
        idArea: item.idArea,
        disponible: 0,
      };
      groups[val].disponible += item.disponible;
      groups[val].area = item.area;
      groups[val].idArea = item.idArea;
      return groups;
    }, []);

    let otraArea = Object.values(agrupadoArea).sort((a, b) =>
      a.disponible < b.disponible ? 1 : -1
    );

    setGraficasAreas(otraArea);

    let agrupadoAno = temp.reduce(function (groups, item) {
      const val = item["ano"];
      groups[val] = groups[val] || {
        ano: item.ano,
        disponible: 0,
      };
      groups[val].disponible += item.disponible;
      groups[val].ano = item.ano;
      return groups;
    }, []);

    let otraAno = Object.values(agrupadoAno).sort((a, b) =>
      a.disponible < b.disponible ? 1 : -1
    );

    setGraficasAnos(otraAno);

    let agrupadoProveedor = temp.reduce(function (groups, item) {
      const val = item["idProveedor"];
      groups[val] = groups[val] || {
        idProveedor: item.idProveedor,
        disponible: 0,
      };
      groups[val].disponible += item.disponible;
      groups[val].proveedor = item.proveedor;
      groups[val].idProveedor = item.idProveedor;
      return groups;
    }, []);

    let otraProveedor = Object.values(agrupadoProveedor).sort((a, b) =>
      a.disponible < b.disponible ? 1 : -1
    );

    setGraficasProveedores(otraProveedor);

    let TVal = temp.map((c) => parseFloat(c.valor));
    let TV = TVal.reduce((t, total, index) => t + total, 0);
    setValorInventario(TV);

    let TCan = temp.map((c) => parseFloat(c.disponible));
    let TC = TCan.reduce((t, total, index) => t + total, 0);
    setCantidadInventario(TC);
  }, [
    info,
    selectedAno,
    selectedColeccion,
    selectedFamilia,
    selectedLinea,
    selectedProveedor,
  ]);

  useLayoutEffect(() => {
    // Grafica Colecciones
    let root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        endAngle: 270,
        layout: root.verticalLayout,
        tooltip: am5.Tooltip.new(root, {
          labelText: "{coleccion} {value}",
        }),
      })
    );

    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "disponible",
        categoryField: "coleccion",
        endAngle: 270,
      })
    );

    series.states.create("hidden", {
      endAngle: -90,
    });

    chart.children.unshift(
      am5.Label.new(root, {
        text: "Colecciones",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    series.labels.template.set("forceHidden", true);
    series.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });
    series.data.setAll(graficasColecciones);
    series.appear(1000, 100);

    let legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: "coleccion",
      })
    );

    legend.data.setAll(series.dataItems);

    // Grafica Lineas
    let rootLineas = am5.Root.new("chartdivLineas");

    rootLineas.setThemes([am5themes_Animated.new(rootLineas)]);

    let chartLineas = rootLineas.container.children.push(
      am5percent.PieChart.new(rootLineas, {
        endAngle: 270,
        layout: rootLineas.verticalLayout,
        tooltip: am5.Tooltip.new(rootLineas, {
          labelText: "{linea} {value}",
        }),
      })
    );

    let seriesLineas = chartLineas.series.push(
      am5percent.PieSeries.new(rootLineas, {
        valueField: "disponible",
        categoryField: "linea",
        endAngle: 270,
      })
    );

    seriesLineas.states.create("hidden", {
      endAngle: -90,
    });

    chartLineas.children.unshift(
      am5.Label.new(rootLineas, {
        text: "Lineas",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    seriesLineas.labels.template.set("forceHidden", true);
    seriesLineas.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });
    seriesLineas.data.setAll(graficasLineas);
    seriesLineas.appear(1000, 100);

    let legendLineas = chartLineas.children.push(
      am5.Legend.new(rootLineas, {
        nameField: "linea",
      })
    );

    legendLineas.data.setAll(seriesLineas.dataItems);

    // Grafica Familias
    let rootFamilias = am5.Root.new("chartdivFamilias");

    rootFamilias.setThemes([am5themes_Animated.new(rootFamilias)]);

    let chartFamilias = rootFamilias.container.children.push(
      am5percent.PieChart.new(rootFamilias, {
        endAngle: 270,
        layout: rootFamilias.verticalLayout,
        tooltip: am5.Tooltip.new(rootFamilias, {
          labelText: "{familia} {value}",
        }),
      })
    );

    let seriesFamilias = chartFamilias.series.push(
      am5percent.PieSeries.new(rootFamilias, {
        valueField: "disponible",
        categoryField: "familia",
        endAngle: 270,
      })
    );

    seriesFamilias.states.create("hidden", {
      endAngle: -90,
    });

    chartFamilias.children.unshift(
      am5.Label.new(rootFamilias, {
        text: "Familias",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    seriesFamilias.labels.template.set("forceHidden", true);
    seriesFamilias.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });
    seriesFamilias.data.setAll(graficasFamilias);
    seriesFamilias.appear(1000, 100);

    let legendFamilias = chartFamilias.children.push(
      am5.Legend.new(rootFamilias, {
        nameField: "familia",
      })
    );

    legendFamilias.data.setAll(seriesFamilias.dataItems);

    // Grafica Años
    let rootAnos = am5.Root.new("chartdivAnos");

    rootAnos.setThemes([am5themes_Animated.new(rootAnos)]);

    let chartAnos = rootAnos.container.children.push(
      am5percent.PieChart.new(rootAnos, {
        endAngle: 270,
        layout: rootAnos.verticalLayout,
        tooltip: am5.Tooltip.new(rootAnos, {
          labelText: "{ano} {value}",
        }),
      })
    );

    let seriesAnos = chartAnos.series.push(
      am5percent.PieSeries.new(rootAnos, {
        valueField: "disponible",
        categoryField: "ano",
        endAngle: 270,
      })
    );

    seriesAnos.states.create("hidden", {
      endAngle: -90,
    });

    chartAnos.children.unshift(
      am5.Label.new(rootAnos, {
        text: "Años",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    seriesAnos.labels.template.set("forceHidden", true);
    seriesAnos.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });
    seriesAnos.data.setAll(graficasAnos);
    seriesAnos.appear(1000, 100);

    let legendAnos = chartAnos.children.push(
      am5.Legend.new(rootAnos, {
        nameField: "ano",
      })
    );

    legendAnos.data.setAll(seriesAnos.dataItems);

    // Grafica Proveedores
    let rootProveedores = am5.Root.new("chartdivProveedores");

    rootProveedores.setThemes([am5themes_Animated.new(rootProveedores)]);

    let chartProveedores = rootProveedores.container.children.push(
      am5percent.PieChart.new(rootProveedores, {
        endAngle: 270,
        layout: rootProveedores.verticalLayout,
        tooltip: am5.Tooltip.new(rootProveedores, {
          labelText: "{proveedor} {value}",
        }),
      })
    );

    let seriesProveedores = chartProveedores.series.push(
      am5percent.PieSeries.new(rootProveedores, {
        valueField: "disponible",
        categoryField: "proveedor",
        endAngle: 270,
      })
    );

    seriesProveedores.states.create("hidden", {
      endAngle: -90,
    });

    chartProveedores.children.unshift(
      am5.Label.new(rootProveedores, {
        text: "Proveedores",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    seriesProveedores.labels.template.set("forceHidden", true);
    seriesProveedores.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });
    seriesProveedores.data.setAll(graficasProveedores);
    seriesProveedores.appear(1000, 100);

    let legendProveedores = chartProveedores.children.push(
      am5.Legend.new(rootProveedores, {
        nameField: "proveedor",
      })
    );

    legendProveedores.data.setAll(seriesProveedores.dataItems);

    // Grafica Areas

    let rootAreas = am5.Root.new("chartdivAreas");

    rootAreas.setThemes([am5themes_Animated.new(rootAreas)]);

    let chartAreas = rootAreas.container.children.push(
      am5xy.XYChart.new(rootAreas, {
        panY: false,
        layout: rootAreas.verticalLayout,
      })
    );

    let dataAreas = graficasAreas;

    // Create Y-axis
    let yAxis = chartAreas.yAxes.push(
      am5xy.ValueAxis.new(rootAreas, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(rootAreas, {}),
      })
    );

    // Create X-Axis
    let xAxis = chartAreas.xAxes.push(
      am5xy.CategoryAxis.new(rootAreas, {
        renderer: am5xy.AxisRendererX.new(rootAreas, {}),
        categoryField: "area",
      })
    );
    xAxis.data.setAll(dataAreas);

    // Create series
    let seriesAreas = chartAreas.series.push(
      am5xy.ColumnSeries.new(rootAreas, {
        name: "area",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "disponible",
        categoryXField: "area",
        tooltip: am5.Tooltip.new(rootAreas, {
          labelText: "{area} {valueY}",
        }),
      })
    );
    seriesAreas.data.setAll(dataAreas);

    // Add cursor
    chartAreas.set("cursor", am5xy.XYCursor.new(rootAreas, {}));

    let exporting = am5plugins_exporting.Exporting.new(rootAreas, {
      menu: am5plugins_exporting.ExportingMenu.new(rootAreas, {}),
    });

    chartAreas.children.unshift(
      am5.Label.new(rootAreas, {
        text: "Almacenes",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    return () => {
      root.dispose();
      rootLineas.dispose();
      rootProveedores.dispose();
      rootFamilias.dispose();
      rootAreas.dispose();
      rootAnos.dispose();
    };
  }, [
    graficasColecciones,
    graficasLineas,
    graficasFamilias,
    graficasAreas,
    graficasAnos,
    graficasProveedores,
  ]);

  return (
    <>
        <Header />
      {/* <div className="container">*/}
        <br />
        <br /> 
        <div className="container col-md-10">
  
          <h3 align="center">Dashboard Inventarios</h3>
          <br /> 
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Años</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedAno}
                onChange={(e) => {
                  setSelectedAno(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {years.map((a) => {
                  return <option value={a}>{a}</option>;
                })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Colecciones</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedColeccion}
                required
                onChange={(e) => {
                  setSelectedColeccion(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {colecciones
                  .sort((a, b) => (a.coleccion > b.coleccion ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idColeccion}>{a.coleccion}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Familias</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedFamilia}
                required
                onChange={(e) => {
                  setSelectedFamilia(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {familias
                  .sort((a, b) => (a.familia > b.familia ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idFamilia}>{a.familia}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Lineas</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedLinea}
                required
                onChange={(e) => {
                  setSelectedLinea(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {lineas
                  .sort((a, b) => (a.linea > b.linea ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idLinea}>{a.linea}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Proveedores</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedProveedor}
                required
                onChange={(e) => {
                  setSelectedProveedor(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {proveedores
                  .sort((a, b) => (a.proveedor > b.proveedor ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idProveedor}>{a.proveedor}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <br />
          <div
            // className="container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Col md={3}>
              <Card style={{ backgroundColor: "transparent" }}>
                <CardHeader tag="h3" align="center">
                  Piezas
                </CardHeader>
                <CardBody>
                  <CardText tag="h4" align="center">
                    {new Intl.NumberFormat("en-US", {}).format(
                      cantidadInventario
                    )}
                  </CardText>
                </CardBody>
              </Card>
            </Col>

            <Col md={3}>
              <Card style={{ backgroundColor: "transparent" }}>
                <CardHeader tag="h3" align="center">
                  Valor
                </CardHeader>
                <CardBody>
                  <CardText tag="h4" align="center">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 2,
                    }).format(valorInventario)}
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </div>

          <div
            // className="container"
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "14px",
            }}
          >
            <div id="chartdiv" style={{ width: "40%", height: "500px" }}></div>
            <div
              id="chartdivLineas"
              style={{ width: "40%", height: "500px" }}
            ></div>
          </div>

          <br />
          <br />
          <div
            // className="container"
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "14px",
            }}
          >
            <div
              id="chartdivFamilias"
              style={{ width: "80%", height: "500px" }}
            ></div>

            <div
              id="chartdivAnos"
              style={{ width: "40%", height: "500px" }}
            ></div>
          </div>

          <br />
          <br />
          <div
            // className="container"
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "14px",
            }}
          >
            <div
              id="chartdivProveedores"
              style={{ width: "40%", height: "500px" }}
            ></div>
          </div>

          <br />
          <br />
          <div
            // className="container"
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "14px",
            }}
          >
            <div
              id="chartdivAreas"
              style={{ width: "90%", height: "500px" }}
            ></div>
          </div>
        </div>
      {/* </div> */}
      {/* ) : undefined}  */}
    </>
  );
}
export default Dashboards;
