import React from "react";
import { Row, Col } from "reactstrap";
import { Nav } from "react-bootstrap";
import Contact from "./Contact/Contact";
import Header from "./Header";

function Contacto() {


  return (
    <>
    <Header/>
      <div id="Contacto" >
        <div className="contacto">
          <Row>
          <Col md={10} style={{ paddingLeft: "3%" }}>
            <p
              className="card-text"
              style={{
                fontSize: "4vh",
                color: "#000",
                fontStyle: "bold",
                paddingTop: "2%",
                paddingLeft: "5%",
                paddingRight: "5%",
              }}
            >
              Únete a las empresas que confían en Sistemify para optimizar sus procesos y crecer de manera eficiente. 
              
              Cuéntenos tus necesidades y desafíos. 
              
              Estamos aquí para escuchar, colaborar y encontrar la solución perfecta para tu negocio.
              </p>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col md={8} style={{ paddingLeft: "3%" }}>
            <p style={{ fontSize: "3vh", color: "#000", fontWeight: "bold", paddingTop: "2%" }}>
              Visítanos en nuestras oficinas
            </p>
            <p style={{ fontSize: "2vh", color: "#000", fontWeight: "bold" }}>
              Pedregal #24, Lomas Virreyes
            </p>
            <p style={{ fontSize: "2vh", color: "#000", fontWeight: "bold" }}>
              Miguel Hidalgo, Ciudad de México, CP 11040
            </p>

              <br />
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.7055017034636!2d-99.20547388561802!3d19.425126345954382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d201f721fdd345%3A0x2e29f738c3d828!2sTorre%20Virreyes!5e0!3m2!1ses-419!2smx!4v1607977134212!5m2!1ses-419!2smx"
                width="100%"   // Cambia a 100% para ocupar todo el ancho disponible
                height="300px" // Fija el alto para asegurar que sea visible sin ocupar demasiado espacio
                style={{ border: 0 }}
                allowFullScreen
                aria-hidden="false"
                tabIndex={0}
              />
              <br />
              <br />
             <Nav.Link href={`mailto:${process.env.REACT_APP_MAIL}`} >
              <h4 className="alineacion mailcontact" style={{ color: "black" }}>
                <i className="far fa-envelope"></i> ¡Contáctanos ahora!
              </h4>
            </Nav.Link>

            </Col>

            <Col md={4}>
              <Contact />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Contacto;
