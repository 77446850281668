import React, { useState } from "react";
import { Container, Button, Form, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import "../../index.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [tamano, setTamano] = useState("0");
  const history = useHistory();

  const handleForm = async (event) => {
    event.preventDefault();
    const jsonSend = {
      name,
      email,
      note,
      tamano,
      tel,
    };

    const POST_CONTACT = `${process.env.REACT_APP_CONTACTO}`;

    try {
      await axios.post(POST_CONTACT, jsonSend).then(() => {
        setName("");
        setEmail("");
        setTel("");
        setTamano("0");
        setNote("");
        Swal.fire({
          icon: "success",
          title: "Listo, se enviaron tus datos",
          text: "Te contactaremos en breve",
          timer: 3000,
          timerProgressBar: true,
          allowEscapeKey: true,
        }).then(()=>window.location.reload())
      });
    } catch (error) {
      let message = error.datas;
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Algo salio mal",
        text: message,
        allowEscapeKey: true,
      });
    }
  };

  return (
    <>
      <Container className="themed-container" fluid={true}>
        <h1
          style={{
            fontSize: "3vh",
            color: "#000",
            fontStyle: "bold",
            paddingTop: "2%",
          }}
        >
          Contacto
        </h1>
        <Row>          
          <Col>
            <Form className="container form-regis" onSubmit={handleForm}>
              <Form.Group>
                <Form.Label className="mr-sm-2" style={{ color: "black" }}>
                  Nombre
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: "white" }}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  id="Name"
                  name="inputName"
                  placeholder="Nombre"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="mr-sm-2" style={{ color: "black" }}>
                  Telefono
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: "white" }}
                  onChange={(e) => setTel(e.target.value)}
                  type="number"
                  id="Name"
                  name="inputName"
                  placeholder="Telefono"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="mr-sm-2" style={{ color: "black" }}>
                  Tamaño de la Empresa
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: "white" }}
                  as="select"
                  onChange={(e) => setTamano(e.target.value)}
                  type="text"
                >
                  <option value="0">Selecciona</option>
                  <option value="1-5 Empleados">1-5 Empleados</option>
                  <option value="6-20 Empleados">6-20 Empleados</option>
                  <option value="21-50 Empleados">21-50 Empleados</option>
                  <option value="51-100 Empleados">51-100 Empleados</option>
                  <option value="101-250 Empleados">101-250 Empleados</option>
                  <option value="251-1000 Empleados">251-1000 Empleados</option>
                  <option value="+1000 Empleados">+1000 Empleados</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label className="mr-sm-2" style={{ color: "black" }}>
                  Email
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: "white" }}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  name="email"
                  id="exampleEmail"
                  placeholder="Email"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="mr-sm-2" style={{ color: "black" }}>
                  Solucion
                </Form.Label>
                <Form.Control
                  as="textarea"
                  className="comment2"
                  style={{
                    backgroundColor: "white",
                    height: "100px",
                  }}
                  onChange={(e) => setNote(e.target.value)}
                  type="email"
                  name="email"
                  id="exampleEmail"
                  placeholder="Que solucion buscas?"
                  required
                />
              </Form.Group>
              <Button className="btn btn-warning" variant="info" type="submit">
                Enviar
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Contact;
