import React, { useState } from "react";
import { Row, Col, Button, Label, Input } from "reactstrap";
import { Nav } from "react-bootstrap";
import Contact from "./Contact/Contact";
import Header from "./Header";
import Footer from "./Footer";

function Demos() {
  const [cambioVista, setCambioVista] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setCambioVista(1);
  };

  return (
    <>
      <Header />
      <div id="Demos" className="container">
        <br />
        
        <Row>
          <Col md={12}>
            <p
              align="center"
              className="card-text"
              style={{
                fontSize: "4vh",
                color: "#000",
                fontStyle: "bold",
                paddingTop: "2%",
              }}
            >
              UNA IDEA DE COMO SE PUEDE VER TU NEGOCIO
            </p>
          </Col>
        </Row>
        <br />
        {cambioVista == 0 ? (
          <>
            <Row>
              <Col md={12}>
                <p
                  align="center"
                  className="card-text"
                  style={{
                    fontSize: "3vh",
                    color: "#000",
                    fontStyle: "bold",
                    // paddingTop: "2%",
                  }}
                >
                  Dejanos conocerte un poco para mejorar tu experiencia
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Label>Cual es el giro de tu Negocio? </Label>

                <form>
                  <label htmlFor="ropa">
                    <input
                      type="radio"
                      id="ropa"
                      name="option"
                      value="ropa"
                      checked={selectedOption === "ropa"}
                      onChange={handleOptionChange}
                    />
                    {"  "} Importacion Ropa
                  </label>
                  <br />
                  <label htmlFor="tela">
                    <input
                      type="radio"
                      id="tela"
                      name="option"
                      value="tela"
                      checked={selectedOption === "tela"}
                      onChange={handleOptionChange}
                    />
                    {"  "} Comercializacion Telas
                  </label>
                  <br />

                  <label htmlFor="produccion">
                    <input
                      type="radio"
                      id="produccion"
                      name="option"
                      value="produccion"
                      checked={selectedOption === "produccion"}
                      onChange={handleOptionChange}
                    />
                    {"  "} Produccion
                  </label>
                </form>
              </Col>
            </Row>
          </>
        ) : cambioVista == 1 && selectedOption == "ropa" ? (
          <Row>
            <Col md={4}>
              <Button
                href="/Dashboards"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-tshirt fa-7x"></i>
                <br />
                <br />
                Inventario Ropa
              </Button>
            </Col>
          </Row>
        ) : cambioVista == 1 && selectedOption == "produccion" ? (
          <Row>
            <Col md={4}>
              <Button
                href="/Dashboards"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-tshirt fa-7x"></i>
                <br />
                <br />
                Inventario Produccion
              </Button>
            </Col>
          </Row>
        ) : cambioVista == 1 && selectedOption == "tela" ? (
          <Row>
            <Col md={4}>
              <Button
                href="/Dashboards"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-tshirt fa-7x"></i>
                <br />
                <br />
                Inventario Tela
              </Button>
            </Col>
          </Row>
        ) : undefined}

        <br />
        <br />
      </div>
      <Footer/>
    </>
  );
}

export default Demos;
